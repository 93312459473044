import React, { useState, useEffect } from "react";

function Animation(props) {
    return (
        <div className={`${props.isMicroMessage ? 'micro-message' : ''}`}>
            <div className={`component-chat-message ${props.bgClass} ${props.isMicroMessage ? '' : 'mx-auto'} ${props.isVisible ? '' : 'hidden'}`}>
                <video autoPlay={true} loop muted playsInline style={{maxHeight: '200px', maxWidth: '360px', width: '100%'}}>
                    <source src={props.src} type="video/mp4"></source>
                </video>
            </div>
        </div>
    );

}

export default Animation;
