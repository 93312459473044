import Button from './Button.js'
import React, { useState, useRef, useEffect } from "react"
//import * as ReactDOMServer from 'react-dom/server'
//import Modal from "react-bootstrap/Modal"
import WaistTable from "./WaistTable.js"
import { getDOMElement, getAnswerForSlug, log } from '../helpers.js'
import { validateInput } from '../utils/input-validation.js'

function MultiChoice(props) {
    // log("Multichoice props")
    // log(props)
    const [showWaistInput, setShowWaistInput] = useState(false)
	const [showWaistSelection, setWaistSelection] = useState(false)
    const [waistInput, setWaistInput] = useState("");
    //const [waistSizeSelected, setWaistSizeSelected] = useState("")
    const [waistSizeSetWithChart, setWaistSizeSetWithChart] = useState(false)
    //const [tableHTMLStr, setTableHTMLStr] = useState("")
    const [waistChartData, setWaistChartData] = useState([])
    const [alertCustomError, setAlertCustomError] = useState('')
    const [questionResponses, setQuestionResponses] = useState('[]')
    const [singleResponse, setSingleResponse] = useState('')
    const [singleResponseCount, setSingleResponseCount] = useState(0)
    const bearerToken = useRef(props.token)

    const modalRef = useRef()
    //const tableRef = useRef()

    let collapseValue = props.collapseValue ?? 4;
    let question = props.question;
    let qid = question.questionId;
    let responses = question.questionResponses;
    //let buttonImages = question.buttonImages;
    //let btnImgArray = [];
    

    // useEffect(() => {
    //     log("responses change triggered")
    //     log(responses)
    // },[responses])

     useEffect(() => {
        // log("singleResponse change triggered")
        // log(props.question.questionName, singleResponse)

        if ((props.continueOnSelect || typeof props.question.parentQuestionName != "undefined" ) && singleResponseCount > 0) {
            // Create string for multiselect validation
            let validationFaux = null
            let inputValuesObject = ''
            if (questionResponses !== '') {
                inputValuesObject = JSON.parse(questionResponses)
            }
            if (question.questionType.includes('Single-Select')) {
                inputValuesObject = singleResponse
            }
            if (inputValuesObject.length === 0) {
                validationFaux = ''
            } else {
                if (inputValuesObject[0] !== 'continue') {
                    validationFaux = 'This represents value.'
                } else {
                    validationFaux = ''
                }
            }

            let validationCheck = validateInput(validationFaux, props.validation, props.dataType,null, props.answers.current)

            if (validationCheck.valid) {
                if (singleResponse === "continue") {
                    
                    props.callback(question.questionName, inputValuesObject, true)
                    
                } else {
                    //let questionResponsesArr = [];
                    if (question.questionType.includes('Single-Select')) {
                        inputValuesObject = singleResponse
                    }
                    props.callback(question.questionName, inputValuesObject, props.continueOnSelect)

                }
                setAlertCustomError('')
            } else {
                setTimeout(function(){
                    setAlertCustomError(validationCheck.message)
                },50)
                
                log(validationCheck.report,"warn")
            }      
        }
        setSingleResponseCount(singleResponseCount + 1)
    },[singleResponse])

    // if (buttonImages != null && buttonImages.includes('|')) {
    //     btnImgArray = buttonImages.split('|');
    // }

    function writeQuestionResponses(value, action) {
        // Check if str is empty and reset
        let fauxInputValues = questionResponses
        if (questionResponses === '' || typeof questionResponses == 'undefined' || questionResponses == null) {
            let arr = []
            fauxInputValues = JSON.stringify(arr)
        }
        //log("fauxInputValues = "+fauxInputValues)
        // Parse JSON for use
        let inputValuesObject = JSON.parse(fauxInputValues)

        if (action === 'push') {

            // Check to see if string "NONE OF THE ABOVE" is in the results
            let hasClearOption = !(question.ClearAll == null || question.ClearAll === 'null')

            if (hasClearOption && inputValuesObject.length > 0) {
                for (let i = 0; i < inputValuesObject.length; i++) {
                    if (inputValuesObject[i] === responses[question.ClearAll]) {
                        // remove clear all value from results array
                        inputValuesObject.splice(i, 1)
                    }
                }
            }

            if (inputValuesObject.includes(value)) {
                inputValuesObject.splice(inputValuesObject.findIndex(e => e === value),1)
            } else {
                inputValuesObject.push(value)
            }
        } else if (action === 'splice') {
            inputValuesObject.splice(inputValuesObject.findIndex(e => e === value),1)
        } else if (action === 'clear') {
            inputValuesObject = [value] 
        }

        let inputValuesJSON = JSON.stringify(inputValuesObject) // Write as JSON string
        // log("inputValuesJSON - calling setQuestionResponses")
        // log(inputValuesJSON)

        setQuestionResponses(inputValuesJSON) // Save new JSON object
        log("questionResponses = "+ questionResponses)
    }

    function handleModalClose() {
        modalRef.current.classList.remove('show')

        setTimeout(function(){
            modalRef.current.style.display = "none"
        }, 300)
    }

    function handleModalOpen() {
        modalRef.current.style.display = "block"

        setTimeout(function(){
            modalRef.current.classList.add('show')
        }, 10)
    }

    async function fetchSizeChartData(retries, localToken) {
        let url = props.baseAPIURL + `question/getSizeChart/v3?gender=${props.sexAnswer.toLowerCase()}`
        let responseData = ''
        if (localToken === '') {
            localToken = await props.tokenRefresh();
        }

        const response = await fetch(url, {
          method: 'GET',
          headers: new Headers({
              "Authorization": "Bearer "+ localToken,
              "Client-Service": "simbyte-mh4l-api-v1",
              "Content-Type": "application/json",
              "Accept": "application/json"
          }),
          mode: "cors" 
        })
        .then(response => {
            if(response.ok) {
                return response.json()
            }else{
                if (response.status === 401){
                    localToken = ''
                    return fetchSizeChartData(retries-1,localToken)
                }
            }
            return response
        })
        .then((data) => {
            responseData = data
        })

        return responseData
    }

    async function handleSelect(selectedOption) {
        log("handleSelect - selectedOption = "+ selectedOption)
        if (question.questionType === "Single-Select-Waist") {
            if (!isNaN(selectedOption)){
                // waistchart with cms
                props.callback(question.questionName, selectedOption, props.continueOnSelect);
                setAlertCustomError('')
            }
            if (selectedOption === "continue") {
                if (waistSizeSetWithChart) {
                    // Input was selected from chart, don't validate
                    if (waistInput !== "") {
                        props.callback(question.questionName, waistInput, props.continueOnSelect);
                    }
                } else {
                   // Input was user provided - validation required 
                    let validationCheck = validateInput(waistInput, props.validation, props.dataType,null, props.answers.current)

                    if (validationCheck.valid) {
                        props.callback(question.questionName, waistInput, props.continueOnSelect);
                        setAlertCustomError('')
                    } else {
                        setAlertCustomError(validationCheck.message)
                        log(validationCheck.report,"warn")
                    }
                }
            } else if (selectedOption === "response1") {
                setAlertCustomError('')
                setShowWaistInput(true)
                setWaistSelection(false)
                // setWaistInput('')
                handleModalClose()

                // questionResponses = selectedOption;
            } else if (selectedOption === "response2") {
                setAlertCustomError('')
                setShowWaistInput(false)
                setWaistSelection(true)
                
                handleModalOpen()

                // questionResponses = selectedOption;

                // questionResponses = selectedOption;
                let retries = 3
                let response = await fetchSizeChartData(retries,bearerToken.current)
                setWaistChartData(response)
            }
        } else {
            // questionType is not "Single-Select-Waist" so we landed here
            if (selectedOption !== 'continue') {
                if (question.questionType.includes('Single-Select')) {
                    setSingleResponse(selectedOption)
                    setAlertCustomError('')
                    // log("selectedOption")
                    // log(selectedOption)
                } else {
                    // questionType is not "Single-Select-Waist" OR "Single-Select"
                    // so it must be "Multi-Select"
                    let hasClearOption = !(question.ClearAll == null || question.ClearAll === 'null')
                    
                    // if the selectedOption is the ClearAll (such as selecting 'None of the Above')
                    //if (hasClearOption && selectedOption == responses[question.ClearAll]) {
                    if (hasClearOption && responses[selectedOption] === responses[question.ClearAll]) {
                            // log('Multi-Select - ClearAll item selected')
                        getDOMElement('input[type=checkbox]', 'querySelectorAll').forEach( el => el.checked = false );
                        writeQuestionResponses(selectedOption, 'clear');
                    } else {
                        if (questionResponses.includes(responses[question.ClearAll])) {
                            // log('Multi-Select - ClearAll is in questionResponses!')
                            writeQuestionResponses(questionResponses.indexOf(responses[question.ClearAll]), 'splice');
                            getDOMElement('input[value="' + question.ClearAll + '"]', 'querySelectorAll').forEach( el => el.checked = false );
                        }
                        log("calling writeQuestionResponses, push " + selectedOption);
                        writeQuestionResponses(selectedOption,'push');
                    }
                }

                // setAlertCustomError('')

                 if (question.questionType.includes('Single-Select')) {
                     let inputValuesObject = ''
                     inputValuesObject = selectedOption // singleResponse
                     props.callback(question.questionName, inputValuesObject, props.continueOnSelect)
                 } 
            }

            if ((!props.continueOnSelect && selectedOption === 'continue') || props.continueOnSelect || typeof props.question.parentQuestionName != "undefined") {
                // Create string for multiselect validation
                let validationFaux = null
                let inputValuesObject = ''
                if (questionResponses !== '') {
                    inputValuesObject = JSON.parse(questionResponses)

                }
                if (question.questionType.includes('Single-Select')) {
                    inputValuesObject = singleResponse
                }
                if (question.questionType.includes('Multi-Select')) {
                    // questionResponses has not really been updated yet due to async.
                    // we use inputValuesObject so we can modify it locally
                    // if the selectedOption is already in the questionResponses for a multiple choice (checkbox)
                    // control then it means it has been deselected, so we splice it from the array
                    // and then push the update to the parent component that is keeping track of the answers (Multi-Question)
                    if (inputValuesObject.includes(selectedOption)) {
                        inputValuesObject.splice(inputValuesObject.findIndex(e => e === selectedOption),1)  
                        // clear out the answer from parent too
                        props.callback(question.questionName, inputValuesObject, true)
                    } else {
                        inputValuesObject.push(selectedOption)
                    }
                }
                if (inputValuesObject.length === 0) {
                    validationFaux = ''
                } else {
                    if (inputValuesObject[0] !== 'continue') {
                        validationFaux = 'This represents value.'
                    } else {
                        validationFaux = ''
                    }
                }

                let validationCheck = validateInput(validationFaux, props.validation, props.dataType, null, props.answers)

                if (validationCheck.valid) {
                    if (selectedOption === "continue") {
                        
                        props.callback(question.questionName, inputValuesObject, true)
                        
                    } else {
                        let questionResponsesArr = [];

                        if (question.questionType.includes('Single-Select')) {
                            inputValuesObject = singleResponse
                        }
                        props.callback(question.questionName, inputValuesObject, props.continueOnSelect)

                    }
                    setAlertCustomError('')
                } else {
                    setTimeout(function(){
                        setAlertCustomError(validationCheck.message)
                    },50)
                    log(validationCheck.report,"warn")
                }      
            } else if (props.hasCachedElements != null && 
                typeof props.hasCachedElements == 'boolean' &&
                props.hasCachedElementsCallback != null &&
                typeof props.hasCachedElementsCallback == 'function') {
                props.hasCachedElementsCallback();
            }
        }
    }

    function handleSizeSelect(cmStr) {
        handleModalClose()
        setWaistSizeSetWithChart(true)
        setWaistInput(cmStr);
        log("waist size set from chart, selection is "+cmStr)
        if (!props.continueOnSelect){ 
            handleSelect(cmStr)
        }
    }

    const waistInputOnChange = (e) => {
        setWaistInput(e.target.value);
        setAlertCustomError('')
        if (!props.continueOnSelect){ 
            handleSelect(e.target.value)
        }
    }
    
    return (
        <div>
            <div className={`component-multi-choice container ${question.questionType === "Single-Select-Waist" ? 'waist-question' : ''} ${Object.keys(responses).length >= collapseValue ? 'condense' : '' }`}>
                {Object.keys(responses).map((key, index) => {
                    return (
                        <div className="multi-choice-row component-form-UI flex-column" key={`${qid}_${key}}`} >
                            <div className='d-flex flex-row'>
                                <input type={`${question.questionType.includes('Single-Select') ? 'radio' : 'checkbox'}`} 
                                    className="radio_input" 
                                    id={`control_${qid}_${key}`} 
                                    name={qid} 
                                    meta={responses[key]} 
                                    onChange={handleSelect.bind(this, key)} 
                                    value={key}
                                    key={key}
                                    defaultChecked={props.features.backrecall?(props.answer===key?true:false):null}
                                />
                                <label htmlFor={`control_${qid}_${key}`} className="w-100">
                                {responses[key]}
                                </label>
                            </div>
                            {question.questionType === "Single-Select-Waist" && showWaistInput && index === 0 && 
                                <input 
                                    className='single-select-waist form-control transparent-background' 
                                    onChange={waistInputOnChange}    
                                    type="number"
                                    pattern="[0-9]*"
                                    input
                                    inputMode="numeric"
                                    defaultValue={props.features.backrecall?props.answer:waistInput}
                                    placeholder={question.explanationText ?? "Enter waist size in centimetres"}
                                />
                            }

                            {question.questionType === "Single-Select-Waist" && showWaistSelection && index === 1 && waistSizeSetWithChart &&
                                <p className="waist-selection">Selected waist size: {waistInput}cm</p>
                            }
                        </div>
                    )
                })}

                
            </div>

            {alertCustomError.length > 0 && typeof question.parentQuestionName=="undefined" && 
                <p className="text text-error">{alertCustomError}</p>
            }

            {question.questionType !== "Single-Select-Waist" && (!question.questionType === "Single-Select" || !props.continueOnSelect) && (typeof props.fieldOnly == 'undefined') &&
                <div style={{marginTop: '32px',display:'block'}}>
                    <Button
                        classList={"primary regular large last-button dynamic-input-button"}
                        innerText={props.question.buttonText}
                        onclick={handleSelect.bind(this, 'continue')}
                        features={props.features}
                    />
                </div>
            }
            {question.questionType === "Single-Select-Waist" && props.continueOnSelect===true &&
                <Button
                    classList="primary regular large last-button dynamic-input-button"
                    innerText="Next"
                    onclick={handleSelect.bind(this, 'continue')}
                    features={props.features}
                />
            }
            {question.questionType==="Single-Select-Waist" &&
                <div role="dialog" aria-modal="true" className="fade waist-selection modal" tabIndex="-1" ref={modalRef} id="sizechartmodal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="waist-selection-body modal-body">
                                <WaistTable 
                                    response={waistChartData}
                                    sex={props.sexAnswer}
                                    callback={handleSizeSelect}
                                    features={props.features}
                                />
                            </div>
                            <div className="waist-selection-footer modal-footer">
                                <Button
                                    classList={"primary regular large last-button dynamic-input-button"}
                                    innerText={"Close"}
                                    onclick={handleModalClose}
                                    features={props.features}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }   
        </div>
    );
}
  
export default MultiChoice;
  