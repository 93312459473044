import Button from './Button.js';
import React, { useState, useEffect } from "react";
import {landingPageEvent} from "../utils/facebookPixelEvent"

export default function DynamicYesNoSkip(props) {
    useEffect(() =>{
        landingPageEvent();
    },[]);
    const [selectedButton, setSelectedButton] = useState("");

    function buttonClick(choice) {
        setSelectedButton(choice);
        props.callback(props.slug, choice);
    }

    return (
      <div className={`component-yes-no component-dynamic-yes-no-skip ${props.classList}`}>
            {props.innerText != '' &&
                <h1>{props.innerText}</h1>
            }
            <div className={`button-wrapper d-flex justify-content-between ${props.collapseButtons ? 'column' : ''}`}>
            {/* 
                The default values 1 for Yes, 0 for No, and NULL for Skip 
                are assumed for response1, response2, and response3 as 
                defined in HealthCheck.js 
            */}
            
            {/* Assuming 1 for "Yes" */}
            <Button
                classList={`regular primary ${selectedButton == props.options[0] ? "selected" : ""}`}
                innerText={props.options[0]}
                onclick={buttonClick.bind(this, '1')} 
                features={props.features}
            />

            {/* Assuming 0 for "No" */}
            <Button
                classList={`regular primary ${selectedButton == props.options[1] ? "selected" : ""}`}
                innerText={props.options[1]}
                onclick={buttonClick.bind(this, '0')} 
                features={props.features}
            />

            {/* Assuming NULL for "Skip" */}
            <Button
                classList={`regular primary ${selectedButton == props.options[2] ? "selected" : ""}`}
                innerText={props.options[2]}
                onclick={buttonClick.bind(this, 'NULL')} 
                features={props.features}
            />
          </div>
      </div>
    );
  }
