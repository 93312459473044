import ProgressBar from './ProgressBar.js';
import Button from './Button.js';
//import React, { useState, useEffect } from "react";
//import ProgramSignUpForm from './ProgramSignUpForm.js';

function Header(props) {
    function navBack() {
        // features enabled in env-cmd
        if (props.features.backbutton){
            props.navigateBackCallback(); // calls healthcheck.PreviousQuestion
        }
    }

    return (
        <div className={`component-header ${props.headerState}`}>
            <div className={`mh4l-logo ${props.headerState}`}></div>
            {props.indexFlow.length > 1 && props.headerState === 'inProgress' && props.features.backbutton && 
                <div className='back-button'>
                    <Button
                        classList={"circle arrow"}
                        onclick={navBack}
                        features={props.features}
                    />
                </div>
            }
            {props.headerState === 'inProgress' && <ProgressBar progress={props.progress} features={props.features} />}
        </div>
    );

}

export default Header;
