// import 'bootstrap/dist/js/bootstrap.js';
import Image from './Image';
import RiskFactor from './RiskFactor';
import ChatMessage from "./ChatMessage";
import Accordian from "./Accordian";
import Button from "./Button.js";
//import ReactDOMServer from 'react-dom/server';
import ProgramSignUpForm from "./ProgramSignUpForm";
import ResultsOnlyForm from "./ResultsOnlyForm";
//import SocialShare from "./SocialShare";
import { getDOMElement, log } from '../helpers.js'
import React, { createRef } from "react";
//import svgs from './svgs.js';
import { ReactComponent as Hand } from  "../assets/mh4l-hand.svg";

function ResultTabs(props) {
    const tabButtonRefs = React.useRef([]);
    const tabPaneRefs = React.useRef([]);
    const toScroll = React.useRef(null);
    tabButtonRefs.current = props.resultSet.tabs?.map((title, index) => tabButtonRefs[index] ?? createRef())
    tabPaneRefs.current = props.resultSet.tabs?.map((title, index) => tabPaneRefs[index] ?? createRef())
    // tabButtonRefs.meta = tabButtonRefs.current
    // tabPaneRefs.meta = tabPaneRefs.current

    let tabCount = -1
   
    let sectionHeadingCount = 0

    function handleTabClick(tabIndex) {
        log("tabIndex")

        for (let i = 0; i < tabButtonRefs.current.length; i++) {
            //log(tabButtonRefs.current[i])
            tabButtonRefs.current[i].current.ariaSelected = false
            tabButtonRefs.current[i].current.className = 'nav-link hc-results-tab-button'

            if (tabButtonRefs.current[i].current.id === 'hc-nav-home-tab-' + tabIndex) {
                tabButtonRefs.current[i].current.className = 'nav-link hc-results-tab-button active'
                tabButtonRefs.current[i].current.ariaSelected = true            
            }
        }   

        for (let i = 0; i < tabPaneRefs.current.length; i++) {
            tabPaneRefs.current[i].current.className = 'tab-pane hc-results-tab-pane fade show'
            tabPaneRefs.current[i].current.ariaSelected = false

            if (tabPaneRefs.current[i].current.id === 'hc-nav-home-' + tabIndex) {
                tabPaneRefs.current[i].current.className = 'tab-pane hc-results-tab-pane fade show active'
                tabPaneRefs.current[i].current.ariaSelected = true
            }
        }
    }

    function scrollToTab(){
        // if we are in the website, we need to allow for the website header.
        let websiteNav = document.getElementsByClassName("elementor-top-section")
        let offset = 0
        if (websiteNav.length > 0 ){
            //TODO fix this up as the index depends how the elementor menu is made and seems to change between desktop and mobile.
            offset = websiteNav[0].scrollHeight;
        } 

        //to scroll  the main window to the top
        //window.scrollTo(0, 0)
        toScroll.current.blur()
        let tabTop = toScroll.current.getBoundingClientRect().top
        let bodyTop = document.body.getBoundingClientRect().top
        //to scroll the nested scroll to the specific position
        window.scrollTo({ behavior: 'smooth', top: tabTop - bodyTop - offset})
        //log(toScroll.current)
        //calling the function to target the specific tab
        handleTabClick(1)
    }

    function scrollMove() {
        let scrollTop = getDOMElement("results-box", "id").scrollTop;
        let navScrollTop = getDOMElement("to-scroll", "id").offsetTop;
        // let navScrollTop = toScroll.current.offsetTop;

        if ((scrollTop - 20) > navScrollTop) {
            getDOMElement(".nav-tabs-container", "querySelectorAll")[0].classList.add("stick");
        } else {
            getDOMElement(".nav-tabs-container", "querySelectorAll")[0].classList.remove("stick");
        }
    }

    getDOMElement("results-box", "id").addEventListener('scroll', scrollMove);

    return (
        <div className="component-result-tab" id="to-scroll" ref={toScroll}>
            
            {!props.joinFormSuccess &&
            <nav className='nav-tabs-container sticky'>
                <div className='nav-tabs-outer'>
                    <div className="nav nav-tabs nav-tabs-inner">
                        {props.resultSet.tabs?.map((title, index) => {
                            return <button 
                                className={`nav-link hc-results-tab-button ${index === 1 ? 'active' : ''}`} 
                                dangerouslySetInnerHTML={{__html: title}}
                                id={`hc-nav-home-tab-${index}`} 
                                data-bs-toggle="tab" 
                                data-bs-target={`#hc-nav-home-${index}`} 
                                type="button" 
                                role="tab" 
                                aria-controls={`hc-nav-home-${index}`}
                                aria-selected={index === 0 ? 'true' : 'false'}
                                onClick={handleTabClick.bind(this, index)}
                                ref={tabButtonRefs.current[index]}
                                ></button>
                        })}
                        
                    </div>
                </div>
            </nav>
            }
            {!props.joinFormSuccess &&
            <div className="tab-content">
                {Object.keys(props.resultSet)?.map((key, indexResultSet) => {
                    if (key.includes('tab') && (key !== 'tabs')) {
                        tabCount++
                        return <div 
                            className={`tab-pane hc-results-tab-pane fade show ${tabCount === 1 ? 'active' : ''}`}
                            id={`hc-nav-home-${tabCount}`} 
                            role="tabpanel" 
                            aria-labelledby={`hc-nav-home-tab-${tabCount}`}
                            ref={tabPaneRefs.current[tabCount]}
                            >
                            
                            {props.resultSet[key]?.map((block, indexTab) => {

                                if (block.blockType === 'banner') {
                                    return <div className={`tab-content-upper-section tab-content-padding ${sectionHeadingCount >= 3 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                        {Object.keys(block.blockContent).map((keyBlockContent, indexContent) => {
                                            if (keyBlockContent === 'heading') {
                                                return <h2 className="heading" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></h2>
                                            }
                                            if (keyBlockContent === 'image') {
                                                return <Image 
                                                    src={block.blockContent[keyBlockContent].scr ? block.blockContent[keyBlockContent].scr : ''} 
                                                    width={block.blockContent[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                    height={block.blockContent[keyBlockContent].height ? block.blockContent[keyBlockContent].height : ''} 
                                                    className={block.blockContent[keyBlockContent].class ? block.blockContent[keyBlockContent].class : ''} 
                                                    alt={block.blockContent[keyBlockContent].alt ? block.blockContent[keyBlockContent].alt : ''}>
                                                </Image>
                                            }
                                            if (keyBlockContent === 'text') {
                                                return <p dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></p>
                                            }                     
                                        })}
                                    </div>
                                }

                                if (block.blockType === 'container') {
                                    if (!Array.isArray(block.blockContent)) {
                                        // log('container is object')
                                        return <div className={`tab-content-padding container ${sectionHeadingCount >= 3 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                            {Object.keys(block.blockContent).map((keyBlockContent, indexContent) => {
                                                if (keyBlockContent === 'heading') {
                                                    return <h2 className="heading" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></h2>
                                                }
                                                if (keyBlockContent === 'image') {
                                                    return <Image 
                                                        src={block.blockContent[keyBlockContent].scr ? block.blockContent[keyBlockContent].scr : ''} 
                                                        width={block.blockContent[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                        height={block.blockContent[keyBlockContent].height ? block.blockContent[keyBlockContent].height : ''} 
                                                        className={block.blockContent[keyBlockContent].class ? block.blockContent[keyBlockContent].class : ''} 
                                                        alt={block.blockContent[keyBlockContent].alt ? block.blockContent[keyBlockContent].alt : ''}>
                                                    </Image>
                                                }
                                                if (keyBlockContent === 'text') {
                                                    return <p dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></p>
                                                }         
                                                if (keyBlockContent === 'link') {
                                                    return <p className="padding-bottom-32 m-0 container-link">
                                                        <a href={block.blockContent[keyBlockContent].url} alt={block.blockContent[keyBlockContent].alt} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent].title}}></a>
                                                    </p>
                                                }                              
                                            })}
                                        </div>
                                    } else if (Array.isArray(block.blockContent)) {
                                        // log('container is array')
                                        return <div className={`tab-content-padding container ${sectionHeadingCount >= 3 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                            {block.blockContent.map((blockContentObj, indexBlockContent) => {
                                                let components = []
                                                Object.keys(blockContentObj).map((keyBlockContent, indexContent) => {
                                                    if (keyBlockContent === 'heading') {
                                                        components.push(
                                                            <h2 className="heading" dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent]}}></h2>
                                                        )
                                                    }
                                                    if (keyBlockContent === 'image') {
                                                        components.push(
                                                            <Image 
                                                                src={block.blockContent[keyBlockContent].scr ? block.blockContent[keyBlockContent].scr : ''} 
                                                                width={block.blockContent[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                                height={block.blockContent[keyBlockContent].height ? block.blockContent[keyBlockContent].height : ''} 
                                                                className={block.blockContent[keyBlockContent].class ? block.blockContent[keyBlockContent].class : ''} 
                                                                alt={block.blockContent[keyBlockContent].alt ? block.blockContent[keyBlockContent].alt : ''}>    
                                                            </Image>
                                                        )
                                                    }
                                                    if (keyBlockContent === 'link') {
                                                        components.push(<p className="padding-bottom-32 m-0 container-link">
                                                            <a href={blockContentObj[keyBlockContent].url} alt={blockContentObj[keyBlockContent].alt} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent].title}}></a>
                                                        </p>)
                                                    } 
                                                    if (keyBlockContent === 'text') {
                                                        components.push(
                                                            <p dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent]}}></p>
                                                        )
                                                    } 
                                                })

                                                // Return components array
                                                return <>{components}</>
                                            })}
                                        </div>
                                    }

                                }

                                if (block.blockType === 'section_heading') {
                                    sectionHeadingCount++
                                    return <div className={`tab-content-padding section-heading ${sectionHeadingCount >= 3 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                        {Object.keys(block.blockContent).map((keyBlockContent, indexContent) => {
                                            if (keyBlockContent === 'heading') {
                                                return <h2 className="risk-heading" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></h2>
                                            }
                                            if (keyBlockContent === 'image') {
                                                return <Image 
                                                    src={block.blockContent[keyBlockContent].scr ? block.blockContent[keyBlockContent].scr : ''} 
                                                    width={block.blockContent[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                    height={block.blockContent[keyBlockContent].height ? block.blockContent[keyBlockContent].height : ''} 
                                                    className={block.blockContent[keyBlockContent].class ? block.blockContent[keyBlockContent].class : ''} 
                                                    alt={block.blockContent[keyBlockContent].alt ? block.blockContent[keyBlockContent].alt : ''}>
                                                </Image>
                                            }
                                            if (keyBlockContent === 'text') {
                                                return <p dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></p>
                                            }                                       
                                        })}
                                    </div>
                                }

                                if (block.blockType === 'accordion') {
                                    if (!Array.isArray(block.blockContent)) {
                                        return <div className={`tab-content-padding accordion ${sectionHeadingCount >= 3 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                            <Accordian
                                                bgClass={`${sectionHeadingCount >= 3 && tabCount === 0 ? 'bg-primary-5' : 'bg-primary'}`}
                                                heading={block?.title}
                                                // renderExpanded={sectionHeadingCount == 1 || tabCount == 0 ? false : true}
                                                renderExpanded={true}
                                                key={indexTab}
                                                features={props.features}
                                            >
                                            {Object.keys(block.blockContent).map((keyBlockContent, indexContent) => {
                                                if (keyBlockContent === 'heading') {
                                                    return <h2 className="heading" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></h2>
                                                }
                                                if (keyBlockContent === 'image') {
                                                    return <Image 
                                                        src={block.blockContent[keyBlockContent].scr ? block.blockContent[keyBlockContent].scr : ''} 
                                                        width={block.blockContent[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                        height={block.blockContent[keyBlockContent].height ? block.blockContent[keyBlockContent].height : ''} 
                                                        className={block.blockContent[keyBlockContent].class ? block.blockContent[keyBlockContent].class : ''} 
                                                        alt={block.blockContent[keyBlockContent].alt ? block.blockContent[keyBlockContent].alt : ''}>
                                                        features={props.features}
                                                    </Image>
                                                }
                                                if (keyBlockContent === 'text') {
                                                    return <p dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent]}}></p>
                                                }    
                                                if (keyBlockContent === 'link') {
                                                    return <p className="padding-bottom-32 m-0 container-link">
                                                        <a href={block.blockContent[keyBlockContent].url} alt={block.blockContent[keyBlockContent].alt} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: block.blockContent[keyBlockContent].title}}></a>
                                                    </p>
                                                } 
                                                if (keyBlockContent === 'linear-chart') {
                                                    return <RiskFactor
                                                        // data={{ ...block.blockContent[keyBlockContent] }}
                                                        riskScore={block.blockContent[keyBlockContent].riskScore}
                                                        bands={block.blockContent[keyBlockContent].bands}
                                                        tagStart={block.blockContent[keyBlockContent].tagStart}
                                                        tagEnd={block.blockContent[keyBlockContent].tagEnd}
                                                        type={`Title goes here`}
                                                        key={indexContent}
                                                        features={props.features}
                                                    ></RiskFactor>
                                                }   
                                                if (keyBlockContent === 'conversation') {
                                                    return <ChatMessage
                                                        isMicroMessage='true'
                                                        avatarFillClass='fill-primary-3'
                                                        bgClass='bg-white'
                                                        text={block.blockContent[keyBlockContent]}
                                                        classList="padding-bottom-32"
                                                        features={props.features}
                                                    />
                                                }                                  
                                            })}
                                            </Accordian>
                                        </div>
                                    } else if (Array.isArray(block.blockContent)) {
                                        return <div className={`tab-content-padding accordion ${sectionHeadingCount >= 3 && tabCount === 0 && tabCount === 0 ? 'lower-risk-section' : ''}`}>
                                            <Accordian
                                                bgClass={`${sectionHeadingCount >= 3 && tabCount === 0 && tabCount === 0 ? 'bg-primary-5' : 'bg-primary'}`}
                                                // bgClass={'bg-primary-5'} // used for the blue accordians
                                                heading={block?.title}
                                                //renderExpanded={sectionHeadingCount == 1 || tabCount == 0 ? false : true}
                                                renderExpanded={true}
                                                key={indexTab}
                                                features={props.features}
                                            >
                                                {block.blockContent.map((blockContentObj, indexBlockContent) => {
                                                    let components = []
                                                    Object.keys(blockContentObj).map((keyBlockContent, indexContent) => {
                                                        if (keyBlockContent === 'heading') {
                                                            components.push( 
                                                                <h2 className="heading" dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent]}}></h2>
                                                            )
                                                        }
                                                        if (keyBlockContent === 'image') {
                                                            components.push( 
                                                                <Image 
                                                                    src={blockContentObj[keyBlockContent].scr ? blockContentObj[keyBlockContent].scr : ''} 
                                                                    width={blockContentObj[keyBlockContent].width ? block.blockContent[keyBlockContent].width : ''} 
                                                                    height={blockContentObj[keyBlockContent].height ? blockContentObj[keyBlockContent].height : ''} 
                                                                    className={blockContentObj[keyBlockContent].class ? blockContentObj[keyBlockContent].class : ''} 
                                                                    alt={blockContentObj[keyBlockContent].alt ? blockContentObj[keyBlockContent].alt : ''}>        
                                                                    features={props.features}
                                                                </Image>
                                                            )
                                                        }
                                                        if (keyBlockContent === 'text') {
                                                            components.push(
                                                                <p dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent]}}></p>
                                                            )
                                                        }    
                                                        if (keyBlockContent === 'link') {
                                                            components.push(
                                                                <p className="padding-bottom-32 m-0 container-link">
                                                                    <a href={blockContentObj[keyBlockContent].url} rel="noreferrer" alt={blockContentObj[keyBlockContent].alt} target="_blank"  dangerouslySetInnerHTML={{__html: blockContentObj[keyBlockContent].title}}></a>
                                                                </p>
                                                            )
                                                        } 
                                                        if (keyBlockContent === 'linear-chart') {
                                                            components.push(
                                                                <RiskFactor
                                                                    // data={{ ...blockContentObj[keyBlockContent] }}
                                                                    riskScore={blockContentObj[keyBlockContent].riskScore}
                                                                    bands={blockContentObj[keyBlockContent].bands}
                                                                    tagStart={blockContentObj[keyBlockContent].tagStart}
                                                                    tagEnd={blockContentObj[keyBlockContent].tagEnd}
                                                                    type={`Title goes here`}
                                                                    key={indexContent}
                                                                    features={props.features}
                                                                ></RiskFactor>
                                                            )
                                                        }   
                                                        if (keyBlockContent === 'conversation') {
                                                           components.push(
                                                               <ChatMessage
                                                                    isMicroMessage='true'
                                                                    avatarFillClass='fill-primary-3'
                                                                    bgClass='bg-white'
                                                                    text={blockContentObj[keyBlockContent]}
                                                                    classList="padding-bottom-32"
                                                                    features={props.features}
                                                                />
                                                            )
                                                        }                                  
                                                    })

                                                    // Return components array
                                                    return <>{components}</>
                                                })}
                                            </Accordian>
                                        </div>
                                    }
                                }
                                
                                if (block.blockType === 'contact-form') {
                                    log(props.results);
                                    if (props.results.eligibility === "Yes") {
                                        return <>
                                            <div className="tab-content-padding">
                                                <Accordian
                                                    bgClass={'bg-primary'}
                                                    heading={block.formTitle}
                                                    renderExpanded={true}
                                                    features={props.features}
                                                >
                                                    {(() => {
                                                                let riskLevel = ''
                                                                if (typeof props.results.resultSet.riskLevel) {
                                                                    riskLevel = props.results.resultSet.riskLevel.toLowerCase();
                                                                }

                                                                if (riskLevel === "high") {
                                                                    return (
                                                                        <p>{block.formText1}</p>
                                                                    )
                                                                }
                                                                if (riskLevel === "mod") {
                                                                    return (
                                                                        <p>{block.formText1}</p>
                                                                    )
                                                                }

                                                            }
                                                    )()}
                                                    
                                                    <ProgramSignUpForm
                                                        handleSubmit={props.formSubmitCallback}
                                                        userFirstName={props.userFirstName}
                                                        userEmail={props.userEmail}
                                                        userPhone={props.userPhone}
                                                        loggedInUserData={props.loggedInUserData}
                                                        riskLevelProp={props.results.resultSet.riskLevel}
                                                        formTitle={block.formTitle}
                                                        formText1={block.formText1}
                                                        formText2={block.formText2}
                                                        formText3={block.formText3}
                                                        joinFormSuccess={props.joinFormSuccess}
                                                        features={props.features}
                                                        moodleLink={props.results?.view_in_moodle?.view_in_moodle}
                                                    />
                                                </Accordian>
                                            </div>
                                            {/* <div className="tab-content-padding">
                                                {
                                                <Accordian
                                                    bgClass={'bg-primary'}
                                                    heading={'Just want your results?'}
                                                    renderExpanded={false}
                                                >
                                                    {(() => {
                                                                let riskLevel = ''
                                                                if (typeof props.results.resultSet.riskLevel) {
                                                                    riskLevel = props.results.resultSet.riskLevel.toLowerCase();
                                                                }
                                                                if (riskLevel == "high") {
                                                                    return (
                                                                        <p>Enter your details below and you’ll receive a copy of your results in your inbox.</p>
                                                                    )
                                                                }
                                                                if (riskLevel == "mod") {
                                                                    return (
                                                                        <p>Enter your details below and you’ll receive a copy of your results in your inbox.</p>
                                                                    )
                                                                }
                                                                
                                                            }
                                                    )()}
                                                    <ResultsOnlyForm
                                                        handleSubmit={props.formSubmitCallback}
                                                        userFirstName={props.userFirstName}
                                                        userEmail={props.userEmail}
                                                        userPhone={props.userPhone}
                                                        loggedInUserData={props.loggedInUserData}
                                                        riskLevelProp={props.results.resultSet.riskLevel}
                                                        eligibilityProp={props.results.eligibility}
                                                        joinFormSuccess={props.joinFormSuccess}
                                                        formTitle={block.formTitle}
                                                        formText1={block.formText1}
                                                        formText2={block.formText2}
                                                        formText3={block.formText3}
                                                    />
                                                </Accordian>
                                                }   
                                            </div> */}
                                        </>
                                    } else if (props.results.eligibility === "No") {
                                        return <div className="tab-content" style={{  }}>
                                            <Accordian
                                                bgClass={'bg-primary'}
                                                heading={block.formTitle}
                                                renderExpanded={true}
                                                features={props.features}
                                            >
                                                <ResultsOnlyForm
                                                    handleSubmit={props.formSubmitCallback}
                                                    userFirstName={props.userFirstName}
                                                    userEmail={props.userEmail}
                                                    userPhone={props.userPhone}
                                                    loggedInUserData={props.loggedInUserData}
                                                    riskLevelProp={props.results.resultSet.riskLevel}
                                                    eligibilityProp={props.results.eligibility}
                                                    formTitle={block.formTitle}
                                                    formText1={block.formText1}
                                                    formText2={block.formText2}
                                                    formText3={block.formText3}
                                                    features={props.features}
                                                />
                                            </Accordian>
                                        </div>
                                    }
                                    
                                }

                                if (block.blockType === '') {
                                    return <div className="">
                                    </div>
                                }
                                return null
                            })}
                            
                            {tabCount === 0 &&
                                <div className='suggested-link'>
                                    <Button
                                        innerText="VIEW PROGRAM FOR YOU"
                                        onclick={() => scrollToTab()} 
                                        classList="view-program-button primary"
                                    />
                                </div>
                            }
                        </div>
                    }
                    return null
                }
                )}
            </div>}
            {props.joinFormSuccess && 
            <>
                <div className='joinFormSuccessMessage'>
                    {props.results.eligibility.toLowerCase() === "yes" && props.resultSet.riskLevel.toLowerCase() === "high" && 
                    <>
                        <Hand width="200px" className="svg-thumb high"/>
                        <p className='text-start high'>A copy of your results has been sent to your email. A<span style={{fontStyle: 'italic'}} > My health for life</span> team member will contact you soon to confirm your enrolment and find a coaching option that best suits you.</p>         
                    </>
                    }
                    {(props.results.eligibility.toLowerCase() === "yes" && (props.resultSet.riskLevel.toLowerCase() === "mod" || props.resultSet.riskLevel.toLowerCase() === "moderate" || props.resultSet.riskLevel.toLowerCase() === "low" )) &&
                    <>
                        <Hand width="200px" className="svg-thumb mod" />
                        <div>
                            <p> A copy of your results and the link to your account has also been sent to your email.
                                Click the Continue button to setup your password and get started.
                            </p>
                            <a  id="moodle2url" 
                                title="Click to setup your account and get started." 
                                rel="noreferrer" 
                                className="moodle2url primary component-button" 
                                href={props.moodle2Link} 
                                target='_blank' 
                                >Continue
                            </a>
                        </div>
                    </>
                    }
                    { props.results.eligibility.toLowerCase() === "no"  && 
                    <>
                        <Hand width="200px" className="svg-thumb high"/>
                        <p className='text-start high'>A copy of your results has been sent to your email.</p>         
                    </>
                    }
                </div>
                {props.results?.view_in_moodle?.view_in_moodle?.length > 1 && 
                    <div className='view-in-moodle-container'>
                    {// view in moodle button for logged in HCs.
                        <a
                            title='View participants profile in Moodle'
                            href={props.results.view_in_moodle.view_in_moodle}
                            target="_blank" rel="noreferrer"
                            className='view-in-moodle'
                        >View in Moodle
                        </a>
                    }
                    </div>
                }
            </>
            }
        </div>
    );
}

export default ResultTabs;