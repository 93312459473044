import Button from './Button.js';
import React, { useState, useEffect, useRef } from "react";
import { validateInput } from '../utils/input-validation.js';
import {log} from '../helpers.js';

function DynamicInput(props) {
    const [inputValue, setInputValue] = useState(props.defaultValue)
    const [displayButton, setDisplayButton] = useState(false)
    const [alertError, setAlertError] = useState(false)
    const [alertEmpty, setAlertEmpty] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)
   // const [alertNeutral, setAlertNeutral] = useState(false)
    const [alertCustomError, setAlertCustomError] = useState('')
    const [requestLoading, setRequestLoading] = useState(false)
    const [workplaceInvalidPrompt, setWorkplaceInvalidPrompt] = useState(false)
    //const [keystrokeTimeout, setKeystrokeTimeout] = useState(null)
    const [workplaceCodeValid, setWorkplaceCodeValid] = useState(false)
    const bearerToken = useRef(props.token)
    const [isWorkplace, setIsWorkPlace] = useState(props.slug === "workplace")
    //const [environment, setEnvironment] = useState((props.environment !== '' && typeof props.environment !== 'undefined') ? props.environment : 'prod')
    // let authBasicMap = {
    //     local: 'Basic bWg0bF9kZXZlbG9wZXI6c1ByMW4zREB5MyE=',
    //     dev: 'Basic bWg0bF9kZXZlbG9wZXI6c1ByMW4zREB5MyE=',
    //     uat: 'Basic bWg0bF9kZXZlbG9wZXI6VGgxNWk1VUBU',
    //     stg: 'Basic bWg0bGNybTpCZWR1Z2V5YWotMjY=',
    //     prod: 'Basic bWg0bF9kZXZlbG9wZXI6VGgzbTRMTERlNGQhIQ=='
    // }
    //let timeout = 1000
    //let timeoutFunc = null
    //let init = false

    useEffect(() => {
        if (!isWorkplace) {
            setDisplayButton(true)
        }
    },[isWorkplace])

    async function validateWorkplace() {
        if (inputValue.trim() === '') {
            setAlertEmpty(true);
        } else {
            setAlertCustomError('')
            setAlertEmpty(false)
            setAlertError(false);
            setAlertSuccess(false);
            // setRequestLoading(true);
            let retries = 3
            let response = await isCodeValid(inputValue, retries, bearerToken.current);
            // setRequestLoading(false);
            // first check for any non-200 response that is a numeric response (as the invalid/valid is coming back in the response.status, not the message)
            if (!isNaN(response.status) && response.status!=="200") {
                //setAlertError(true);
                setAlertSuccess(false);
                setAlertCustomError('An Error occured while validating the workplace code ('+response.status + ') '+response?.message )
            } else if (response.status === "Invalid") {
                setAlertError(true);
                setAlertSuccess(false);
                setWorkplaceCodeValid(false)
            } else if (response.status === "Valid") {
                setAlertError(false);
                setAlertSuccess(true);
                setDisplayButton(true);
                setWorkplaceCodeValid(true)
            }
        }
    }

    async function handleKeyPress(event) {
        setAlertCustomError('')
        setWorkplaceCodeValid(false)
        setAlertEmpty(false)
        setAlertError(false)
        setAlertSuccess(false)
        setInputValue(event.target.value)
        setWorkplaceInvalidPrompt(false)
        if (isWorkplace) {
            setDisplayButton(false)
        }
    }

    async function isCodeValid(codeStr, retries, localToken) {
        let formData = new FormData()
        formData.append('workplacecode',codeStr)
        setRequestLoading(true)

        let params = props.params
        if (params.length > 0) {
            if (params.charAt(0) === "&") {
                params = '?' + params.substring(1)
            }
        }

        if (localToken === ''){
            localToken = await props.tokenRefresh();
            bearerToken.current = localToken;
        }
        const response = await fetch(props.apiBase + 'referral/verifyWorkplaceCode' + params, {
            method: 'POST',
            headers: new Headers({
                    "Authorization": "Bearer "+ localToken,
                    "Client-Service": "simbyte-mh4l-api-v1",
                    "Accept": "application/json"
                }),
            mode: "cors",
            body: formData
        })
        .then(response => {
            if(response.ok){
                return response.json()
            } else if (response.status=== 401){
                localToken = ''
                if (retries > 0){
                    return isCodeValid(codeStr, retries-1, localToken)
                }
            }
        
        })
        
        .then((data) => {
            setRequestLoading(false)
            return data
        })
        .catch((error) => {
            setAlertSuccess(false);
            setAlertCustomError('An Error occured while validating the workplace code ('+error.status + ') '+error?.message )
        })
        return response
    }

    function buttonClick() {
        if (isWorkplace) {
            if (workplaceCodeValid) {
                // Workplace code VALID - save and continue
                props.callback(props.slug, inputValue)
            } else {
                // Workplace code INVALID - display message
                setWorkplaceInvalidPrompt(true)
            }
        } else {
            let validationCheck = validateInput(inputValue, props.validation, props.dataType,null, props.answers.current)

            if (validationCheck.stop === true) {
                props.callback(props.slug, inputValue, false)
                props.stopCallback(validationCheck.message)
            } else {
                if (validationCheck.valid) {
                    props.callback(props.slug, inputValue)
                    setAlertCustomError('')
                } else {
                    setAlertCustomError(validationCheck.message)
                    log(validationCheck.report,"warn")
                }
            }
        }
    }

    return (
        <div className={`component-dynamic-input component-form-UI ${props?.classList}`}>
            <input 
                className="form-control to-input transparent-background"
                type={props.inputType}
                value={inputValue}
                placeholder={props.inputPlaceholder}
                onChange={handleKeyPress.bind(this)}
                disabled={requestLoading}
            />
            {requestLoading &&
                <p className="text text-loading">Validating your workplace code <span className="loading"></span></p>
            }
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }
            {alertError &&
                <p className="text text-error">Workplace code invalid</p>
            }
            {alertEmpty &&
                <p className="text text-error">Enter a valid workplace code</p>
            }
            {alertSuccess &&
                <p className="text text-success">Workplace code valid</p>
            }
            {isWorkplace && 
                <Button
                    classList={`primary regular large last-button dynamic-input-button ${!displayButton ? '' : ' hide'} ${requestLoading ? 'disabled' : ''}`}
                    innerText={'Click to validate'}
                    onclick={validateWorkplace}
                    disabled={requestLoading}
                    features={props.features}
                />
            }
            <Button
                classList={`primary regular large last-button dynamic-input-button ${displayButton ? '' : ' hide'} ${requestLoading ? 'disabled' : ''}`}
                innerText={props.buttonText}
                onclick={buttonClick.bind(this)}
                features={props.features}
            />
            {workplaceInvalidPrompt &&
                <p className="text text-error">You must enter a valid workplace code</p>
            }
        </div>
    );
  }
  
  export default DynamicInput;