export function getDOMElement(selector = '', type = null) {
    if (type == null && selector === '') {
        return '';
    } else if (type !== 'id' && type !== 'className' && type !== 'querySelectorAll') {
        return 'invalid selector type';
    }

    let root = null;
    let el = null;

    if (document.getElementById('healthcheckembed') == null) {
        root = document;
    } else {
        root = document.getElementById('healthcheckembed').shadowRoot.getElementById('mh4l-root');
    }

    if (type === 'id') {
        el = root.querySelectorAll('#' + selector)[0];
    } else if (type === 'className') {
        el = root.getElementsByClassName(selector);
    } else if (type === 'querySelectorAll') {
        el = root.querySelectorAll(selector);
    }

    return el;
}

export function isMobile() {
    var isMobile = false;

    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;
    }

    return isMobile;
}

export function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}
// tidyConditionalString used to cleanup the condition string on the questions
export function tidyConditionalString(string) {
//    string = string.replaceAll('{','')
    string = string.split('{').join('');

//    string = string.replaceAll('}','') // 
    string = string.split('}').join('');
//    string = string.replaceAll('\"','') // remove backslash with double quotation marks
    string = string.split('\\"').join('');
//    string = string.replaceAll('"','') // remove double quotation marks
    string = string.split('"').join('');
//    string = string.replaceAll('\\','') // remove backslash
    string = string.split('\\').join('');
    string = string.trim() // Remove whitespace from ends

    return string
  }

  export function tidyPlaceholderStr(string) {
    string = string.replaceAll('_',' ')
    string = string.toLowerCase()

    return string
  }
/****************************************************************
    checkConditions takes 3 args
    responses should be all responses provided up to this point
    question should be the current question props
    condition should be the condition we're testing
    return true to show the question
*/
export function checkConditionsShowQuestion (answers, question, condition, hiddenQuestions,queryParams) {
    // Check for null condition
    if (typeof condition == 'undefined' || condition == null || condition === '') {
      // No conditions - this is here purely for a catch all and readability 
      log('No condition for question ' + question.questionName)
      return true
    } else {
        // Check count of "|" delimiter with split
        if (condition.includes('|') && condition.split('|').length === 2) {
            let explodedConditionStr = condition.split('|') 
            log("Condition from question.condition:")
            log(explodedConditionStr[0])

            // Check for externalonly and return
            if (explodedConditionStr[0].trim().toUpperCase() === '{{EXTERNALONLY}}' || explodedConditionStr[0].trim().toUpperCase() === '"{{EXTERNALONLY}"}') {
                if (queryParams.get('userid')== null) {
                    if (hiddenQuestions.current.includes(question.questionName)){
                        hiddenQuestions.splice(hiddenQuestions.current.indexOf(question.questionName),1)
                    }
                    return true
                } else {
                    if (!hiddenQuestions.current.includes(question.questionName)){
                        hiddenQuestions.push(question.questionName)
                    }
                    return (false)
                }
            }

            // Remove any and all " characters
            condition = explodedConditionStr[0].split('"').join('')
            log("condition before meta replacement")
            log(condition)

            // Encapsulate all response meta keys in quotations
            let numberCap = 32
            for (let i = 0; i <= numberCap; i++) {
            let searchStr = 'response' + i
            let replacementStr = '"response' + i + '"'
            condition = condition.split(searchStr).join(replacementStr)
            condition = condition.split(searchStr.toUpperCase()).join(replacementStr)
            }

            condition = condition.split('NULL').join('"NULL"')
            condition = condition.split('null').join('"NULL"')

            log("condition before tag replacement")
            log(condition)

           // Replace all {{tags}} with response meta key if presence, else replace all {{tags}} with response value.
           // eg. {{sex}}=="response2" has the sex replaced with the actual answer and then use eval to test the logic
           let tag = condition.match(/\{{2}\w+\}}/g)

           let theAnswer = "";
           // tag = sex, now we replace it with the answer if there is one
           // answers can be in children of the parent answers in the answer object so we check for them too
           // let's find sex, for example
           if (tag.length > 0) {
                if (answers.current.hasOwnProperty(tag[0].split('{{').join('').split('}}').join(''))){
                        // it's in the top level so just replace it
                        theAnswer = answers.current[tag[0].split('{{').join('').split('}}').join('')].answer
                } else {
                    for (const [key, value] of Object.entries(answers.current)){
                        if(/^\s*(\{|\[)/.test(value['answer'])){
                            let childAnswers=[]
                            try{
                                childAnswers = JSON.parse(value['answer'])
                                log(childAnswers)
                            }catch(e){
                                //  do nothing or return false
                            }
                            // we now have a json object of the child answers to search
                            if (childAnswers.hasOwnProperty(tag[0].split('{{').join('').split('}}').join(''))){
                                // get the answer for that tag from the childAnswers object
                                theAnswer = childAnswers[tag[0].split('{{').join('').split('}}').join('')]
                            }
                        }
                        else {
                            // answer isn't json but didn't have a named key perhaps, it's from the healthcheck.js rather than multiquestion
                            for (const [key, value] of Object.entries(answers.current)) {
                                if(tag[0].split('{{').join('').split('}}').join('') === value.slug){
                                    theAnswer = value.answerMeta
                                    break;
                                }
                              }
                        }
                    }
                }
                if (theAnswer !== "" && tag.length>0){
                    // now we have worked out the answer we replace the condition string tags with the answer
                    // so {{sex}}==response2 becomes response1==response2  (where response1 was that answer provided for sex and response2 is our conditional value check)
                    // check the answer isn't a number
                    if (!isNaN(parseInt(theAnswer))) {
                        condition = condition.split(tag[0]).join(theAnswer)
                    } else {
                        // got string so wrap in quotes.
                        condition = condition.split(tag[0]).join('"' + theAnswer + '"')
                    }
                    log("condition tag replacement returned "+ condition,"info")
                }
                else {
                    log("no answer for "+tag[0],"info")
                }
            }
        
            if (question.questionType === "Single-Select-Waist") {
                // This is a waist input question
                // This type of question should only be asked once
                // It could be one of a series of question on waist size, i.e. this could be the 2nd or 3rd time this question is asked
                // Assumption - there will only be one waist input question for each gender
                // Get previously stored reponse for gender and force the display of only the correct waist input
                // Assumption - input from sex question can be appended to the str "waist_size_" to get the slug for the waist size
                // Original solution was to look for waist inputs recorded - this doesn't allow for if a user navigates back as a waist answer would already be recorded, skipping all waist inputs

                // Initialise question index modifier as 1
                //let indexModifier = 1

                // Create 

                // get previously recorded responses for "waist_size_male", "waist_size_female", and "waist_size_other"
                let sexAnswer = getAnswerForSlug('sex', answers)
                sexAnswer = sexAnswer.trim() // trim it
                sexAnswer = sexAnswer.toLowerCase()

                log("waist_size_" + sexAnswer)
                log("question.questionName="+question.questionName)

                // // Check if response has been stored
                if (question.questionName === ("waist_size_" + sexAnswer)) {
                    // Current waist input matches sex provided
                     return true
                }
            }

            log("condition before evaluation"+condition)
            if (condition.includes("{{")){
                log("condition question has no answer "+ condition,"info")
                // note that the behviour is hard coded if there is no answer.
                // if you want a question to default to SHOW then build the logic around that
                // if you want the question to default to HIDE then build the logic around that
                // eg !{{atsi}}=="response1" | 'HIDE' will hide if the atsi has no value
                // and {{atsi}}=="response1" | 'SHOW' will show if the atsi has no value
                if(explodedConditionStr[1].trim() === 'SHOW'){
                    // conditional SHOW but no answer - we will show
                    return true;
                }
                if(explodedConditionStr[1].trim() === 'HIDE'){
                    // conditional HIDE but no answer - we will hide
                    return false
                }

            }

            // Evaluate the condition
            let result = false 
            try {
                result = eval(condition)
            } catch (err) {
                log('Question condition error: expression couldn\'t be evaluated. \nCondition: "'+condition+'"')
                return result
            }

            if (typeof result == 'boolean') {
                // Do nothing
            } else {
                log('Question condition error: expression didn\'t evaluate to a true/false. \nCondition: "'+condition+'"')
                return result
            }

            // Tidy the action string - nothing to tidy so remarked this out -- Andrew
            //let tidyConditionActionStr = tidyConditionalString(explodedConditionStr[1])
            let tidyConditionActionStr = explodedConditionStr[1].trim()
            //let newHiddenQuestions = hiddenQuestions
            if (result === true && tidyConditionActionStr === 'SHOW') {
                // SHOW this question - maintain the current index (effectively no change)
                log('question.condition evaluated TRUE on action SHOW - showing question')
            return true

            } else if (result === true && tidyConditionActionStr === 'HIDE') {
                // HIDE this question (i.e. skip this question) - increase question index by one
                log('question.condition evaluated TRUE on action HIDE - skipping question')
            return false

            } else if (result === false && tidyConditionActionStr === 'SHOW') {
                // SHOW is the action, but the condition failed (i.e. skip this question) - increase question index by one
                log('question.condition evaluated FALSE on action SHOW - skipping question')
            return false

            } else if (result === false && tidyConditionActionStr === 'HIDE') {
                // HIDE is the action, but the condition failed (i.e. skip this question)
                // maintain the current index (effectively no change)
                log('question.condition evaluated FALSE on action HIDE - showing question')
            return true

            }
        } else {
        // Too many "|" key delimiters added - error out
        log("Error in question condition: Question can only contain one \"|\" delimiter.")
        // default to true - show it if the condition has an error
        return true
        } 
    }
}
      // Gets the answer for a slug from stored responses
  // Returns string
export function getAnswerForSlug(slug, answers) {
    let answer = ''
    //for (let i = 0; i < questionResponses.current.length; i++) {
      // if (questionResponses.current[i].slug == slug) {
      //   answer = questionResponses.current[i].answer // get previously recorded answer
      // }
      // we also need to check childQuestions for the slug/answer      
      for (const [key, value] of Object.entries(answers)){
          if ( value['slug'] === slug){
            answer = value
            break
          } else if (/^\s*(\{|\[)/.test(value['answer'])){
              let childAnswers=[]
              try{
                  childAnswers = JSON.parse(value['answer'])
              }catch(e){
                  //  do nothing or return false
              }
              // we now have a json object of the child answers to search
              if (childAnswers.hasOwnProperty(slug)){
                  // get the answer for that tag from the childAnswers object
                  answer = childAnswers[slug]
                  log("got "+answer+ " for slug="+slug, "debug")
                  break
              }
          }
      }
    //}
    log("getAnswerForSlug slug="+slug + " answer="+answer, "debug")
    return answer
}
export   function getAnswerMetaFromAnswer(slug, answer, question) {
    let answerMeta = ''
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug === slug) {
        answerMeta = questionResponses.current[i].answerMeta // get previously recorded answer
      }
      if (/^\s*(\{|\[)/.test(questionResponses.current[i].answer)){
        let childAnswers=[]
        try{
            childAnswers = JSON.parse(questionResponses.current[i].answer)
        }catch(e){
            //  do nothing or return false
        }
        if (childAnswers.hasOwnProperty(slug)){
          // get the answerMeta for that tag from the questionSet
          // find the slug in the child questions
          for (let x=0; x<questionSet.length; x++){
            // loop through each parent question checking for children
            if(questionSet[x].childQuestions.hasOwnProperty(slug)){
              console.dir(questionSet[x].childQuestions[slug])
              //TODO add meta lookup that matches child responseX
            }
          }
          break
        }      
      }
    }
    return answerMeta
  }

  // log centralises the display and logging of errors
  // logMessage is a string
  // eloghowLevel is a string of debug, info, warn, elog none
  // elogevel is the level of the elogas a string of debug, info, warn, elog none
  // default is to show elogonly, and default elogevel is none
  // if called like log("display this elog) then it will not display 
  //  but will store the logged event
  // override in the env using REACT_APP_LOGSHOWLEVEL
  export function log(logMessage, logLevel = null, logShowLevel=null){

    function logDebug(logMessage){
        //console.debug(logMessage);
        console.trace(logMessage);
    };
    
        if(typeof logMessage == "undefined" && logMessage==null){
            return null
        }
        if (logLevel==null) { 
            logLevel="info" // defaults to info so we can call log("some message") without any other params for general messages
        }

        if (logShowLevel==null){
            // no logShowLevel passed so lets check for a default react var
            if(typeof process.env.REACT_APP_LOGSHOWLEVEL!="undefined" && process.env.REACT_APP_LOGSHOWLEVEL.length > 0){
                logShowLevel=process.env.REACT_APP_LOGSHOWLEVEL
            }
        }
        // let the url params override logShowLevel
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if (params.get('logShowLevel')!=null && params.get('logShowLevel')?.length!== 0 && ["debug","info","warn","error"].includes(params.get('logShowLevel'))){
            logShowLevel = params.get('logShowLevel')
        }

        switch(typeof logMessage){
            case "string": 
                break;
            case "number":
                break;
            case "object":
                logMessage = JSON.stringify(logMessage)
                break;
            case "boolean":
                logMessage=logMessage?"true":"false";
                break;
            default:
                console.warn("logMessage passed to log() with typeof=" + typeof(logMessage))
                console.trace()
                return null
        }
        switch (logShowLevel) {
            // no break as debug implies showing all the other log levels
            case "debug":
                switch (logLevel) {
                    case "debug":
                        logDebug(logMessage)
                        break;
                    case "info":
                        //console.info(logMessage)
                        console.trace(logMessage)
                        break;
                    case "warn":
                        //console.warn(logMessage)
                        console.trace(logMessage)
                        break;
                    case "error":
                        //console.error(logMessage)
                        console.trace(logMessage)
                        break;
                    default:
                }
                break;
            case "info":
                switch (logLevel) {
                    case "info":
                        console.info(logMessage)
                        break;
                    case "warn":
                        //console.warn(logMessage)
                        console.trace(logMessage)
                        break;
                    case "error":
                        //console.error(logMessage)
                        console.trace(logMessage)
                        break;
                    default:
                        break;
                }
                break;
            case "warn":
                switch (logLevel) {
                    case "warn":
                        console.warn(logMessage)
                        break;
                    case "error":
                        console.error(logMessage)
                        break;
                    default:
                        break;
                }
                break;
            case "error":
                switch (logLevel) {
                    case "error":
                        console.error(logMessage)
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        return null;
  }
// selectInputMode takes the question name and type (slug and type) and determines 
// the appropriate inputmode and pattern for text input controls
// this assists mobile users by showing the appropriate keyboard.
// note: pattern will affect what can be entered on most browsers so 
//       I have avoided setting it as only age and waist is numberic only.
export function selectInputMode (slug, type){
    switch (slug) {
        case "postcode":
            return JSON.parse('{ "mode": "numeric", "pattern": "", "type":"text" }')
        case "age":
            return JSON.parse('{"mode": "numeric", "pattern": "[0-9]*", "type":"number" }')
        case "waist":
            return JSON.parse('{"mode": "numeric", "pattern": "[0-9]*", "type":"text" }')
        case "email":
            return JSON.parse('{"mode": "email", "pattern": "", "type":"email" }')
        case "phone":
            return JSON.parse('{"mode": "tel", "pattern": "", "type":"number" }')
        default:
            return JSON.parse('{"mode": "text", "pattern": "", "type":"text" }')
    }
}

/************************************
 *  parseInfoSections takes the questionText
 *  and returns an array of INFO Text 
 *  that can be rendered with MDBToolTip
 *  (react bootstrap tooltip component)
 */
export function parseInfoSections(text) {
    const regex = /<INFO>(.*?)<\/INFO>/g;
    const matches = [];
    let match;
    
    while ((match = regex.exec(text)) !== null) {
        matches.push(match[1]);
    }
    
    return matches;
}