import Button from './Button.js';
import React, { useState, useEffect } from "react";
import {landingPageEvent} from "../utils/facebookPixelEvent"

function DynamicYesNo(props) {
    useEffect(() =>{
        landingPageEvent();
    },[]);
    const [selectedButton, setSelectedButton] = useState("");

    function buttonClick(choice) {
        setSelectedButton(choice);
        props.callback(props.slug, choice);
    }

    return (
      <div className={`component-yes-no component-dynamic-yes-no ${props.classList}`}>
            {props.innerText !== '' &&
                <h1>{props.innerText}</h1>
            }
            <div className={`button-wrapper d-flex justify-content-between ${props.collapseButtons ? 'column' : ''}`}>
                {props.customisation === "Responses as Buttons" &&  
                <>
                    <Button
                        classList={`regular primary ${selectedButton === props.options[0] ? "selected" : ""}`}
                        innerText={props.options[0]}
                        onclick={buttonClick.bind(this, props.options[0]+'')}
                        features={props.features}
                    /> 
                    <Button
                        classList={`regular primary ${selectedButton === props.options[1] ? "selected" : ""}`}
                        innerText={props.options[1]}
                        onclick={buttonClick.bind(this, props.options[1]+'')}
                        features={props.features}
                    />
                </>
                }
                {props.customisation !== "Responses as Buttons" &&
                <>
                    <label>
                        <input type='radio' 
                            name={props.slug}
                            classList={`regular primary ${selectedButton === props.options[0] ? "selected" : ""}`}
                            //checked={selectedOption === props.options[0]}
                            onChange={buttonClick.bind(this, props.options[0]+'')}
                        />
                        {props.options[0]}
                    </label>
                    <label>
                        <input type='radio' 
                            name={props.slug}
                            classList={`regular primary ${selectedButton === props.options[1] ? "selected" : ""}`}
                            //checked={selectedOption === props.options[1]}
                            onChange={buttonClick.bind(this, props.options[1]+'')}
                        />{props.options[1]}
                    </label>
                </>
                }
            </div>
      </div>
    );
  }
  
  export default DynamicYesNo;