import React, { useState, useEffect } from "react";

function Image(props) {
    return (
        <div className={`component-image`} >
              <img  src={props.src} 
                    alt={props.alt ? props.alt : ''} 
                    className={props.className ? props.className : ''}
                    height={props.height ? props.height : ''}
                    width={props.width ? props.width : ''}
                    loading="lazy" />
        </div>
    );

}

export default Image;
