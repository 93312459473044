import React, { useRef } from "react"
import {log} from "../helpers"

function WaistTable(props) {
    const preloaderRef = useRef()
    const tableRef = useRef()

    function handleKeyDown(size_cm, e){
        if (e.key === 'Enter'){
            props.callback(size_cm)
        }
        log("keypress"+size_cm)
    }

    if (typeof props.response != 'undefined') {
        if (Array.isArray(props.response)) {
            let keys = []

            if (props.response.length > 0) {
                keys = Object.keys(props.response[0])
            }

            function handleSizeSelect(cm) {
                props.callback(cm)
            }

            return (
                <>
                    {props.response.length === 0 &&
                        <p style={{textAlign:"center"}} ref={preloaderRef}><span className="loading"></span></p>
                    }

                    {props.response.length > 0 &&
                    <div className={`table ${keys.length <=3 ? 'thirds' : 'quarters'}`} ref={tableRef}>
                        <div className="tr" >
                            {keys.map((key, index) => {
                                // if (index > 0)    
                                    return <div className="th" key={index}>{key}</div>
                                }
                            )}
                        </div>

                        {props.response.map((row, index) => {

                            // if (row['Gender'].toUpperCase() == props.sex)
                                return <div className="tr" key={index} tabIndex={index+1} onClick={handleSizeSelect.bind(this, row['Waist Size (cm)'])} onKeyDown={handleKeyDown.bind(this,row['Waist Size (cm)'])}>
                                    {keys.map((key, index) => {
                                        // if (index > 0)    
                                            return <div className="td" key={index}>{row[key]}</div>
                                        }
                                    )}
                                </div>
                            }
                        )}
                    </div>
                    }
                </>
            )
        }
    }
}

export default WaistTable;