//import Button from './Button.js';
import DynamicYesNo from './DynamicYesNo.js';
import React, { useState } from "react";
import { validateInput } from '../utils/input-validation.js';
//import MultiQuestion from './MultiQuestion.js';
import {log} from '../helpers.js';

function CVDScore(props) {
    const [inputValue, setInputValue] = useState('')
    const [alertCustomError, setAlertCustomError] = useState('')

    function handleKeyPress(event) {
        setInputValue(event.target.value)
        setAlertCustomError('')
        if(props.fieldOnly){
            let validationCheck = validateInput(event.target.value, props.validation, 'cvdscore',null, props.answers.current)
            if (validationCheck.valid) {
                props.callback(props.slug, event.target.value)
                setAlertCustomError('')
            } else {
                setAlertCustomError(validationCheck.message)
                log(validationCheck.report,"warn")
            }
        }
    }

    function handleButtonReponse(slug, answer, boo = false) {
        if (answer === props.buttons.response1) {
            let callbackValue = inputValue
            let validationCheck = validateInput(callbackValue, props.validation, 'cvdscore', null, props.answers.current)
            if (validationCheck.valid) {
                props.callback(props.slug, callbackValue)
                setAlertCustomError('')
            } else {
                setAlertCustomError(validationCheck.message)
                log(validationCheck.report,"warn")
            }
        } else if (answer === props.buttons.response2) {
            props.callback(props.slug, "NULL");
        }
    }

    return (
        <div>
            <div className={`component-cvd-score`}>
                <input type="text"
                    className="form-control transparent-background"
                    placeholder={props.explanationText}
                    onChange={handleKeyPress.bind(this)}
                />
                <div className="component-cvd-score information-text">{props.explanationText}</div>
            </div>
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }

            {!props.fieldOnly && 
                <DynamicYesNo
                innerText=""
                options={[props.buttons.response1, props.buttons.response2]}
                collapseButtons={true}
                callback={handleButtonReponse}
                slug=""
                customisation={props.options}
                features={props.features}
            />
            }
        </div>
    );
}

export default CVDScore;