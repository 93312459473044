import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/features/array';
import 'core-js/es/object/entries';
import 'core-js/es/object';
import 'react-app-polyfill/ie11';
import 'es7-object-polyfill';
import 'es6-promise/auto';
import 'object.entries/auto'
import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './styles/_main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_ID;
const tagManagerArgs = {
  gtmId: googleTagManagerId,
};
TagManager.initialize(tagManagerArgs);

if (document.getElementById('healthcheckembed') == null) {
  const root = ReactDOM.createRoot(document.getElementById('mh4l-root'));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const shadowRoot = document.getElementById('healthcheckembed').shadowRoot.getElementById('mh4l-root');
  const root = createRoot(shadowRoot);
  shadowRoot.classList.add('embed');

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
