import Button from './Button.js'
import YesNo from './YesNo.js'
import AutocompleteInput from './AutocompleteInput.js'
import React, { useState, useLayoutEffect, createRef, useEffect } from "react"
import { validateInput } from '../utils/input-validation.js';
import MultiChoice from './MultiChoice.js';
import { checkConditionsShowQuestion, tidyPlaceholderStr, getAnswerForSlug, log, selectInputMode, parseInfoSections } from '../helpers.js';
import CVDScore from './CVDScore.js';
import BloodPressure from './BloodPressure.js';

function MultiQuestion(props) {
    function loadAnswers (answer) {
        if(!props.features.backrecall){
            return ''
        }
        if(typeof answer!='undefined'){
            let newAnswer = answer.find((item) => item.slug===props.slug)
            if (typeof newAnswer!='undefined'){
                return newAnswer.answer
            } else {
                return ''
            }
        } else {
            return ''
        }
}
    const [inputValues, setInputValues] = useState(props.features.backrecall?(typeof props.answers!='undefined'?loadAnswers(props.answers.current):''):'')
    //const [inputValues, setInputValues] = useState('')
    const [yesNoValues, setYesNoValues] = useState('')
    const [selectOptions, setSelectOptions] = useState([])
    const [alertCustomError, setAlertCustomError] = useState('')
    const [isAlertCustomError, setIsAlertCustomError] = useState(false)
    const [validatedDropdowns, setValidatedDropdown] = useState([])
    const alertRefs = React.useRef([])
    const multiQuestionRefs = React.useRef([])
    const hiddenQuestions = React.useRef([props.hiddenQuestions])
    const iconInfo = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGhlaWdodD0nMTYwJyB3aWR0aD0nMTYwJyB2ZXJzaW9uPScxLjAnPjxnIGZpbGw9JyMyZjU3YjMnPjxwYXRoIGQ9J204MCAxNWMtMzUuODggMC02NSAyOS4xMi02NSA2NXMyOS4xMiA2NSA2NSA2NSA2NS0yOS4xMiA2NS02NS0yOS4xMi02NS02NS02NXptMCAxMGMzMC4zNiAwIDU1IDI0LjY0IDU1IDU1cy0yNC42NCA1NS01NSA1NS01NS0yNC42NC01NS01NSAyNC42NC01NSA1NS01NXonLz48cGF0aCBkPSdtNTcuMzczIDE4LjIzMWE5LjM4MzQgOS4xMTUzIDAgMSAxIC0xOC43NjcgMCA5LjM4MzQgOS4xMTUzIDAgMSAxIDE4Ljc2NyAweicgdHJhbnNmb3JtPSdtYXRyaXgoMS4xOTg5IDAgMCAxLjIzNDIgMjEuMjE0IDI4Ljc1KScvPjxwYXRoIGQ9J205MC42NjUgMTEwLjk2Yy0wLjA2OSAyLjczIDEuMjExIDMuNSA0LjMyNyAzLjgybDUuMDA4IDAuMXY1LjEyaC0zOS4wNzN2LTUuMTJsNS41MDMtMC4xYzMuMjkxLTAuMSA0LjA4Mi0xLjM4IDQuMzI3LTMuODJ2LTMwLjgxM2MwLjAzNS00Ljg3OS02LjI5Ni00LjExMy0xMC43NTctMy45Njh2LTUuMDc0bDMwLjY2NS0xLjEwNScvPjwvZz48L3N2Zz4K";

    props.features.backrecall ? log("multiQuestionRefs="+JSON.stringify(multiQuestionRefs)) : null
    props.features.backrecall ? log("initial MultiQuestion inputValues : "+ inputValues) : null
    // multiQuestionRefs.current = props.childQuestions.map((questionName) => multiQuestionRefs. = {questionName: questionName, answer: null})
    if (typeof props.childQuestions=='undefined'){
        log("MultiQuestion has no child questions", "error")
        //props.setErrorMsgCallBack("MultiQuestion has no child questions")
        //props.setErrorStateCallBack(true)
        //return null
    }
    // alertRefs is used to hold references to all the error <p> tags under each question.
    if (alertRefs.current.length === 0) {
        alertRefs.current = props.childQuestions.map((question, index) => alertRefs[index] ?? createRef())
    }

    //  useEffect(() => {
    // //     // check for alertRefs that aren't hidden
    //      let firstError = alertRefs.current.map((item) => {
    //          if (item.current.classList && !item.current.classList.includes("hide")){
    //              console.log("error alertRef "+item)
    //              firstError = item
    //          }
    //          return firstError
    //      })
    //      // find the first one and scroll to it.
    //    })

    // Check for default values incase user has come through moodle
    useEffect(() => {
        let obj = {};

        questions.map((question, index) => {
            if (question.defaultValue != null && typeof question.defaultValue != 'undefined') {
                obj[question.questionName] = question.defaultValue;
            }
        });
        let inputJSON = JSON.stringify(obj);
        setInputValues(inputJSON);

        if(props.features.backrecall){
            let multiQuestionArray = [];

            for (let i=0; i<props.childQuestions.length; i++){
                //const q = {slug: props.childQuestions[i].questionName, answer: null, answerMeta: null, ref: createRef()};
                const q = { answer: null, answerMeta: null, ref: createRef()}
                multiQuestionArray[props.childQuestions[i].questionName] = q
            }
            log("calling setMultiQuestionRefs "+multiQuestionArray)
            multiQuestionRefs.current = multiQuestionArray
        }
    }, [])

    useEffect(()=>{
        log("inputValues useEffect reacting "+inputValues);
    },[inputValues])

    function writeInputs(name, value) {
        // Check if str is empty and reset
        let fauxInputValues = inputValues
        if (inputValues === '' || typeof inputValues == 'undefined' || inputValues == null) {
            let obj = {}
            fauxInputValues = JSON.stringify(obj)
        }

        // Parse JSON for use
        let inputValuesObject = JSON.parse(fauxInputValues)
        inputValuesObject[name] = value // leverage question name

        let inputValuesJSON = JSON.stringify(inputValuesObject) // Write as JSON string
        log("MultiQuestion -  setInputValues "+ inputValuesJSON)
        setInputValues(inputValuesJSON) // Save new JSON object
    }

    function handleInputChange(event) {
        // get question details from slug and call validation check
        let thisQuestion = props.childQuestions[props.childQuestions.findIndex(e => e.questionName === event.target.name)]
        if (typeof thisQuestion === 'object'){
                    // Parse stored answer JSON
            let fauxInputValues = inputValues
            // log("inputValues == ''")
            // log(inputValues == '')
            if (inputValues === '' || typeof inputValues == 'undefined' || inputValues == null) {
                let obj = {}
                fauxInputValues = JSON.stringify(obj)
            }
            let inputValuesObject = JSON.parse(fauxInputValues)
            let validationCheck = validateInput(event.target.value, thisQuestion.validation, thisQuestion.dataType,null, inputValuesObject)
            if (validationCheck.valid) {
                writeInputs(event.target.name, event.target.value)
                // Loop through childQuestions and get index for this event.target.name
                for (let i = 0; i < props.childQuestions.length; i++) {
                    // Hide the errormessage
                    if (typeof alertRefs != 'undefined') {
                        if (typeof alertRefs.current[i] != 'undefined') {
                            if (props.childQuestions[i].questionName === event.target.name && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                                alertRefs.current[i].current.innerHTML = ''
                                alertRefs.current[i].current.classList = 'text text-error hide'
                            }
                        }
                    }
                }
            } 
            else {
                // clear out the answer if it's not valid - could be an old answer still in the inputs
                let inputValuesObject = JSON.parse(inputValues)
                if(inputValuesObject.hasOwnProperty(event.target.name)){
                    delete inputValuesObject[event.target.name]
                    setInputValues(JSON.stringify(inputValuesObject))
                }
                // Loop through childQuestions and get index for this event.target.name
                for (let i = 0; i < props.childQuestions.length; i++) {
                    // Hide the errormessage
                    if (typeof alertRefs != 'undefined') {
                        if (typeof alertRefs.current[i] != 'undefined') {
                            if (props.childQuestions[i].questionName === event.target.name && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                                alertRefs.current[i].current.innerHTML = validationCheck.message
                                alertRefs.current[i].current.classList = 'text text-error'
                            }
                        }
                    }
                }
            }
        } else {
            log("error in validating "+thisQuestion.questionName,"error")
        }
    }

    function handleMultiChoiceResponse(slug, answer, boo = false){
        if (slug != null && answer != null) {
            writeInputs(slug, answer)
            log("slug="+slug+", answer="+answer);
             // Loop through childQuestions and get index for this event.target.name
            for (let i = 0; i < props.childQuestions.length; i++) {
                // Hide the errormessage
                // alertRefs.current[i].current.innerHTML = ''
                // alertRefs.current[i].current.classList = 'text text-error hide'
                if (typeof alertRefs != 'undefined') {
                    if (typeof alertRefs.current[i] != 'undefined') {
                        if (props.childQuestions[i].questionName === slug && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                            alertRefs.current[i].current.innerHTML = ''
                            alertRefs.current[i].current.classList = 'text text-error hide'
                        }
                    }
                }
            }
        }
    }


    function handleYesNoButtonReponse(slug, answer, boo = false) {
        if (slug != null && answer != null) {
            writeInputs(slug, answer)

             // Loop through childQuestions and get index for this event.target.name
            for (let i = 0; i < props.childQuestions.length; i++) {
                // Hide the errormessage
                // alertRefs.current[i].current.innerHTML = ''
                // alertRefs.current[i].current.classList = 'text text-error hide'
                if (typeof alertRefs != 'undefined') {
                    if (typeof alertRefs.current[i] != 'undefined') {
                        if (props.childQuestions[i].questionName === slug && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                            alertRefs.current[i].current.innerHTML = ''
                            alertRefs.current[i].current.classList = 'text text-error hide'
                        }
                    }
                }
            }
        }
    }

    function handleSelectButtonResponse(slug, answer, boo = false) {
        log("in MultiQuestion.handleSelectButtonResponse with slug="+slug+" answer="+answer)
        if (slug != null && answer != null) {
            // writeInputs(slug, answer)
            // for postcode, because the answer is a combination of postcode and suburb, we have to treat it differently
            if(slug==="postcode"){
                log("calling writeInputs for postcode with slug="+slug+" answer="+answer)
                const validAnswers = boo
                const postcode = answer.split(", ")[1]
                const suburb = answer.split(", ")[0]
                // if they just enter a suburb into the postcode box then we end up here with the text only. 
                // so lets check we have a valid 'suburb, postcode' or invalidate it and remove the old answer 
                // if they have entered a valid answer before and then modified it.
                if (postcode && suburb && validAnswers.findIndex(e => e.Locality === suburb && e.Pcode === postcode)!==-1){
                    writeInputs(slug, answer)
                    let qid = props.childQuestions.findIndex(e => e.questionName === slug)
                    alertRefs.current[qid].current.innerHTML = ''
                    alertRefs.current[qid].current.classList = 'text text-error hide'
                } else {
                    // if it's not valid we have to clear out any previous answer
                    writeInputs(slug, '')
                    let qid = props.childQuestions.findIndex(e => e.questionName === slug)
                    alertRefs.current[qid].current.innerHTML = 'invalid postcode, please enter a postcode and choose from the list'
                    alertRefs.current[qid].current.classList = 'text text-error'
                }
            } else {
                let ind = Object.values(props.childQuestions[props.childQuestions.findIndex(e => e.questionName === slug)].questionResponses).findIndex(e => e === answer)
                if (ind > -1){
                    writeInputs(slug, Object.keys(props.childQuestions[props.childQuestions.findIndex(e => e.questionName === slug)].questionResponses)[ind])
                }
                
                // Loop through childQuestions and get index for this event.target.name
                for (let i = 0; i < props.childQuestions.length; i++) {
                    // Hide the errormessage
                    if (typeof alertRefs != 'undefined') {
                        if (typeof alertRefs.current[i] != 'undefined') {
                            if (props.childQuestions[i].questionName === slug && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                                alertRefs.current[i].current.innerHTML = ''
                                alertRefs.current[i].current.classList = 'text text-error hide'
                            }
                        }
                    }
                }
            }
        }
    }

    function handleDropdownValidationCallback(name, value) {
        log ("in multiquestion handleDropdownValidationCallback (name,value)")
        log ("name:"+name,"debug")
        log ("value:"+ value,"debug")
        // just checks if the questionName is in the validatedDropDowns list - seems pointless as they always seem to be in there.
        // and so writeInputs is never called. ??
        if (!validatedDropdowns.includes(name)) {
            validatedDropdowns.push(name)
            let newArr = validatedDropdowns;
            setValidatedDropdown(newArr);
            writeInputs(name, value);
            log("function handleDropdownValidationCallback name="+name+ " value="+value)
        }
    }

    function handleDropdownValidationCallbackNull(name, value) {
        log ("in multiquestion handleDropdownValidationCallbackNull (name,value)","debug")
        log ("name:" + name,"debug")
        log ("value:" + value,"debug")

        let newArr = [];
        log("In handDropdownValidationCallbackNull with name="+name+" value="+value)
        for (let i = 0; i < validatedDropdowns.length; i++) {
            if (validatedDropdowns[i] !== name) {
                newArr.push(validatedDropdowns[i])
                writeInputs(name, value)
                alertRefs.current[i].current.innerHTML = ''
                alertRefs.current[i].current.classList = 'text text-error hide'
            }
        }

        setValidatedDropdown(newArr);
    }

    function handleSubmitButtonResponse(slug, answer) {
        // Locally scoped var for execution stack convenience
        let isValidationError = false

        // Parse stored answer JSON
        let fauxInputValues = inputValues
        // log("inputValues == ''")
        // log(inputValues == '')
        if (inputValues === '' || typeof inputValues == 'undefined' || inputValues == null) {
            let obj = {}
            fauxInputValues = JSON.stringify(obj)
        }
        let inputValuesObject = JSON.parse(fauxInputValues)

        // Loop through child question set
        for (let i = 0; i < props.childQuestions.length; i++) {
            // Clear previous question error messages
            if(hiddenQuestions.current.length > 0 && hiddenQuestions.current.includes(props.childQuestions[i].questionName)){
                continue;
            }
             // Find answer for that child question in the answer set - can target with slug - else use ''
            let storedValue = ''
            let validationCheck=[]
            if (typeof inputValuesObject[props.childQuestions[i].questionName] != 'undefined') { // Check if answer is set 
                storedValue = inputValuesObject[props.childQuestions[i].questionName]
                    // Check the validation rules for that child question + Check to see if the validation passes
                    // multi-choice questions have an array for responses so we have to check type and loop
                    if (props.childQuestions[i].questionType!=="Blood Pressure"){
                        if (typeof storedValue == 'object'){
                            validationCheck = validateInput(storedValue[0], props.childQuestions[i].validation, props.childQuestions[i].dataType, null, inputValuesObject)
                        } else {
                            //TODO now we have a special kludge for the postcode because postcode is just a datatype=text and we need to 
                            // do more validation than just checking it's text. So I'm inventing a pseudo data type and passing that
                            // if the questionName == postcode (would be better to make the type in CRM postcode but not sure of regression )
                            if (props.childQuestions[i].questionName === 'postcode'){
                                validationCheck = validateInput(storedValue, props.childQuestions[i].validation, "postcode", null, inputValuesObject)
                            } else {
                                validationCheck = validateInput(storedValue, props.childQuestions[i].validation, props.childQuestions[i].dataType, null, inputValuesObject)
                            }
                        }
                    } else {
                        // we already validate the blood pressure in the component so no need to duplicate it here
                        // and it would need special handling as it's not really a number but a csv of 2 numbers.
                        validationCheck.valid = true;
                    }
            } else {
                // answer not set, but maybe it is required, let's checck for validation
                if (props.childQuestions[i].validation === '' || props.childQuestions[i].validation == null){
                    // Email and Phone don't have question.validationCheck but we check the dataType and deal with them separately
                    if(props.childQuestions[i].dataType === 'Email'){
                        validationCheck = validateInput(storedValue, props.childQuestions[i].validation, props.childQuestions[i].dataType, null, inputValuesObject)
                    } else if (props.childQuestions[i].dataType === 'Phone'){
                        validationCheck = validateInput(storedValue, props.childQuestions[i].validation, props.childQuestions[i].dataType, null, inputValuesObject)
                    } else {    
                        // nothing to validate, it's all good
                        validationCheck = { valid: true,  
                        stop: false,
                        message: '', 
                        report: 'No validation string to evaluate. Returning true to allow the Health Check to continue.' }
                    }
                }
                else { // we got a validation string to test with
                    // let's test the validation
                    if  (typeof inputValuesObject[props.childQuestions[i].questionName] == 'undefined') {
                        // validation string but no value to validate, let's pass ' ' so it's not seen as blank and the 2nd stage of validation will be done.
                        // because if we pass datatype=text and '' it will return a generic validation message without even checking the question validation.
                        if (props.childQuestions[i].questionType=="Blood Pressure"){
                            // it's undefined because there was no answer provided. 
                            // Blood Pressure is an optional question
                            // TODO add required/optional setting to questions so we can better handle this.
                            validationCheck.valid=true;
                        } else {
                            // data type override to apply special validation
                            let _dataType = ''
                            switch (props.childQuestions[i].questionType) {
                                case "Single-Select-Dropdown":
                                case "Multi-Select":
                                case "Single-Select":
                                    _dataType = "list"
                                    break;
                                case "CVD Score":
                                    _dataType='cvdscore'
                                    break;
                                default:
                                    _dataType = props.childQuestions[i].dataType

                            }
                            validationCheck = validateInput(' ', props.childQuestions[i].validation, _dataType, null, inputValuesObject)
                        }
                    }
                }
            }

            if (validationCheck.valid && alertRefs.current[i].current!=null && typeof alertRefs.current[i].current.innerHTML !== 'undefined') {
                // Validation has passed - clear message for this field
                alertRefs.current[i].current.innerHTML = ''
                alertRefs.current[i].current.classList = 'text text-error hide'
            } else if (props.childQuestions[i].questionType !== "Single-Select-Dropdown" && props.childQuestions[i].questionType !== "Multi-Select" && props.childQuestions[i].questionType !== "Single-Select")   {
                // If validation doesn't pass, show an error for that error message
                isValidationError = true
                log(validationCheck)
                alertRefs.current[i].current.innerHTML = validationCheck.message
                alertRefs.current[i].current.classList = 'text text-error'
                log(validationCheck.report,"warn")
            } 
            
            /*
                - For dropdowns there is a special check for "data integrity"
                - This is returned from the child question and stored globally
                - Review this for each dropdown question and see if integrity
                is currently valid
            */
            if (props.childQuestions[i].questionType == "Single-Select-Dropdown" 
                || props.childQuestions[i].questionType == "Multi-Select"
                || props.childQuestions[i].questionType == "Single-Select") {
                if (validationCheck.valid) {
            
                    alertRefs.current[i].current.innerHTML = ""
                    alertRefs.current[i].current.classList = ''
                } else {
                    isValidationError = true
                    alertRefs.current[i].current.innerHTML = validationCheck.message
                    alertRefs.current[i].current.classList = 'text text-error'
                }
            }

        }
        
            // This will require some magic ref targeting
        if (!isValidationError) {
            props.callback(props.slug, inputValues);
        } else {
            setIsAlertCustomError(true)
        }
    }


    function replaceAllTags(str) {
        // add handler for INFO tag.
        let infoItems = []
        if (str.indexOf('<INFO>') >= 0 ){
            // trim the stuff up to the starting point, then we will remove the </INFO>
            infoItems = parseInfoSections(str)
        }
        str = str.includes('<INFO>') ? String().concat('<div class=\'question-text-body\'>',str) : str
        str = str.split('<HEADING>').join('<h4>')
        str = str.split('</HEADING>').join('</h4>')
        str = str.split('<TEXT>').join('<p>')
        str = str.split('</TEXT>').join('</p>')
        str = str.split('<INFO>').join('</div><div class=\'tooltip\'><img class=\'info\' src=\''+iconInfo+'\'><p class=\'tooltiptext\'>')
        str = str.split('</INFO>').join('</p></div>')
        return str
    }
    
    // log("hiddenQuestions")
    // log(hiddenQuestions)
    let questions = []
    if (Array.isArray(props.childQuestions)) {
        questions = props.childQuestions
    }

  return (
        <div className="component-multiquestion">
            {questions.map((question, index) => {
                if (!hiddenQuestions.current.includes(question.questionName) && !checkConditionsShowQuestion(props.answers, question, question.condition, hiddenQuestions, props.queryParams)){ 
                    hiddenQuestions.current.push(question.questionName)
                }
                if (question.questionType === "Short Answer" 
                    && (hiddenQuestions.current.length === 0 
                        || !hiddenQuestions.current.includes(question.questionName)
                       )
                    )
                    return <div className={`partial-input`} key={index}>
                        
                        <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                        <input type={selectInputMode(question.questionName, question.questionType)['type']}
                            className={`form-control transparent-background ${question.questionName}`}
                            placeholder={question.explanationText}
                            name={question.questionName}
                            inputMode={selectInputMode(question.questionName, question.questionType)['mode']}
                            onChange={handleInputChange.bind(this)}
                            defaultValue={props.features.backrecall?(getAnswerForSlug(question.questionName, props.answers.current)):question?.defaultValue}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                

                if (question.questionType === "Yes/No" && (hiddenQuestions.current.length===0 || !hiddenQuestions.current.includes(question.questionName)))
                    return <div className={`partial-input`} key={index}>
                        <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                        <YesNo
                            innerText=""
                            options={[props.buttons.response1, props.buttons.response2]}
                            collapseButtons={true}
                            callback={handleYesNoButtonReponse.bind(this)}
                            slug={question.questionName}
                            answer={getAnswerForSlug(question.questionName, props.answers.current)}
                            answers={props.answers.current}
                            environment={props.environment}
                            features={props.features}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                

                if (question.questionType === "Single-Select-Dropdown" &&  (hiddenQuestions.current.length===0 || !hiddenQuestions.current.includes(question.questionName)))
                    return <div className={`partial-input`} key={index}>
                        <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                        <AutocompleteInput
                            placeholder={question.explanationText}
                            type={selectInputMode(question.questionName, question.questionType)['type']}
                            id="colFormLabel"
                            options={props.options[index]}
                            callback={handleSelectButtonResponse.bind(this)} 
                            slug={question.questionName}
                            fieldOnly={true}
                            defaultVal={question.defaultValue}
                            validationTriggeredCb={handleDropdownValidationCallback}
                            validationNullTriggeredCb={handleDropdownValidationCallbackNull}
                            baseAPIURL={props.baseAPIURL} // pass multiQuestion so we hide the autocomplete paragraph for error labelling as we have it here
                            multiQuestion={typeof question.parentQuestion=="undefined"?false:true}
                            answer={getAnswerForSlug(question.questionName, props.answers.current)}
                            features={props.features}
                            environment={props.environment}
                            token={props.token}
                            tokenRefresh={props.tokenRefresh}
                        />
                        <p title="error" className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                
                if ((question.questionType==="Multi-Select" || question.questionType==="Single-Select" || question.questionType==="Single-Select-Waist")  && (hiddenQuestions.current.length===0 || !hiddenQuestions.current.includes(question.questionName)))
                    return <div className={`partial-input`} key={index}>
                        <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                        <MultiChoice 
                            type={selectInputMode(question.questionName, question.questionType)['type']}
                            options={props.options[index]}
                            fieldOnly={true}
                            name={question.questionName}
                            slug={question.questionName}
                            sexAnswer={props.sexAnswer}
                            baseAPIURL={props.baseAPIURL}
                            question={question}
                            continueOnSelect={false}
                            callback={handleMultiChoiceResponse.bind(this)}
                            answers={props.answers.current}
                            answer={getAnswerForSlug(question.questionName, props.answers.current)}
                            features={props.features}
                            environment={props.environment}
                            token={props.token}
                            tokenRefresh={props.tokenRefresh}
                            //callback: handleQuestionResponse,
                            //stopCallback: handleQuestionStop,
                            validation={question.validation}
                            dataType={question.dataType}
                            customisation={question.options}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                
                if ((question.questionType==="CVD Score")  && (hiddenQuestions.current.length===0 || !hiddenQuestions.current.includes(question.questionName)))
                    return <div className={`partial-input`} key={index}>
                        <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                        <CVDScore 
                            type="number"
                            buttons={question.questionResponses}
                            fieldOnly={true}
                            name={question.questionName}
                            slug={question.questionName}
                            baseAPIURL={props.baseAPIURL}
                            validation={question.validation}
                            question={question}
                            continueOnSelect={false}
                            callback={handleYesNoButtonReponse.bind(this)}
                            dataType={question.dataType}
                            customisation={question.options}
                            answer={getAnswerForSlug(question.questionName, props.answers.current)}
                            answers={props.answers.current}
                            features={props.features}
                            environment={props.environment}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                
                if (question.questionType === "Blood Pressure" && (hiddenQuestions.current.length===0 || !hiddenQuestions.current.includes(question.questionName)))
                return <div className={`partial-input`} key={index}>
                    <p className='questionText'><div className='questionText' dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></div></p>
                    <BloodPressure
                        innerText=""
                        options={[props.buttons.response1, props.buttons.response2]}
                        fieldOnly={true}
                        collapseButtons={true}
                        callback={handleYesNoButtonReponse.bind(this)}
                        slug={question.questionName}
                        buttons={question.questionResponses}
                        validation={question.validation}
                        inputType='text'
                        dataType={question.dataType}
                        inputPlaceholder={question.explanationText != null && question.explanationText !== "" ? 
                        question.explanationText : "Enter " + tidyPlaceholderStr(question.questionName)}
                        answer={getAnswerForSlug(question.questionName, props.answers.current)}
                        answers={props.answers.current}
                        features={props.features}
                        environment={props.environment}
                    />
                    <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                </div>
            
            })}
                    
            <Button
                classList={"primary regular large last-button dynamic-input-button"}
                innerText={props.buttonText}
                onclick={handleSubmitButtonResponse.bind(this)}
                features={props.features}
                environment={props.environment}
            />
        </div>
    
    )
}

export default MultiQuestion;