import Button from './Button.js';
import DynamicYesNo from './DynamicYesNo.js';
import React, { useState, useRef } from "react";
import { validateInput } from '../utils/input-validation.js';
import {log} from "../helpers.js";

function BloodPressure(props) {
    const [inputValueSys, setInputValueSys] = useState('')
    const [inputValueDia, setInputValueDia] = useState('')
    const [alertCustomError, setAlertCustomError] = useState('')
    const refSystolic = useRef();
    const refDiastolic = useRef();

    function handleKeyPressSys(event) {
        setInputValueSys(event.target.value)
        if(props.fieldOnly) {
            let validationCheckSys = validateInput(event.target.value, props.validation, props.dataType, 0, props.answers.current);
            let validationCheckDia = validateInput(refDiastolic.current.value, props.validation, props.dataType, 1, props.answers.current);
            errorCheckAndCallResponse(refSystolic.current.value + ',' + refDiastolic.current.value, validationCheckSys, validationCheckDia)
        }
    }

    function handleKeyPressDia(event) {
        setInputValueDia(event.target.value)
        if(props.fieldOnly) {
            let validationCheckSys = validateInput(refSystolic.current.value, props.validation, props.dataType, 0, props.answers.current);
            let validationCheckDia = validateInput(event.target.value, props.validation, props.dataType, 1, props.answers.current);
            errorCheckAndCallResponse(refSystolic.current.value + ',' + refDiastolic.current.value, validationCheckSys, validationCheckDia)
        }
    }

    function handleButtonReponse(slug, answer, boo = false) {
        if (answer === props.buttons.response1) {
            let callbackValue = inputValueSys + ',' + inputValueDia;
            let validationCheckSys = validateInput(inputValueSys, props.validation, props.dataType, 0, props.answers.current);
            let validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1, props.answers.current);

            errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia);
        } else if (answer === props.buttons.response2) {
            let callbackValue = '';
            let validationCheckSys = {};
            let validationCheckDia = {};
           
            if( inputValueSys !== null && inputValueSys !== '' && inputValueSys !== undefined){
                validationCheckSys = validateInput(inputValueSys, props.validation, props.dataType, 0, props.answers.current);
                if( inputValueDia !== null && inputValueDia !== '' && inputValueDia !== undefined){
                    validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1, props.answers.current);
                    callbackValue = `${inputValueSys},${inputValueDia}`;
                }
                else{
                    validationCheckDia.valid = true;
                    callbackValue = `${inputValueSys},NULL`;
                }
            }else if( inputValueDia !== null && inputValueDia !== '' && inputValueDia !== undefined){
                validationCheckSys.valid = true;
                validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1, props.answers.current);
                callbackValue = `NULL,${inputValueDia}`;
            }else{
                validationCheckSys.valid = true;
                validationCheckDia.valid = true;
                callbackValue = 'NULL';
            }

            errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia);
        }
    }

    function errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia){
        
        if (validationCheckSys.valid && validationCheckDia.valid) {
            props.callback(props.slug, callbackValue)
            setAlertCustomError('')
        } else {
            let errMessages = [];

            if (!validationCheckSys.valid) {
                errMessages.push(validationCheckSys.message);
                log(validationCheckSys.report,"warn")
            } 
            
            if (!validationCheckDia.valid){
                errMessages.push(validationCheckDia.message);
                log(validationCheckDia.report,"warn")
            }

            setAlertCustomError(errMessages.join('. '));
        }   
    }

    return (
        <div>
            <div className={`component-blood-pressure`}>
                <input type="number"
                    className="form-control transparent-background"
                    placeholder="Systolic"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="systolic"
                    ref={refSystolic}
                    onChange={handleKeyPressSys.bind(this)}
                />
                <span className="seperator">/</span>
                <input type="number"
                    className="form-control transparent-background"
                    placeholder="Diastolic"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name="diastolic"
                    ref={refDiastolic}
                    onChange={handleKeyPressDia.bind(this)}
                />
                
            </div>
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }

            {!props.fieldOnly && 
            <DynamicYesNo
                innerText=""
                options={[props.buttons.response1, props.buttons.response2]}
                collapseButtons={true}
                callback={handleButtonReponse}
                slug=""
                features={props.features}
            />
            }
        </div>
    );
}

export default BloodPressure;