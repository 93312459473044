import './App.css'
import HealthCheck from './components/HealthCheck.js'
import React, { useState, useEffect, useRef } from "react"
import {log} from './helpers'
import { clarity } from 'react-microsoft-clarity'
import { HelmetProvider } from 'react-helmet-async';
import CacheBuster from 'react-cache-buster'
import {
  isIOS, isAndroid, isLegacyEdge, isSamsungBrowser, isFirefox, isEdge, isIE ,
  osVersion, osName,
  isIOS13,
  getUA,
  browserVersion,
  browserName,
  deviceDetect,
  fullBrowserVersion,
  isChrome,
} from 'react-device-detect';

function App() {
  // Determine if start button is visible on screen. If not then make container scrollable
  // Will remove scrollable class once start button is clicked.
  const [browseBack, setBrowseBack] = useState(false);
  const [token, setToken] = useState();
  //const [tokenAttempts, setTokenAttempts] = useState(0);
  const bearerToken = useRef('');
  const isProduction = process.env.NODE_ENV === 'production'
  const appNewVersion = require('../package.json').version
  const clarityProject = useRef(process.env.REACT_APP_CLARITY_TOKEN)
  const featureList = JSON.parse(process.env.REACT_APP_FEATURES);

  const UnSupportedBrowserPage = () => {
    return <div>
            <p style={{marginLeft:2 + 'rem', marginRight:2 + 'rem', marginTop:2 + 'rem'}}>Your browser {browserName} version {browserVersion} is not supported by our Health Check. Please upgrade to a more recent browser. We aim to support all modern browsers.</p>
           </div>
    }
  ;

  function isSupportedBrowser() {
    console.log ("isSupportedBrowser checking "+browserName+' '+browserVersion+' running on '+osName+' '+osVersion)
    if (isIOS && browserVersion < 12) { return false }
    if (isChrome && browserVersion < 61) { return false }
    if (isFirefox && browserVersion < 51) { return false }
    if (isIE) { return false }
    if (isLegacyEdge) { return false }
    if (isSamsungBrowser && browserVersion < 6) { return false }
    if (!Object.entries) { return false }
    return true;
  }
  
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!browseBack) {
        if (window.confirm("Press Cancel to continue with the health check or OK to exit.")) {
            setBrowseBack(true)
            // your logic
            window.history.back()
        } else {
            window.history.pushState(null, null, window.location.href);
            setBrowseBack(false)
        }
    }
}

  clarity.init(clarityProject.current)
  clarity.consent()
  clarity.upgrade()

  useEffect(() => {
    window.process = {
      ...window.process,
    }

    const fetchToken = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_TOKENURL);
        if(!response.ok){
          throw new Error ("error fetching auth token ("+ response.status +')'+response.statusText)
        } else {
          const data = await response.json();
          setToken(data);
          bearerToken.current = data;  
        }
      }
      catch(error){
        log(error,"error")
        return
      }
    };

    fetchToken();
    log(bearerToken.current)
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);

  async function getToken (){
    log("fetching a bearer token")
    const fetchToken = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_TOKENURL);
        if(!response.ok){
          throw new Error ("error occurred in getToken()")
        }
        const data = await response.json();
        setToken(data);
        bearerToken.current = data;
        return data;
      }
      catch(error){
        log("error :("+ error.name + ') '+error.message, "error")
      }
    };
    return await fetchToken();
  }

  log ("token="+token)
  log ("bearerToken.current="+bearerToken.current)

  if (isSupportedBrowser()==false) {
    console.log ("browser not supported "+browserName +' '+browserVersion)
    return (<UnSupportedBrowserPage />)
  }
  return (
    <div className="App typography">
      <CacheBuster
        currentVersion={appNewVersion}
        isEnabled = {true} // {isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
      >
        <HelmetProvider>
          {bearerToken.current!=='' &&
           <HealthCheck 
              features={featureList} 
              env={process.env.REACT_APP_ENV} 
              version={appNewVersion} 
              tokenRefresh={getToken} 
              token={token}/>
          }
        </HelmetProvider>    
      </CacheBuster>
    </div>
  );
}

export default App;
