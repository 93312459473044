import ChatAvatar from "./ChatAvatar";
// import avatarWaving from "../../public/assets/SampleVideo.mp4";

const videoMap = {
    "avatar": "https://dev-hc.myhealthforlife.org.au/assets/Avatar_Waving.mp4"
}

function ChatMessage(props) {
    return (
    <div className={`${props.isMicroMessage ? 'micro-message' : ''}`}>
        {props.isMicroMessage && <ChatAvatar 
            className={'small'}
            fillClass={props.avatarFillClass}
        />}
        <div className={`component-chat-message ${props.bgClass} ${props.isMicroMessage ? '' : 'mx-auto'} ${props.isVisible ? '' : 'hidden'}`}>
            <span className="message-bobble">
                <span className={`message-bobble-inner ${props.bgClass}`}></span>
            </span>
            {
            props.text && 
            <div className="text">
                <span dangerouslySetInnerHTML={{__html: props.text}}></span>
            </div>
            }
            {
            props.imageUrl && 
            <img 
                className="mx-auto" 
                src={props.imageUrl} 
                alt={props.alt}
                style={{height: props.imageHeight ? props.imageHeight : 'unset',
                width: props.imageWidth ? props.ImageWidth : 'unset'}} 
            />
            }
            {
            props.animationUrl && 
            <video autoPlay={true} loop muted playsInline style={{maxHeight: '200px', maxWidth: '360px', width: '100%'}}>
                <source src={props.animationUrl} type="video/mp4"></source>
            </video>
            }
            {
            props.videoType && 
            <video autoPlay={true} loop muted playsInline style={{maxHeight: '200px', maxWidth: '360px', width: '100%'}}>
                <source src={videoMap[props.videoType]} type="video/mp4"></source>
            </video>
            }
        </div>        
    </div>

    );
}

export default ChatMessage;