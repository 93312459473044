import React, { useEffect, useState, useRef } from "react";
import Button from './Button.js';
import { getDOMElement, isElementInViewport, getAnswerForSlug, getAnswerMetaForSlug, log, selectInputMode } from "../helpers.js";
import { validateInput, validateDropdown } from '../utils/input-validation.js';
//import getParamsAsStr from './HealthCheck.js'
//import { useDebounce } from 'use-debounce';
// import Cookies from 'js-cookie'; //Import the JS-Cookie library in your React component.
// import axios from 'axios';
// import MultiQuestion from "./MultiQuestion.js";

function AutocompleteInput(props) {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState('');
    const [inputMeta, setInputMeta] = useState('')
    const [alertCustomError, setAlertCustomError] = useState('')
    const [inputFocused, setInputFocused] = useState(false);
    const [hasScrolledToView, setHasScrolledToView] = useState(false);
    //const [baseAPIURL, setBaseAPIURL] = useState('')
    //const [postCodeList, setPostCodeList] = useState('')
    let [validationCheck, setOptions] = useState('')
    //const [text, setText] = useState('Hello');
    //const debouncedValue = useDebounce(text, 1000);
    //const [result, setResult] = useState(null);
    //const [validSelection, setValidSelection] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const bearerToken = useRef(props.token);
    const inputRef = useRef()
    //const [environment, setEnvironment] = useState((props.environment != '' && typeof props.environment != 'undefined') ? props.environment : 'prod')
    
    var suggestions = props.options;
    var options = props.options
    //var postCodeSuburbs = [];
    var originalOptions = props.options;
   
    useEffect(
        () => {
            log("in useEffect [input].value = "+ input)
            if (input.length < 3) { //to check the value is greater then 2
                log ("input.length < 3 : "+input.length,"debug")
                //setResult(null);
                return;
            }
            if (!isNaN(+input) && input !== ''){ // to check if the given input is number/not empty 
                // If it's not not a number (eg is it a number) and not an emtpy string do all this stuff
                // presumably this is a way to effect only numeric input values - eg. postcode but what if someone uses a number
                // for another droplist? -- Andrew
                // Woudn't this be better to check for the questionname of postcode? using  (props.slug === "postcode")
                const timeout = setTimeout(() => {
                    let queryString = `filter[filters][0][field]=Pcode&filter[filters][0][operator]=contains&filter[filters][0][value]=${""}`;
                    const url = props.baseAPIURL + 'common/getGeocodedPostcode2?' + queryString + input
                    log("fetching with url "+ url,"debug")
                    const fetchData = async () => {  // data will be fetched after user input something
                    
                    try {
                        setRequestLoading(true)
                        let localToken = bearerToken.current;
                        // do we really have a token??
                        if(localToken === ''){
                            localToken = await props.tokenRefresh();
                            log("localToken for postcode API call is "+localToken)
                            bearerToken.current = localToken
                        }
                        
                        /* Start - response with BT auth (Passing parameters with Bearer token) */
                        let retries = 2;
                        const response = await fetchPlus(localToken, retries);
                        const json = await response.json();
                        setRequestLoading(false)
                        suggestions = json;
                        options = json;
                        setOptions(json);
                        originalOptions = json;
                        if (props.slug === "postcode") {
                            if(json.length > 0){
                                setFilteredSuggestions(options.map(a => ({locality : a.Locality, pcode : a.Pcode})));
                                suggestions = options.map(a => a.code);
                                //postCodeSuburbs = options.map(a => a.Locality);
                            } else {
                                setFilteredSuggestions("No Match")
                                suggestions = "No Match"
                                if (props.validationNullTriggeredCb != null && typeof props.validationNullTriggeredCb === 'function') {
                                    log('validationNullTriggeredCb() '+ props.slug)
                                    props.validationNullTriggeredCb(props.slug, '');
                                } 
                            }
                        }

                    } catch (error) {
                            log(error.message,"error");
                    }
                        
                        async function fetchPlus(localToken, retries) {
                            const response = await fetch(url, {
                                method: 'GET',
                                headers: new Headers({
                                    "Authorization": "Bearer " + localToken,
                                    "Client-Service": "simbyte-mh4l-api-v1",
                                    "Content-Type": "application/json",
                                    "Accept": "application/json"
                                }),
                                mode: "cors"
                            });
                            /* End - response with BT auth (Passing parameters with Bearer token) */
                            if (!response.ok) {
                                if (response.status === 401) {
                                    log("fetch returned 401, refreshing token");
                                    bearerToken.current = await props.tokenRefresh();
                                    if (retries > 0){
                                        return fetchPlus(bearerToken.current, retries-1)
                                    }
                                    else {
                                        throw new Error ("Failed to refresh api token after multiple attempts.")
                                    }
                                } else {
                                    throw new Error("An error occurred fetching postcode data (" + response.status + ') ' + response.statusText);
                                }
                            }
                            return response;
                        }
                };
                  fetchData();
                }, 1000); // you can edit the time for wait after user input
                return () => clearTimeout(timeout);
            } else {
                // so it's not a number, so not the postcode question (should update to checking the question
                // slug rather than number as it will cause problems if new questions use a number)
                // NOTE: once a postcode/suburb is selected and the input gets the selected text
                // then the inputValue is no longer just the postcode, but SUBURB, POSTCODE
                // so this code gets triggered.
                log ("not a number so it's not the postcode input? ", input)
                //setValidSelection(false)
                if(typeof props.callback!="undefined"){
                    log("callback to " + props.callback.name, "info")
                    props.callback(props.slug, input, validationCheck)
                }
            }
        
        },
        [input]
      );    

    // useEffect(() => {
    //     if (props.fieldOnly) {
    //         returnValueViaCallback()
    //     }
    // }, [])

    function buttonClick() {
        returnValueViaCallback()
    }

    function returnValueViaCallback() { //on submit this function works
        log('returnValueViaCallback() called')
        
        if (input != null && props.slug != null) {
            let formattedOptions = []
            let dropdownValidationCheck = {}; 
            if (props.slug === "postcode") {
                for (let i = 0; i < validationCheck.length; i++) {
                    formattedOptions.push(validationCheck[i].Locality + ', ' + validationCheck[i].Pcode) // {suggestion.suburb}, {suggestion.code}
                }
            } else {
                formattedOptions = props.options
            }
            if (props.slug === "postcode" && props.fieldOnly){
                dropdownValidationCheck.success = true
            }
            else{
                dropdownValidationCheck = validateDropdown(input, formattedOptions)
            }
            if (dropdownValidationCheck.success) {
                let validationCheck = validateInput(input, props.validation, props.dataType, null, props.answers.current)
                if (validationCheck.valid) {
                    let returnValue = input;
                    let returnValueMeta = inputMeta;
                    if (props.slug !== "postcode") {
                        returnValue = suggestions[dropdownValidationCheck.validIndex]
                    }
                    log("returnValueMeta="+returnValueMeta, "info");
                    props.callback(props.slug, returnValue);
                    setAlertCustomError('')
                } else {
                    setAlertCustomError(validationCheck.message)
                    log(validationCheck.report, "warn")
                }
            } else {
                setAlertCustomError("Please select a valid option from dropdown")
                log("Submitted value doesn't match possible dropdown options.","warn")
            }
            
            if (props.validationTriggeredCb != null && typeof props.validationTriggeredCb === 'function') {
                log('validationTriggeredCb() for '+ props.slug, "info")
                props.validationTriggeredCb(props.slug);
            }
        }
    }
    
    const onChange = (e) => {
        log ("autocomplete onchange event","debug")
        // as characters are entered into the input box, this onChange function is called
        // Do we need to call this for every character typed? If it's a postcode, we know we have 4 chars to wait for
        // can we also lookup the locality?
        const userInput = e.target.value;
        let unLinked = [];

        if (props.slug === "postcode" ){
            // if ( userInput.length < 4 ){ 
            //     // let's ignore things until we get 4 characters
            //     return 
            // }
            unLinked = (originalOptions.filter(a => {return a.Pcode.includes(userInput)}))
            log("unLinked:"+unLinked,"debug")
        } else {
            if (userInput && typeof userInput === "string" && suggestions && suggestions.length > 0) {
                unLinked = suggestions.filter(
                    suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
                );
                // do something with unLinked
              } else {
                // handle the case where userInput or suggestions is undefined or empty
                log('else is working in AutocompleteInput onChange event', "info");
              }
        }
        log("e.target.value="+e.target.value,"debug")
        setInput(e.target.value);
        setInputMeta(e.target.meta)
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
        setAlertCustomError('')
    };

    const onClick = (e) => {
        log("Autocomplete onClick function")
        //setValidSelection(true)
        setFilteredSuggestions([]);
        setAlertCustomError('')
        setInput(e.target.innerText); // the droplist item selected , the text, eg 'FEMALE'
        setInputMeta(e.target.meta);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);

        if (props.validationTriggeredCb != null && typeof props.validationTriggeredCb === 'function') {
            log("autocomplete validationTriggeredCb about to be called")
            log('validationTriggeredCb() slug='+ props.slug+ " innerText="+e.target.innerText)
            props.validationTriggeredCb(props.slug, e.target.innerText);
        }
    };

    function clearSuggestion() {
        log ("autocomplete clearSuggestions function","debug")
        setAlertCustomError('')
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);       

        if (props.validationNullTriggeredCb != null && typeof props.validationNullTriggeredCb === 'function') {
            log('validationNullTriggeredCb() '+ props.slug)
            props.validationNullTriggeredCb(props.slug, '');
        } 
    }

    const onKeyDown = (e) => {
        log ("autocomplete onKeyDown event "+e.key,"debug")
        //const desktopSidebar = getDOMElement("partial-desktop-sidebar", "className")[0];
        const desktopSidebar = getDOMElement("partial-chat-view", "className")[0];
        const isMobile = isElementInViewport(desktopSidebar);

        if (isMobile && !hasScrolledToView) {
            const chatElement = getDOMElement("chat-box", "id");
            
            chatElement.scroll({
                top: chatElement.scrollHeight + 1000, 
                left: 0, 
                behavior: 'smooth'
            });        
            
            setHasScrolledToView(true);
        }
        
        switch (e.key) {
            case 'Enter': //Enter
                if (props.slug === "postcode") {
                    setInput(filteredSuggestions[activeSuggestionIndex].locality + ', ' + filteredSuggestions[activeSuggestionIndex].pcode);
                } else {
                    setInput(filteredSuggestions[activeSuggestionIndex]);
                }
                clearSuggestion();
                setInputFocused(false);
                e.target.blur();
                //setValidSelection(true)
                setFilteredSuggestions([]);
                // log(e.target.innerText)
                setAlertCustomError('')
                setInputMeta(e.target.meta);
                setActiveSuggestionIndex(0);
                setShowSuggestions(false);
        
                if (props.validationTriggeredCb != null && typeof props.validationTriggeredCb === 'function') {
                    log('validationTriggeredCb() slug='+ props.slug+ " innerText="+e.target.innerText )
                    props.validationTriggeredCb(props.slug, e.target.innerText);
                }
                break;
            case 'ArrowUp': //Arrow Up
                if (activeSuggestionIndex > 0) {
                    setActiveSuggestionIndex(activeSuggestionIndex - 1);
                }

                break;
            case 'ArrowDown': //Arrow down
                if (activeSuggestionIndex < filteredSuggestions.length - 1) {
                    setActiveSuggestionIndex(activeSuggestionIndex + 1);
                }
                break;
            default:
                break;
        }
    }

    const onBlur = (e) => {
        log("autocomplete onBlur event","debug")
        setInputFocused(false);
    }

    const onFocus = (e) => {
        log ("autocomplete onFocus event","debug")
        if (props.slug === "postcode") {   
            if (e.target.value.length > 3){  
                setActiveSuggestionIndex(0);
                setInputFocused(true);
                setShowSuggestions(true);
                //validateDropdown(e.target.value)
                //props.validationTriggeredCb(props.slug, e.target.value);  
            }   
        } else {     
            setFilteredSuggestions(props.options);
            setInput("");
            setInputMeta("");
            setActiveSuggestionIndex(0);
            setInputFocused(true);
            setShowSuggestions(true);
        }
    }

    useEffect(() => {
        log ("autocomplete useEffect checking activeSuggestIndex length and scrolling","debug")
        if (activeSuggestionIndex > 3) {
            let activeElTop = getDOMElement('suggestion-active', 'className')[0].offsetTop;
            getDOMElement('suggestions', 'className')[0].scrollTop = activeElTop - 100;
        }
    });

    const SuggestionsListComponent = () => {
        log ("inside SuggestionsListComponent function","debug")
        return typeof filteredSuggestions === 'object' && filteredSuggestions.length>0 ? (
            <ul className="suggestions form-control to-input">
                {props.slug === "postcode" && filteredSuggestions.map((suggestion, index) => {
                    log("suggestion populated="+ JSON.stringify(suggestion));
                    let className = "";
                    if (index === activeSuggestionIndex) {
                        className = "suggestion-active";
                    }

                    return (
                        <li className={className} key={`${index}`} meta={`${suggestion.locality}, ${suggestion.pcode}`} onMouseDown={onClick} onKeyDown={onKeyDown}>
                            {suggestion.locality}, {suggestion.pcode}
                        </li>
                    );
                })}

                {props.slug !== "postcode" && filteredSuggestions.map((suggestion, index) => {
                    let className = "";

                    if (index === activeSuggestionIndex) {
                        className = "suggestion-active";
                    }

                    return (
                        <li className={className} key={`${index}`} meta={`${suggestion}`} onMouseDown={onClick} onKeyDown={onKeyDown}>
                            {suggestion}
                        </li>
                    );
                })}
            </ul>
        ) : (
            !isNaN(inputRef.current.value) && typeof filteredSuggestions === 'string' && <div className="no-suggestions">{filteredSuggestions}
            </div>
        );
    };
    let inputmode = selectInputMode(props.slug, props.type)
    return (
        <div className={`postcode component-autocomplete-input component-form-UI ${inputFocused ? 'active' : ''} ${typeof props.fieldOnly != 'undefined' ? 'nested' : ''}`}>
            <input
                className="form-control to-input transparent-background"
                type={props.type}
                placeholder={props.placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
                pattern={`${inputmode!=null?inputmode.pattern:null}`}
                inputMode={`${inputmode!=null?inputmode.mode:'text'}`}
                value={input}
                ref={inputRef}
                autocomplete="chrome-off"
            />
            {requestLoading &&
                 <p className="text text-loading"><span className="loading"></span></p>
            }
            {alertCustomError.length > 0 && props.multiQuestion && 
                <p title="autocomplete" className="text text-error hide">{alertCustomError}</p>
            }
            {/* {inputFocused && showSuggestions && <SuggestionsListComponent />} */}
            {showSuggestions && <SuggestionsListComponent />}
            {(typeof props.fieldOnly == 'undefined') &&
            //display a button if the fieldOnly prop is not set 
            //TODO this should really be checking if it's not true.
                <Button
                    classList={"primary regular large last-button dynamic-input-button"}
                    innerText={props.buttonText}
                    onclick={buttonClick}
                    features={props.features}
                />
            }
        </div>
    );
}

export default AutocompleteInput;
