import React, { useState, useEffect, useRef, createRef } from "react";
import { validateInput } from '../utils/input-validation.js';
import { gtmHandler } from '../utils/gtmhandler.js';
import { log } from "../helpers.js";

function ProgramSignUpForm(props) {
    //const [moodle2Link, setMoodle2Link] = useState('')
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
       // agreeToTerms: false,
       // receiveNewsletter: false
    });

    const formDataTypeMap = {
        firstName: {type: 'text', required: true},
        lastName: {type: 'text', required: true},
        email: {type: 'email', required: true},
        contactNumber: {type: 'phone', required: true},
       // receiveNewsletter: {type: 'boolean', required: false}
    };

    //const [requiredInputs, setRequiredInputs] = useState([]);
    const [formDisabled, setFormDisabled] = useState(false);
    const [formSubmissionMessage, setFormSubmissionMessage] = useState('');
    //const [formSubmissionURL, setFormSubmissionURL] = useState('');
    const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
    const signupFormElement = useRef();
    const successMessageElement = useRef();

    //let isValid = true;
    //let validFields = [];
    
    const alertRefs = React.useRef([]);
    alertRefs.current = Object.keys(formData).map((k, index) => alertRefs[index] ?? createRef());

    useEffect(() => {
        if (!isInitiallyLoaded && Object.keys(props.loggedInUserData).length > 0) {
            setFormData({...formData, 
                'firstName': (props.loggedInUserData['firstname'] ?? ''), 
                'lastName': props.loggedInUserData['lastname'] ?? '', 
                'email': props.loggedInUserData['email'] ?? '',
                'contactNumber': props.loggedInUserData['phone'] ?? ''
            });

        } else if (!isInitiallyLoaded && typeof props.userFirstName == 'string') {
            setFormData({...formData, 
                'contactNumber': (props.userPhone ?? ''), 
                'firstName': (props.userFirstName ?? ''), 
                'email': (props.userEmail ?? ''),
            });
        }      

        setIsInitiallyLoaded(true);

    }, []);

    function handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({...formData, [name]: value});

        // if (requiredInputs.includes(name)) {
        //     validateForm();
        // }

        let refIndex = Object.keys(formData).indexOf(name);
        alertRefs.current[refIndex].innerHTML = '';
        alertRefs.current[refIndex].classList = 'text text-error hide';
    }

    function validateForm() {
        let valid = true;

        Object.keys(formData).forEach(key => {
            let validationStr = formDataTypeMap[key].type === 'boolean' 
                && formDataTypeMap[key].required &&
                !formData[key] ? 'true' : '';

            switch (key){
                case "lastName":
                    validationStr = "{0} == NULL|Block|\"You must enter a last name\""
                    break;
                case "firstName":
                    validationStr = "[\"{0} == NULL\"|Block|\"You must enter a first name\",\"{0}.length > 30|Block|\"Please correct the following errors: Your entered name must not exceed 30 characters\"]"
                    break;
                case "email":
                    validationStr = "{0} == NULL|Block|\"You must enter a valid email address\""
                    break;
                case "contactNumber":
                    validationStr = "[\"{0} == NULL\"|Block|\"You must enter a valid phone number\",\"{0}.length > 12|Block|\"Please correct the following errors: Your entered phone number must not exceed 30 characters\",\"{0}.length < 8 && {EMAIL}==NULL|Block|\"Please correct the following errors: Your entered phone number must not be less than 8 characters\"]"
                    break;
                default: 
            }
            let validationCheck = validateInput(String(formData[key]), validationStr, formDataTypeMap[key].type,null, formData);
            let refIndex = Object.keys(formData).indexOf(key);

            if (validationCheck.valid) {
                // Validation has passed - clear message for this field
                alertRefs.current[refIndex].innerHTML = '';
                alertRefs.current[refIndex].classList = 'text text-error hide';
            } else {
                // If validation doesn't pass, show an error for that error message
                valid = false;

                if (key === 'agreeToTerms') {
                    validationCheck.message = "You must agree to the terms and conditions";
                }

                alertRefs.current[refIndex].innerHTML = validationCheck.message
                alertRefs.current[refIndex].classList = 'text text-error';
            }
		});

        //isValid = valid;
        return valid;
    }

    function isFormValid() {
        let valid = validateForm();

        return valid;
    }

    function toggleFormState(isDisabled) {
        setFormDisabled(isDisabled);
    }

    function handleSubmit(e) {
        e.preventDefault();
        // Attach GTM Tag Manager and setup Data Layer
        gtmHandler.bindSubmit(e);

        let successMessage = '';
        //let moodle2url = "" ;
        let riskLevel = props.riskLevelProp.toLowerCase();        
        
        const cb = (success, message, moodle2url) => {
            log("in ProgramSignupForm cb function and riskLevel = " + riskLevel)
            if(riskLevel === "high"){
                successMessage = 'Thank you! A copy of your results has been sent to your email. A<span style="font-style: italic;"> My health for life</span> team member will contact you soon to confirm your enrolment and find a coaching option that best suits you.'
                toggleFormState(false);
                log("setting FormSubmissionMessage to "+successMessage)
                setFormSubmissionMessage(successMessage)
                //setMoodle2Link(moodle2url)
            }

            if(riskLevel === "mod" || riskLevel === "low"){
                if(typeof moodle2url!=="undefined"){
                    successMessage = "Thank you! Your account can now be set up <a className='submission-message-a' href="+moodle2url+"> here.</a>  A copy of your results and the link to your account has also been sent to your email."
                    toggleFormState(false);
                    log("setting FormSubmissionMessage to "+successMessage)
                    setFormSubmissionMessage(successMessage)
                    //setMoodle2Link(moodle2url)
                }
            }
            if (document.getElementById('healthcheckembed')){
                document.getElementById('healthcheckembed').blur();
                document.getElementById('healthcheckembed').scrollIntoView({
                  block:"start",
                  behaviour: "smooth"
                })
            }

        }

        if (isFormValid()) {
            toggleFormState(true);
            let retries=3;
            log("isFormValid=true, calling props.handleSubmit from ProgramSignupForm.js with signup:true")
            props.handleSubmit({...formData,signup:true}, cb,retries);
        }
    }

    function onSubmitClick(e) {
        // Attach GTM Tag Manager and setup Data Layer
        gtmHandler.bindClick(e);
    }

    return (
        <div id="component-program-sign-up" className="component-program-sign-up component-form-UI">
            {formSubmissionMessage === '' && 
            <form id="programSignUpForm" onSubmit={handleSubmit} ref={signupFormElement}>
                <label><input value={formData['firstName']} className={`simple-input`} type="text" name="firstName" placeholder="First Name" onChange={handleInputChange} disabled={formDisabled} /></label>
                <p className="text text-error text-error-results hide" ref={el => alertRefs.current[Object.keys(formData).indexOf('firstName')] = el}></p>
                
                <label><input value={formData['lastName']} className={`simple-input`}  type="text" name="lastName" placeholder="Last Name" onChange={handleInputChange} disabled={formDisabled}/></label>
                <p className="text text-error text-error-results hide" ref={el => alertRefs.current[Object.keys(formData).indexOf('lastName')] = el}></p>
                
                <label><input value={formData['email']} className={`simple-input`}  type="email" name="email"  placeholder="Email" onChange={handleInputChange} disabled={formDisabled}/></label>
                <p className="text text-error text-error-results hide" ref={el => alertRefs.current[Object.keys(formData).indexOf('email')] = el}></p>
                
                <label><input value={formData['contactNumber']} className={`simple-input`} inputMode="numeric"  type="text" name="contactNumber" placeholder="Contact Number" onChange={handleInputChange} disabled={formDisabled}/></label>
                <p className="text text-error text-error-results hide" ref={el => alertRefs.current[Object.keys(formData).indexOf('contactNumber')] = el}></p>
                <p className="formText2">{props.formText2}</p>
                <div className={`loader-wrapper ${formDisabled ? '' : 'disabled'}`}><span className="loading"></span></div>
                <div className="w-100">
                    <input id="joinButton" type="submit" value="JOIN NOW" className={`component-button share-button mx-auto primary regular large ${formDisabled ? 'disabled' : ''}`} disabled={formDisabled} onSubmit={onSubmitClick} />
                </div>
                <p className="formText3">{props.formText3}</p>
            </form>
            }         
            <p ref={successMessageElement} id="successMessageElement" className={`form-submission-message ${formSubmissionMessage === '' ? '' : 'active'}`} dangerouslySetInnerHTML={{ __html: formSubmissionMessage }}></p>
        </div>
    )
}

export default ProgramSignUpForm;