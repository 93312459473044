import Button from './Button.js';
import { useState, useEffect } from "react";
import {log} from "../helpers.js";

function YesNo(props) {
    const [activeButton, setActiveButton] = useState("");

    useEffect(() => {
      if(props.features.backrecall && typeof props.answer != 'undefined' 
          && ( props.answer==='YES'|| props.answer==='NO' ) 
        ){
        log(props.answer);
        setActiveButton(props.answer);
        props.callback(props.slug, props.answer, true);
      }
    },[])

    function buttonClick(choice) {
        props.callback(props.slug, choice, true);
        setActiveButton(choice);
    }

    return (
      <div className={`component-yes-no ${props.classList}`}>
        <h1>{props.innerText}</h1>
          {/* <div className='button-wrapper d-flex justify-content-between'>  rem out and replace to remove justify-content - Andrew */}
        
        {props.customisation === "Responses as Buttons" &&  
          <div className='button-wrapper d-flex'>
            <Button
                classList={`regular primary yesno ${props.features.backrecall && props.answer === "YES" ? 'selected' : ''}`}
                innerText={"YES"}
                onclick={buttonClick.bind(this, 'YES')}
            />
            <Button
                classList={`regular primary yesno ${props.features.backrecall && props.answer === "NO" ? 'selected' : ''}`}
                innerText={"NO"}
                onclick={buttonClick.bind(this, 'NO')}
            />
          </div>
        }
        {props.customisation !== "Responses as Buttons" &&
          <div className='component-multi-choice container'>
            <div className="multi-choice-row component-form-UI">
              <input type='radio' 
                name={props.slug}
                key={props.slug+'YES'}
                id={props.slug+'YES'}
                classList={`radio_input yesno ${props.answer === "YES" ? "selected" : ""}`}
                onChange={buttonClick.bind(this, 'YES')}
                defaultChecked={props.features.backrecall && (props.answer==='YES')}
              />
              <label classList="w-100" for={props.slug+'YES'}>YES</label>
            </div>
            <div className="multi-choice-row component-form-UI">
              <input type='radio' 
                name={props.slug} 
                key={props.slug+'NO'}
                id={props.slug+'NO'}
                classList={`radio_input yesno ${props.answer === "NO" ? "selected" : ""}`}
                onChange={buttonClick.bind(this, 'NO')}
                defaultChecked={props.features.backrecall && (props.answer==='NO')}
              />
              <label classList="w-100" for={props.slug+'NO'}>NO</label>
            </div>
          </div>
        }
      </div>
    );
  }
  
  export default YesNo;