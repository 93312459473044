import React, { useEffect, useRef } from 'react';
import {log} from "../helpers.js";

function RiskChart(props) {
    //const [slidersStyle, setSlidersStyle] = useState({})
    const strokeLow = useRef([])
    const strokeMedLow = useRef([])
    const strokeMedium = useRef([])
    const strokeMedHigh = useRef([])
    const strokeHigh = useRef([])
    const arrow = useRef([])

    log("RiskChart props:")
    log(props)

    // Finds key frames rule from stylesheet. Can be used to dynamically change arrow animation timing
    // function findKeyframesRule(rule) {
    //     try {
    //         let ss = document.styleSheets;

    //         log("document.styleSheets")
    //         log(ss)

    //         for (let i = 0; i < ss.length; i++) {
    //             for (let j = 0; j < ss[i].cssRules.length; j++) {
    //                 if (ss[i].cssRules[j].type === 7 && ss[i].cssRules[j].name === rule) { 
    //                     return ss[i].cssRules[j]; 
    //                 }
    //             }
    //         }
    //     } catch (e) {
    //         log("Error(s1)")
    //         log(e)
    //     }

    //     return null;
    // }

    function setRotation() {
        try {
            // Get highest value - this is used for effectively scalling all positions
            let bandHighestValue = props.bands[(props.bands.length - 1)][Object.keys(props.bands[(props.bands.length - 1)])[0]][1]
            
            bandHighestValue = (typeof bandHighestValue != 'undefined' && typeof bandHighestValue != 'string') ? bandHighestValue : 40

            // Set meter pos with degrees
            let deg = (270 / 100) * ((parseInt(props.riskIndication) / bandHighestValue) * 100);

            // rebase offset vars
            // let root = document.documentElement;

            // root.style.setProperty('--low-offset', 1000);
            // root.style.setProperty('--medLow-offset', 1000);
            // root.style.setProperty('--medium-offset', 1000);
            // root.style.setProperty('--medHigh-offset', 1000);
            // root.style.setProperty('--high-offset', 1000);

            // Magic numbers set by SVG positioning
            //let lowEnd = 1000;
            //let highEnd = 840;
            let lowHighDifference = 160;

            // Loop through provided array of bands and set the SVGs elements
            for (let i = 0; i < props.bands.length; i ++) { // Loop through top level
                for (let j = 0; j < Object.keys(props.bands[i]).length; j++) { // Loop through keys for each element
                    let bandKey = Object.keys(props.bands[i])[j] // get key
                    let bandArr = props.bands[i][bandKey] // get array

                    // Calc percentage of highest value offset
                    let decimalPercentageOfThisBand = (bandArr[1] / bandHighestValue)
                    log("decimalPercentageOfThisBand")
                    log(decimalPercentageOfThisBand)

                    // Calculate band arc in SVG numbers
                    let bandArcInSVGNumbers = decimalPercentageOfThisBand * lowHighDifference

                    // Set style in root var
                    log("bandKey")
                    log(bandKey)
                    switch(bandKey) {
                        case 'low':
                            strokeLow.current.style.strokeDashoffset = (1000 - bandArcInSVGNumbers)
                            break;
                        case 'medLow':
                            strokeMedLow.current.style.strokeDashoffset = (1000 - bandArcInSVGNumbers)
                            break;
                        case 'medium':
                            strokeMedium.current.style.strokeDashoffset = (1000 - bandArcInSVGNumbers)
                            break;
                        case 'medHigh':
                            strokeMedHigh.current.style.strokeDashoffset = (1000 - bandArcInSVGNumbers)
                            break;
                        case 'high':
                            strokeHigh.current.style.strokeDashoffset = (1000 - bandArcInSVGNumbers)
                            break;
                        default:
                    }
                    // root.style.setProperty('--' + bandKey + '-offset', (1000 - bandArcInSVGNumbers))
                }
            }

            // root.style.setProperty('--arrow-end-deg', deg + "deg");
            arrow.current.style.transform = 'rotate(' + deg + 'deg)'
        } catch (e) {
            log("Error(s2)")
            log(e)
        }
    }

    useEffect(() => {
        try {
            if (props.riskIndication !== 0) {
                setRotation();
            }
        } catch (e) {
            log("Error(s3)");
            log(e);
        }
    })

    return (
        ((typeof props.bands != 'undefined' && props.bands !== '' && props.bands.length > 0) || (props.text!=="")) && 
        <div className={`component-risk-chart ${props.bgClass}`}>
            {typeof props.bands != 'undefined' && props.bands !== '' && props.bands.length > 0 &&
                <div className="indicator-wrapper d-flex justify-content-center align-items-center">
                    <div className="text-block header">Low Risk</div>
                    <div className="outer-risk-indicator">
                        <div className="loader">
                            <div className="sliders" /*style={slidersStyle} */ >
                                <svg className="low" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle ref={strokeLow} className="load stroke-low" cx="60" cy="60" r="40" />
                                </svg>
                                <svg className="medLow" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle ref={strokeMedLow} className="load stroke-medLow" cx="60" cy="60" r="40" />
                                </svg>
                                <svg className="medium" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle ref={strokeMedium} className="load stroke-medium" cx="60" cy="60" r="40" />
                                </svg>
                                <svg className="medHigh" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle ref={strokeMedHigh} className="load stroke-medHigh" cx="60" cy="60" r="40" />
                                </svg>
                                <svg className="high" viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle ref={strokeHigh} className="load stroke-high" cx="60" cy="60" r="40" />
                                </svg>                             
                            </div>
                            <div className="indicator-ellipse">
                                <div ref={arrow} className="arrow-wrapper circle-beige">
                                    <svg width="20%" height="20%" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M18.1549 7.44975C12.1698 1.61236 8.78733 1.91574 6.17468 4.5945C3.56202 7.27326 2.70853 11.313 8.69361 17.1504C14.6787 22.9878 45.0866 48.408 47.6993 45.7293C50.3119 43.0505 24.14 13.2871 18.1549 7.44975Z" fill="black"/>
                                        <circle cx="11.0576" cy="9.90117" r="2.64221" transform="rotate(90.2603 11.0576 9.90117)"/>
                                    </svg>                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-block header">High Risk</div>
                </div>
            }
            { props.text!=="" &&
                <div className="text-block general">
                    <span dangerouslySetInnerHTML={{__html: props.text}}></span>
                </div>
            }
        </div>
    );
}
  
export default RiskChart;