import 'react-app-polyfill/stable.js';
import 'es7-object-polyfill';
import Header from './Header.js';
import Button from './Button.js';
import Heading from './Heading.js';
import Text from './Text.js';
import ChatMessage from './ChatMessage.js';
import ChatAvatar from './ChatAvatar.js';
// import Slider from './Slider.js';
import YesNo from './YesNo.js';
// import RiskChart from './RiskChart.js';
// import Accordian from './Accordian.js';
import MultiChoice from './MultiChoice';
import MultiQuestion from './MultiQuestion';
// import DesktopScreen from './DesktopScreen';
// import RiskFactor from './RiskFactor.js';
// import FormUI from './FormUI';
// import ResultHeader from './ResultHeader';
import DynamicYesNo from './DynamicYesNo.js';
import DynamicYesNoSkip from './DynamicYesNoSkip.js';
import DynamicInput from './DynamicInput.js';
// import ProgressBar from './ProgressBar.js';
import BloodPressure from './BloodPressure.js';
import CVDScore from './CVDScore.js';
import Image from './Image.js';
import Animation from './Animation.js';
import Info from './Info.js';
// import { postcodeArr } from '../utils/ppostcodeArr.js';
import { checkConditionsShowQuestion, tidyPlaceholderStr, log} from '../helpers.js';

// import svgs from './svgs.js';

import React, { useState, useEffect, useRef } from "react";
import ReactDOM, { createRoot } from 'react-dom/client';
import ResultsScreen from './ResultsScreen.js';
// import Footer from './Footer.js';
import AutocompleteInput from './AutocompleteInput.js';

// XML Parser
import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import { getDOMElement } from '../helpers.js';

// Clarity
import { clarity } from 'react-microsoft-clarity';
import { Helmet } from 'react-helmet-async';
//import ProgramSignUpForm from './ProgramSignUpForm.js';

function HealthCheck(props) {
	// const [conversations, setConversations] = useState([])
	const [questionSet, setQuestionSet] = useState({})
	// const [questions, setQuestions] = useState([])
  //const [referralHeader, setReferralHeader] = useState({})
  // const [displayReferralBanner, setDisplayReferralBanner] = useState(false)
  // const [displayLogoBannerAndFooter, setdisplayLogoBannerAndFooter] = useState(false)
	const [questionSetLoaded, setQuestionSetLoaded] = useState(false)
	// const [userID, setUserID] = useState('')
  const [baseAPIURL, setBaseAPIURL] = useState('')
  const [sessionCode, setSessionCode] = useState('')
  const [environment, setEnvironment] = useState(typeof props.env!='undefined'?props.env:'')
	const queryParams = new URLSearchParams(window.location.search)
  const hiddenQuestions = React.useRef([])
	const [dataMapping, setDataMapping] = useState('')
	const [questionSetID, setQuestionSetID] = useState('')
	const [questionSetName, setQuestionSetName] = useState('')
	const [questionSetType, setQuestionSetType] = useState('')
	const [questionIndexFlow, setQuestionIndexFlow] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)
	// const [desktopWelcomeContent, setDesktopWelcomeContent] = useState(WelcomeContent())
	const [width, setWidth] = useState(window.innerWidth)
	// const [desktopSidecartContent, setDesktopSidecartContent] = useState(getDesktopSidecartContent())
	const [desktopViewClassList, setDesktopViewClassList] = useState('partial-desktop-sidebar intro')
	// const desktopViewClassListRef = useRef(desktopViewClassList)
	const [progressPercentage, setProgressPercentage] = useState(0)
	const [stateButtonText, setStartButtonText] = useState('Getting ready . . .')

	const [resultsVisibility, setResultsVisibility] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const [headerState, setHeaderState] = useState("initial")
	
	// const [cachedReactDOM, setReactDOM] = useState(null)
	//const [saveUserDetailsID, setSaveUserDetailsID] = useState('')

  const [loggedInUserData, setLoggedInUserData] = useState({})
	const [userFirstName, setUserFirstName] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [userPhone, setUserPhone] = useState('')
	// const [postCodeList, setPostCodeList] = useState('Hello')

	const [overalResultSet, setOveralResultSet] = useState({})
	const [overalResult, setOveralResult] = useState({})
  const [apiLoading, setApiLoading] = useState(false);
  const isLoadingDiv = useRef();
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const bearerToken = useRef('');
  const moodle2Link = useRef('');
  bearerToken.current = props.token;
  log("Features="+JSON.stringify(props.features), "info", "info")
	let loadingQuestionSet = false
	// let sectionIndex = 0
	let questionIndexLocal = questionIndex
  log("set questionIndexLocal to "+ questionIndexLocal);
 	let apiSaveResponsesID = sessionCode ? sessionCode : ''
	//const transitionInitTimeMilliseconds = 1200
	
	// const desktopView = useRef(null)
	const chatView = useRef(null)
	const chatBox = useRef(null)
	const resultsBox = useRef(null)
	// const desktopCopy = useRef(null)
	// const introGraphic = useRef(null)
	// const introButton = useRef(null)
  const [joinFormSuccess, setJoinFormSuccess] = useState(false);

	// const uprightHandSVG = svgs.uprighthand

	const cachedElementsRef = useRef([])
	// const reactDOMRef = useRef(null)
	const questionResponses = useRef([])
  const errorElement = useRef();
    
  //log("Clarity Token = ", process.env.REACT_APP_CLARITY_TOKEN);



	useEffect(() => {
		// Init vars
		setIsMobile(width <= 1040);

		// Init events
		window.addEventListener('resize', handleWindowSizeChange);
    adaptBaseAPIURL()
		clarity.init("eo7bvv0xa");

    const timeout = setTimeout(() => {
    
      let paramsStr = getParamsAsStr(true)
      const url = process.env.REACT_APP_BASEAPIURL  + 'question/getQuestions/3EC2D6BD-5574-42BF-A3CC-4D299C9E38C9/v3' + paramsStr
      let retries = 2

      async function fetchQuestionData(retries, localToken) {
        try {
          setStartButtonText("Loading . . .");

          // do we really have a token??
          if (localToken=== '') {
            localToken = props.tokenRefresh();
            bearerToken.current = localToken;
          }

          /* Start - response with BT auth (Passing parameters with Bearer token) */
          const response = await fetch(url, {
            headers: new Headers({
              "Authorization": "Bearer " + localToken,
              "Client-Service": "simbyte-mh4l-api-v1",
              "Content-Type": "application/json",
              "Accept": "application/json"
            })
          });
          /* End - response with BT auth (Passing parameters with Bearer token) */
          if (!response.ok) {
            if (response.status === 401) {
              if (retries > 0){
                log("fetch returned 401, refreshing token, retries="+retries);
                localToken = ''
                return fetchQuestionData(retries-1,localToken)
              } else {
                log("token refresh has failed in fetchQuestionData.")
                throw new Error("Error occurred refreshing token, multiple retries have failed. Please check your network connection")
              }
            } else {
              throw new Error("An error occurred fetching data (" + response.status + ') ' + response.statusText);
            }
          }
          const data = await response.json();
          log("QUESTION SET:");
          log(data.questions);
          // Store data mapping
          setDataMapping(data.DataMapping); // not in PoC output
          setQuestionSet(data.questions);
          setQuestionSetID(data.questionsetId);
          setQuestionSetName(data.questionsetName);
          setQuestionSetType(data.questionsetType);
          setQuestionSetLoaded(true);
          setApiLoading(false);
          setStartButtonText("Start Health Check");
          // if (typeof data.header != 'undefined' && typeof data.header == 'object') {
          //   if (data.header != null) {
          //       setReferralHeader(data.header)
          //   }
          // }
        } catch (error) {
          log(error.message, "error");
        }
      }
    fetchQuestionData(retries,bearerToken.current);
  }, 0); // you can edit the time for wait after user input
  return () => clearTimeout(timeout);


  }, []);

  // Detecting change on baseAPIURL var
  useEffect(() => {
    // Core fetching functions following base URL determination
    //fetchPostcodeListFromAPI()

    // Fetch question set
    if (!loadingQuestionSet) {
      loadingQuestionSet = true

      if (baseAPIURL !== '' && typeof (questionSet) === 'object' && Object.keys(questionSet).length === 0) {
        // fetchQuestionSet()
        //  .then(() => {
          
        //   }
        //   );
      
      }
    }
  }, [baseAPIURL])

  // Detecting change on referralHeader var
  // useEffect(() => {
  //   renderReferralBanner()
  //   renderLogoBanner()
  // }, [referralHeader, renderLogoBanner])

useEffect(() => {
  log("questionIndex="+questionIndex);
  log("questionIndexFlow="+questionIndexFlow);
}, [questionIndex])

// refresh on error state change
  useEffect(() => {
    if (errorMsg.length>0){
      errorElement.innerText=errorMsg;
    }
  }, [errorState])

  async function adaptBaseAPIURL() {
    // Detect if this is a local, dev, staging, uat, or prod environment
    // let hostname = window.location.hostname
    // let href = window.location.href
    setBaseAPIURL(process.env.REACT_APP_BASEAPIURL)
    if (typeof environment != 'undefined'){ 
      log("env="+environment+", "+process.env.REACT_APP_BASEAPIURL)
    }else{
      setEnvironment(process.env.REACT_APP_ENV);
    }
  }

  // Call API

  // async function fetchQuestionSet() {
  //   setStartButtonText("Loading . . .")
  //   let paramsStr = getParamsAsStr(true)
  //   //TODO POC questionset GUID - this would be better as a env var.
  //   let fetchURL = baseAPIURL + 'question/getQuestions/3EC2D6BD-5574-42BF-A3CC-4D299C9E38C9/v3' + paramsStr

  //   setApiLoading(true)
  //   try {
  //     console.log("props.token = ",bearerToken.current)
  //     const response = await fetch(fetchURL, {  
  //       method: 'GET',
  //       headers: new Headers({
  //           "Authorization": "Bearer "+ bearerToken.current,
  //           "Client-Service": "simbyte-mh4l-api-v1",
  //           "Content-Type": "application/json",
  //           "Accept": "application/json"
  //       }),
  //       mode: "cors" 
  //   });
  //     if(!response.ok){
  //       setErrorMsg("Network error loading questions : " + response.status + " " + response.statusText)
  //       throw new Error ("Network error loading questions : " + response.status + " " + response.statusText)
  //     }else {
  //       const data = await response.json();
  //       log("QUESTION SET:");
  //       log(data.questions);

  //       // Store data mapping
  //       setDataMapping(data.DataMapping)  // not in PoC output
  //       setQuestionSet(data.questions)
  //       setQuestionSetID(data.questionsetId)
  //       setQuestionSetName(data.questionsetName)
  //       setQuestionSetType(data.questionsetType)
  //       setQuestionSetLoaded(true)
  //       setApiLoading(false)
  //       setStartButtonText("Start Health Check")
  //       if (typeof data.header != 'undefined' && typeof data.header == 'object') {
  //         if (data.header != null) {
  //             setReferralHeader(data.header)
  //         }
  //       }
  //     }

  //   } catch (Error) {
  //     log("Error: "+Error.message,"warn");
  //     setErrorMsg("Error: "+Error.message);
  //     setErrorState(true)
  //     setApiLoading(false);
  //     setStartButtonText("Not Loaded")
  //   }
  // }

  function getParamsAsStr(firstParams = true, question = null, index = null) {
    // Collect params
    let params = ''
    queryParams.forEach(function(value, key) {
      if (params === '') {
        if (firstParams) {
          params = params + '?' // preface the first param
        } else {
          params = params + '&' // preface the first param from queryParams
        }
      } else {
        params = params + '&' // delimit the 2nd to n params
      }
      params = params + key + '=' + value // append param key and value
    })

    // Append debugging vars
    if (environment !== 'prod' && question != null && index != null) {
      if (params === '') {
        if (firstParams) {
          params = params + '?' // preface the first param
        } else {
          params = params + '&' // preface the first param from queryParams
        }
      } else {
        params = params + '&' // delimit the 2nd to n params
      }

      let now = new Date();
      let utc_timestamp = String().concat(now.getUTCFullYear().toString() ,"-"
                          , now.getUTCMonth().toString().padStart(2,"0") ,"-"
                          , now.getUTCDate().toString().padStart(2,"0") ,"_"
                          , now.getUTCHours().toString().padStart(2,"0") ,""
                          , now.getUTCMinutes().toString().padStart(2,"0")
                          , now.getUTCSeconds().toString().padStart(2,"0")
      )

      params = params + 'question_slug=' + question.questionName
      params = params + '&question_index=' + index
      //TODO - fix this to remove the Universal Coordinated Time text at the end of the value.
      params = params + '&timestamp=' + utc_timestamp
    }

    return params
  }

  // async function renderLogoBanner() {
    
  //   let hostname = window.location.hostname
  //   // let regexenv = process.env.REACT_APP_LOGOBANNERHOSTNAME
  //   if( hostname.search(process.env.REACT_APP_LOGOBANNERHOSTNAME)) {
  //     setdisplayLogoBannerAndFooter(true)
  //     //log("hostname "+hostname+" is to have logo banner")
  //   } else {
  //     //log("hostname "+hostname+" is NOT to have logo banner")
  //   }
  //   //log("-- test was: "+process.env.REACT_APP_LOGOBANNERHOSTNAME)
  // }

  // async function renderReferralBanner() {
  //   // Check if header variable is set
  //   if (referralHeader != 'undefined') {
  //     if (referralHeader != null) {
  //       if (typeof referralHeader.workplacecode != 'undefined') {
  //         setDisplayReferralBanner(true)
  //       }
  //     }
  //   }
  // }


	function startHealthCheck() {
    if (!questionSetLoaded) {
      return;
    }

    let startScreenContainer = getDOMElement('container-primary', 'className')[0];
    if (startScreenContainer.classList.contains('scrollable')) {
      startScreenContainer.classList.remove('scrollable');
    }

		log('question set loaded:' + questionSetLoaded);
		log(dataMapping)
		log(questionSet)
		log(questionSetID)
		log(questionSetName)
		log(questionSetType)

		setDesktopViewClassList(addClassToClassList(desktopViewClassList, 'half-width'))

		//setTimeout(() => setDesktopSidecartContent(`<h2>${questionSet[0].questionSection ?? 'Welcome'}</h2>`), transitionInitTimeMilliseconds/4)
		setHeaderState('inProgress')
		nextQuestion()
	}

	// function startHealthCheckMobile() {
	// 	log('mobile')
	// }

	function nextQuestion() {
    let isLastQuestionOfQuiz = isFinalQuestion(); //questionIndexLocal == (questionSet.length - 1)  ;

    if (isLastQuestionOfQuiz) {
      log("isLastQuestionOfQuiz:" + isLastQuestionOfQuiz)
    }
    
    if (isLastQuestionOfQuiz) {
      showResultsScreen();
    } else {
      questionIndexLocal = getNextValidQuestionIndexNew()
      log("questionIndexLocal in nextQuestion is "+ questionIndexLocal);
      let arr = [questionIndexLocal];
      setQuestionIndex(questionIndexLocal);
      setQuestionIndexFlow((questionIndexFlow) => ([...questionIndexFlow, ...arr]))

      let percentage = Math.floor(((questionIndexLocal + 1) / questionSet.length) * 100)
      setProgressPercentage(percentage);

      displayQuestion(questionIndexLocal);
    }
	}

	function previousQuestion() {
		//Reset cached elements from current question (incase there are any for the previous)
		cachedElementsRef.current = [];
    log('navback clicked')

		// Get previous index (current is last element in array so -2) and save new state
		let previousIndex = questionIndexFlow[questionIndexFlow.length - 2];
    setQuestionIndex(previousIndex);
    questionIndexLocal=previousIndex;
    log("navback previousIndex="+previousIndex);
		let newArr = questionIndexFlow.filter(i => i !== questionIndexFlow[questionIndexFlow.length - 1]);
    log("navback newArr="+newArr);
		setQuestionIndexFlow(newArr);
		
		let percentage = Math.floor(((previousIndex + 1) / questionSet.length) * 100)
		setProgressPercentage(percentage)
		displayQuestion(previousIndex);
	}

	function isFinalQuestion() {
    let moreQuestions=false;
    for (let i=questionIndexLocal+1 ; i < questionSet.length ; i++){
      // check if any of the next questions are SHOW=true
      if (checkConditionsShowQuestion(questionResponses, questionSet[i], questionSet[i].condition, hiddenQuestions, queryParams)){
          // true means we have a question coming up that we will show so this current question is not the final
        moreQuestions=true;
      }
    }
		if (moreQuestions===false || questionIndexLocal >= (questionSet.length - 1)) {
			return true
		} else {
			return false
		}
	}

	function showResultsScreen() {
		let resultsVisible = !resultsVisibility;

		setResultsVisibility(resultsVisible);

		if(resultsVisible){
			setHeaderState('results')
		}else{
			setHeaderState('inProgress')
		}
	}

  function getNextValidQuestionIndexNew(){
    // questionIndex is the current array index of the questionSet
    log("questionIndexFlow is "+questionIndexFlow)
    // starting from the next question after the current one (questionIndex+1), loop through 
    // subsequent questions till we find one that has a SHOW condition that is true.
    for (let i = questionIndexLocal+1; i < questionSet.length; i++){
      // loop through subsequent questions to find the next one to show
      if(checkConditionsShowQuestion(questionResponses, questionSet[i], questionSet[i].condition, hiddenQuestions, queryParams)){
        log("new refactored index is "+ i )
        return i
      } else{
        // do we need to update hiddenQuestions??
        log("Skipping question "+ questionSet[i].questionName)
      }
    }
  }
  function getNextValidQuestionIndex() {
    let initialQuestionIndex = questionIndexLocal;
    let newQuestionIndex;

    if (questionIndexFlow.length > 1) {
      if (questionIndexFlow[questionIndexFlow.length - 2] + 1 < questionIndexFlow[questionIndexFlow.length - 1]) {
        newQuestionIndex = questionIndexFlow[questionIndexFlow.length - 2] + 1;
      }
    }

    if (typeof(newQuestionIndex) == 'undefined') {
      questionIndexLocal = questionIndexLocal === 0 && questionIndexFlow.length > 0 ? questionIndexFlow[questionIndexFlow.length - 1] : questionIndexLocal
      newQuestionIndex = initialQuestionIndex === 0 && questionIndexFlow.length > 0 ? questionIndex : questionIndexLocal + 1 // get next question index     
    }
    setQuestionIndex(questionIndexLocal)
    // Look up next question conditions, if valid
    //FIXUP
    if (typeof questionSet[newQuestionIndex] == 'undefined') {
      return newQuestionIndex
    }
    if (typeof questionSet[newQuestionIndex].condition == 'undefined') {
        return newQuestionIndex
    }
    

    log("initialQuestionIndex="+initialQuestionIndex)
    log("newQuestionIndex="+newQuestionIndex)

    // Store condition in tidy variable
    let condition = questionSet[newQuestionIndex].condition
    log("condition: "+condition)

    if(questionSet[newQuestionIndex].condition==null 
//      || checkConditionsShowQuestion(questionResponses, questionSet[newQuestionIndex], questionSet[newQuestionIndex].condition, hiddenQuestions)
      ){
      return newQuestionIndex
//    }else {
//      return (newQuestionIndex++)
    }
    if(!checkConditionsShowQuestion(questionResponses, questionSet[newQuestionIndex], questionSet[newQuestionIndex].condition, hiddenQuestions, queryParams)){
	    newQuestionIndex = newQuestionIndex + 1
      log("new refactored index is "+ newQuestionIndex )
      return newQuestionIndex
    }
	  else {
		  return newQuestionIndex
	  }
    // Check for null condition
    // if (typeof condition == 'undefined' || condition == null || condition == '') {
    //   // No conditions - this is here purely for a catch all and readability 
    //   log('No condition for question '+questionSet[newQuestionIndex])
    // } else {
    //   // Check count of "|" delimiter with split
    //   if (condition.includes('|') && condition.split('|').length == 2) {
    //     let explodedConditionStr = condition.split('|') 
    //     log("Condition from question.condition:")
    //     log(explodedConditionStr[0])

    //     // Check for externalonly and return
    //     if (explodedConditionStr[0].trim() == '{{EXTERNALONLY}}' || explodedConditionStr[0].trim() == '"{{EXTERNALONLY}"}') {
    //       if (queryParams.get('userid') == null) {
    //         return newQuestionIndex
    //       } else {
    //         return (newQuestionIndex++)
    //       }
    //     }

    //     // Remove any and all " characters
    //     condition = explodedConditionStr[0].split('"').join('')
    //     log("condition1 : "+condition)

    //     // Encapsulate all response meta keys in quotations
    //     let numberCap = 32
    //     for (let i = 0; i <= numberCap; i++) {
    //       let searchStr = 'response' + i
    //       let replacementStr = '"response' + i + '"'
    //       condition = condition.split(searchStr).join(replacementStr)
    //     }

    //     condition = condition.split('NULL').join('"NULL"')

    //     log("condition2 : "+condition)

    //     // Replace all {{tags}} with response meta key if presence, else replace all {{tags}} with response value.
    //     for (let i = 0; i < questionResponses.current.length; i++) {
    //       let searchStr = '{{' + questionResponses.current[i].slug + '}}' // compose the slug to search for
    //       let replacementStr = questionResponses.current[i].answer // set the answer as the replacment str
    //       if (questionResponses.current[i].answerMeta != '') { // check if the meta isn't an empty string (indicates non-multiselect)
    //         replacementStr = questionResponses.current[i].answerMeta // update the replacement str
    //       }

    //       // If value is a poential int, print out as is, else encapsulate in quotations
    //       if (!isNaN(parseInt(replacementStr))) { // check if it's a number
    //         // is a number, leave replacement str as is
    //       } else {
    //         // not a number, encapsulate in quotations
    //         replacementStr = '"' + replacementStr + '"'
    //       }
          
    //       condition = condition.split(searchStr).join(replacementStr)
    //     }
        
    //     if (questionSet[newQuestionIndex].questionType === "Single-Select-Waist") {
    //     	// This is a waist input question
    //     	// This type of question should only be asked once
    //     	// It could be one of a series of question on waist size, i.e. this could be the 2nd or 3rd time this question is asked
    //     	// Assumption - there will only be one waist input question for each gender
    //     	// Get previously stored reponse for gender and force the display of only the correct waist input
    //     	// Assumption - input from sex question can be appended to the str "waist_size_" to get the slug for the waist size
    //     	// Original solution was to look for waist inputs recorded - this doesn't allow for if a user navigates back as a waist answer would already be recorded, skipping all waist inputs

    //     	// Initialise quesiton index modifier as 1
    //     	let indexModifier = 1

    //     	// Create 

    //     	// get previously recorded responses for "waist_size_male", "waist_size_female", and "waist_size_other"
    //   		let sexAnswer = getAnswerForSlug('sex')
    //   		sexAnswer = sexAnswer.trim() // trim it
    //   		sexAnswer = sexAnswer.toLowerCase()

    //   		log("waist_size_" + sexAnswer)
    //   		log("questionSet[newQuestionIndex].questionName "+questionSet[newQuestionIndex].questionName)

    //   		// Check if response has been stored
    //   		if (questionSet[newQuestionIndex].questionName == ("waist_size_" + sexAnswer)) {
    //   			// Current waist input matches sex provided
    //   			return newQuestionIndex
    //   		} else {
    //   			// Current waist input doesn't match sex provided - find next valid waist input or next question type
    //   			let nextQuestionInvalid = true 
    //   			while (nextQuestionInvalid) {

    //   				if (questionSet[(questionIndexLocal + indexModifier)].questionType === "Single-Select-Waist") {
    //   					// Another waist question - check if the slug is valid
    //   					if (questionSet[(questionIndexLocal + indexModifier)].questionName == ("waist_size_" + sexAnswer)) {
		// 	      			return (questionIndexLocal + indexModifier) // Current waist input matches sex provided - return this index
		// 	      		} else {
    //   						indexModifier++ // Increment index Modifier
    //   					}
    //   				} else {
    //   					// Different question found - terminate loop
    //   					nextQuestionInvalid = false
    //   				}
    //   			}

    //   			// Return questionIndex with new modifier
    //   			return questionIndexLocal + indexModifier
    //   		}
    //     }

    //     log("condition3 : "+condition)

    //     // Evaluate the condition
    //     let result = false 
    //     try {
    //       result = eval(condition)
    //     } catch (err) {
    //       log('Question condition error: expression couldn\'t be evaluated. \nCondition: "'+condition+'"')
    //       return newQuestionIndex
    //     }

    //     if (typeof result == 'boolean') {
    //       // Do nothing
    //     } else {
    //       log('Question condition error: expression didn\'t evaluate to a true/false. \nCondition: "'+condition+'"')
    //       return newQuestionIndex
    //     }

    //     // Tidy the action string
    //     let tidyConditionActionStr = tidyConditionalString(explodedConditionStr[1])

    //     if (result == true && tidyConditionActionStr == 'SHOW') {

    //       // SHOW this question - maintain the current index (effectively no change)
    //       log('question.condition evaluated TRUE on action SHOW - showing question')


    //     } else if (result == true && tidyConditionActionStr == 'HIDE') {
          
    //       // HIDE this question (i.e. skip this question) - increase question index by one
    //       newQuestionIndex = newQuestionIndex + 1 // get next question index
    //       log('question.condition evaluated TRUE on action HIDE - skipping question')


    //     } else if (result == false && tidyConditionActionStr == 'SHOW') {
          
    //       // SHOW is the action, but the condition failed (i.e. skip this question) - increase question index by one
    //       newQuestionIndex = newQuestionIndex + 1 // get next question index
    //       log('question.condition evaluated FALSE on action SHOW - skipping question')


    //     } else if (result == false && tidyConditionActionStr == 'HIDE') {
          
    //       // HIDE is the action, but the condition failed (i.e. skip this question)
    //       // maintain the current index (effectively no change)
    //       log('question.condition evaluated FALSE on action HIDE - showing question')

    //     }

    //   } else {
    //     // Too many "|" key delimiters added - error out
    //     log("Error in question condition: Question can only contain one \"|\" delimiter.")
    //   }
    // }

    //return newQuestionIndex
  }
  // Moved tidyConditionalString to helpers.js -- Andrew
  // function tidyConditionalString(string) {
  //   string = string.replaceAll('{','')
  //   string = string.replaceAll('}','') // 
  //   string = string.replaceAll('\"','') // remove backslash with double quotation marks
  //   string = string.replaceAll('"','') // remove double quotation marks
  //   string = string.replaceAll('\\','') // remove backslash
  //   string = string.trim() // Remove whitespace from ends

  //   return string
  // }

function handleQuestionResponse(slug, answer, progressToNextQuestion) {
    log('Slug: ' + slug);
    log('Answ: ' + answer);

	progressToNextQuestion = typeof(progressToNextQuestion) == 'undefined' || 
							progressToNextQuestion == null ? true : progressToNextQuestion;

    // Look up question from question set and store response key in answerMeta
    let answerMeta = ''
    for (let i = 0; i < questionSet.length; i++) {
      if (questionSet[i].questionName === slug) {

        // Match found, get question response meta
        if (typeof questionSet[i].questionResponses != 'undefined') { // check responses exist
          //let responseIndex = null // store for matching response index

          // Determine answer name from question possible answers (if possible)
          //TODO - this loop generates the answerMeta for the responses (eg. response1: No) but it doesn't work for multiquestion as the questionResponses are in children
          //TODO   so we need to review this code and update to handle the answerMeta for children or just do this in the multiquestion submit function before passing the answers back to the healthcheck parent
          for (const [key, value] of Object.entries(questionSet[i].questionResponses)) {
						if (typeof(answer) === "object" && answer.includes(value)) {
							answerMeta += ',' + key;
						} else if (value === answer) {
							answerMeta = key // store response key in answerMeta
						}
          }

				  if (answerMeta.startsWith(',')) {
						answerMeta = answerMeta.substring(1);
				  }
        } // multiple responses not detected, store nothing 
      }
    }

    // Loop through responses array and add question data and question meta
    let match = false
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug === slug) {
        match = true // mark match as true
        questionResponses.current[i].answer = answer // update question response
        questionResponses.current[i].answerMeta = answerMeta
        // TO DO - send reponse
      }
    }

    if (slug === "firstname") {
    	setUserFirstName(answer)
    } else if (slug === "profile") {
    	let answerObj = JSON.parse(answer)
    	let name = typeof(answerObj.firstname) !== 'undefined' ? answerObj.firstname : ''
    	setUserFirstName(name)
      setLoggedInUserData(answerObj)
    }

    if(slug === "contact_email"){
      let emailInfo=JSON.parse(answer)
      setUserEmail(emailInfo.email)
    }

    if(slug === "contact_phone"){
      let phoneInfo=JSON.parse(answer)
      setUserPhone(phoneInfo.phone)
    }

    // No match found in loop, add new answer
    if (!match) {
			questionResponses.current.push({slug: slug, answer: answer, answerMeta: answerMeta});
    }

    // Push answer to Simbyte API
    pushQuestionResponseToAPI()

    log('Responses dump')
    log(questionResponses.current)

		if (cachedElementsRef.current.length > 0) {
			progressToNextQuestion = false;

			let postRenderContainer =  getDOMElement('postRenderContainer', 'id');// document.getElementById('postRenderContainer');
			//let chatBoxEl = getDOMElement('chat-box', 'id');
			let chatBoxElWritable;

			if (document.getElementById('healthcheckembed') == null) {
				chatBoxElWritable = ReactDOM.createRoot(postRenderContainer);
			} else {
				chatBoxElWritable = createRoot(postRenderContainer)
			}

			//chatBoxEl.scrollTop = 0;
			//postRenderContainer.scrollTop = 0;
			chatBoxElWritable.render(cachedElementsRef.current) // render array
			// chatBoxElWritable.render(cachedElementsRef.current);

			cachedElementsRef.current = [];
		}

  	if (progressToNextQuestion) {
  		nextQuestion() // go to next question
  	}
  }

  function handleQuestionStop(message) {
  	log("Stop response")
  	log(message)
  	questionIndexLocal = getNextValidQuestionIndex()
  	let arr = [questionIndexLocal];
    setQuestionIndexFlow((questionIndexFlow) => ([...questionIndexFlow, ...arr]))
    let fauxQuestionText = message
    if (!fauxQuestionText.includes('<TEXT>') && !fauxQuestionText.includes('<HEADING>')) {
    	fauxQuestionText = '<TEXT>' + message + '</TEXT>'
    }

  	let fauxQuestion = {
  		questionText: fauxQuestionText,
  		image: '',
  		animation: '',
  		buttonImages: ''
  	}

		let conversationElements = getConversationElements(fauxQuestion)

    // Remove problematic elements
    for (let i = (conversationElements.length - 1); i >= 0; i--) {
      if (conversationElements[i].type === '') {
        log('Issues with conversationElements[' + i + ']:')
        log(conversationElements[i])
        conversationElements.splice(i,1)
      }
    }

		if (document.getElementById('healthcheckembed') == null) {
			let chatBoxEl = document.getElementById('chat-box');
			let chatBoxElWritable = ReactDOM.createRoot(chatBoxEl);
			//chatBoxEl.scrollTop = 0;
			chatBoxElWritable.render(conversationElements) // render array
		} else {
			const master = document.getElementById('healthcheckembed');
			const shadow = master.shadowRoot;	
			const shadowRoot = shadow.getElementById('chat-box');
			let chatBoxElWritable = createRoot(shadowRoot)
			//shadowRoot.scrollTop = 0
			chatBoxElWritable.render(conversationElements) // render array
		}
		
		//Desktop - Update sidecart content
		//setDesktopSidecartContent(`<h2>Heath Check Stopped</h2>`);
  }

  function displayChachedComponents() {
    if (cachedElementsRef.current.length > 0) {
      let postRenderContainer =  getDOMElement('postRenderContainer', 'id');// document.getElementById('postRenderContainer');
      let chatBoxEl = getDOMElement('chat-box', 'id');
      let chatBoxElWritable;
  
      if (document.getElementById('healthcheckembed') == null) {
        chatBoxElWritable = ReactDOM.createRoot(postRenderContainer);
      } else {
        chatBoxElWritable = createRoot(postRenderContainer)
      }
  
      chatBoxElWritable.render(cachedElementsRef.current) // render array
      
      setTimeout(() => {
        chatBoxEl.scrollTo({top: chatBoxEl.scrollHeight, behavior: 'smooth'});
      }, 50);

      cachedElementsRef.current = [];
    }
  }

  async function pushQuestionResponseToAPI() {
    
    // formulate form data
    let formData = new FormData()

    if (isFinalQuestion()) {
    	formData.append('final','1')
    }
    log('pushQuestionResponse: questionIndexLocal ='+ questionIndexLocal)
    //log(questionIndex)

    // Add question set ID to the formData
    if (apiSaveResponsesID !== '') { // iff not '' this indicates the save response ID has been previously set
    	formData.append('responseId',apiSaveResponsesID) // append the response ID so the data set can be 
	    log('apiSaveResponsesID='+apiSaveResponsesID)
      log('sessionCode='+sessionCode)
    }

    for (let i = 0; i < questionResponses.current.length; i++) {
    	let answer = questionResponses.current[i].answer
    	if (questionResponses.current[i].answerMeta !== '') {
    		answer = questionResponses.current[i].answerMeta
    	}

    	formData.append('responses['+i+'][questionName]', questionResponses.current[i].slug)
    	formData.append('responses['+i+'][response]', answer)
    }
    let response=null;
    // questionSetID stored globally
    // scroll to top of the page before we go to API and refresh the page
    // have to blur first to avoid a scrolling bug in chrome where it doesn't 
    // scroll all the way to the top if behaviour is smooth.
    if (document.getElementById('healthcheckembed')){
      document.getElementById('healthcheckembed').blur();
      document.getElementById('healthcheckembed').scrollIntoView({
        block:"start",
        behaviour: "smooth"
      })
    }
    log("formData")
    for (const pair of formData.entries()) {
      log("formData : pair[0]="+pair[0]+" pair[1]=" + pair[1]);
    }
    setApiLoading(true)
	  try {
	    //response = await callSaveResponsesAPIEndpoint(formData, structuredClone(questionSet[questionIndex]), questionIndex)
      let retries = 2
      response = await callSaveResponsesAPIEndpoint(formData, JSON.parse(JSON.stringify(questionSet[questionIndexLocal])), questionIndexLocal, retries, bearerToken.current)
    } catch (Error) {
      log("error saving responses: "+Error);
      setErrorMsg(Error);
      setErrorState(true);
    }
    // this might be required but needs more testing of the impacts.
    //TODO if (typeof response.responseId != 'undefined' || typeof response.riskassessment_id != 'undefined') {
    if (typeof response.responseId != 'undefined') {
      apiSaveResponsesID = response.responseId;
      //TODO apiSaveResponsesID = typeof response.responseId == 'undefined'?response.riskassessment_id:response.responseId
      
      setSessionCode(apiSaveResponsesID)

      //setSaveUserDetailsID(response.responseId); //TODO: save the actual user GUID (if we have one), what's the point of saving responseId again?
      if (clarity.hasStarted) { 
        clarity.consent();
        log ("Clarity started using USER_ID="+response.responseId);
        clarity.identify('USER_ID', response.responseId); 
        // at this point the questionIndex has already been increemented for the next question so minus 1 to get the one we just answered.
        // log("Clarity slug="+questionSet[questionIndex-1].questionName);
        clarity.setTag('slug',questionSet[questionIndexLocal-1].questionName);
      } else {
          log("Clarity not started");
      }
    } else {
      log ("callSaveResponsesAPIEndpoint - no responseId","error")
    }

    // lets handle the scenario where we have passed final:1 but the API didn't give us a resultset
    if(resultsVisibility && headerState==="results"){
      if ( typeof response.alertmessage == 'undefined'){
        log("API didn't return a result.", "error")
        setErrorMsg("The result calculation has failed, please try again later.")
        setErrorState(true)
        setApiLoading(false)
      }
    }

    // Results should include alertmessage (apparently)
    if (typeof response.alertmessage != 'undefined' && response.alertmessage === "Completed") {
      if (response.resultSet == null){
        log("No resultSet in the final results response from the API.", "error")
        setErrorMsg("Sorry, we had a problem calculating your results. Please try again later or contact us for assistance.")
        setErrorState(true)
      } else {
        log(response.resultSet)
        setOveralResultSet(response.resultSet);
        let curatedResults = response 
        setOveralResult(curatedResults);
      }
    }

    // setStartButtonText("All loaded!")
    // setTimeout(() => setStartButtonText("Great. Let's get started"), 2000)
  }

	// questionSetID stored globally
  async function callSaveResponsesAPIEndpoint(formData, question = null, index = null, retries, localToken) {
    //let responseData = []
    let paramsStr = getParamsAsStr(true, question, index)
    let fetchURL = baseAPIURL + 'question/saveResponses/' + questionSetID + '/v3' + paramsStr
    setApiLoading(true);

    if (localToken === ""){
      localToken = await props.tokenRefresh();
      bearerToken.current = localToken
      log("refreshed token for saveDetails:"+localToken+", retries "+retries)
    }

    try {
      const response = await fetch(fetchURL, {
        method: 'POST',
        body: formData,
        headers: new Headers({
            "Authorization": "Bearer "+ localToken,
            "Client-Service": "simbyte-mh4l-api-v1",
            "Accept": "application/json"
        }),
        mode: "cors"
      });
      if(!response.ok){
        if (response.status === 401 ){
          if (retries > 0 ){
            // bearer token expired - recall the function with token='' to invoke the refreshToken callback
            localToken=''
            return callSaveResponsesAPIEndpoint(formData, question, index, retries-1, localToken)
          } else {
            log("token refresh has failed in callSaveResponsesAPIEndpoint.")
            throw new Error("Error occurred refreshing token, multiple retries have failed. Please check your network connection")
          }
        }
        setErrorMsg("Network error saving responses : " + response.status + " " + response.statusText)
        log("Network error saving responses : " + response.status + " " + response.statusText, "error")
        errorElement.innerText="Network error saving responses : " + response.status + " " + response.statusText
        throw new Error ("Network error saving responses : " + response.status + " " + response.statusText)        
      } else {
        const data = await response.json();
        log(data);
        setApiLoading(false);
        setErrorMsg('');
        setErrorState(false);
        return data
      }
    } catch (Error) {
      log(Error.Message);
      setErrorState(true);
      setApiLoading(false);
      setErrorMsg(Error.message);
    }
  }
  
	async function pushUserDetailsToAPI(formDataObject, callback, retries) {
		let formData = new FormData();
		callback = callback || function() {};
    log("in the pushUserDetailsToAPI function")

		Object.keys(formDataObject).forEach(fieldName => {
			log("fieldName="+fieldName + " formDataObject=" + formDataObject[fieldName]);
			formData.append(fieldName, formDataObject[fieldName]);
		});

		//let responseData = [];
    let paramsStr = getParamsAsStr(true)
    let fetchURL = baseAPIURL + 'question/saveDetails/' + overalResult.riskassessment_id + '/v3' + paramsStr;
    const response =  await saveDetails(formData, callback, retries, bearerToken.current)
    return response

    async function saveDetails(formData, callback, retries, localToken) {
      try {
        if (localToken === ''){
          localToken = await props.tokenRefresh();
          bearerToken.current = localToken;
          log("refreshed token for saveDetails:"+localToken+", retries "+retries)
        }
        const response = await fetch(fetchURL, {
          method: 'POST',
          body: formData,
          headers: new Headers({
            "Authorization": "Bearer " + localToken,
            "Client-Service": "simbyte-mh4l-api-v1",
            "Accept": "application/json"
          }),
          mode: "cors"
        })
        if (!response.ok){
          if(response.status === 401){
            if(retries > 0){
              // refresh the token
              localToken = ''
              log("401 returned calling saveDetails recalling saveDetails with retries="+retries-1)
              return saveDetails(formData, callback, retries-1,localToken)
            } else {
              throw new Error("token refresh failed after multiple attempts, please check your network connection.")
            }
          } else {
            // not 401 but some other error
            throw new Error ("saveDetails failed ("+response.status+") "+ response.statusText)
          }
        } else {
          const data = await response.json()
          log(data);
          setApiLoading(false);
          if (data.error!=="Success"){
            setErrorMsg(data.error);
            setErrorState(true);
            setJoinFormSuccess(false);
            callback(false, data.error);
          } else {
            setErrorMsg('');
            setErrorState(false);
            setJoinFormSuccess(true);
          }
          if (data.moodle2url != null){
            // no moodle link
            moodle2Link.current = data.moodle2url;
            log("Moodle URL returned " + data.moodle2url);
          }
          return data
        }
      }
      catch (error) {
        log('Error in final join form submission:' + error, "error");
        setJoinFormSuccess(false);
        setErrorMsg("There was an error submitting the form.");
        setErrorState(true);
        // Determine form success status
        callback(false, "There was an error submitting the form. Check your submitted information and try again.");
      };
    }
	}

	function displayQuestion(index) {
		//let question = structuredClone(questionSet[index]);
    let question= JSON.parse(JSON.stringify(questionSet[index]));
    let formattedQuestion = filterDynamicTags(question)
		let conversationElements = getConversationElements(formattedQuestion)
    log("in function displayQuestion, index="+ index);

    // Remove problematic elements
    for (let i = (conversationElements.length - 1); i >= 0; i--) {
      if (conversationElements[i].type === '') {
        log('Issues with conversationElements[' + i + ']:'+conversationElements[i], "warn")
        conversationElements.splice(i,1)
      }
    }

		if (document.getElementById('healthcheckembed') == null) {
			let chatBoxEl = document.getElementById('chat-box');
			let chatBoxElWritable = ReactDOM.createRoot(chatBoxEl);
			//chatBoxEl.scrollTop = 0;
			chatBoxElWritable.render(conversationElements) // render array
		} else {
			const master = document.getElementById('healthcheckembed');
			const shadow = master.shadowRoot;	
			const shadowRoot = shadow.getElementById('chat-box');
			let chatBoxElWritable = createRoot(shadowRoot)
			//shadowRoot.scrollTop = 0
      log("conversationElements "+ conversationElements);
			chatBoxElWritable.render(conversationElements) // render array
      document.getElementById('healthcheckembed').scrollTo({
        top: 0,
        left: 0,
        behaviour: 'smooth'
      });
		}
		
		//Desktop - Update sidecart content
		//setDesktopSidecartContent(`<h2>${question.questionSection}</h2>`);
	}

  function filterDynamicTags(question) {
    log("question filterDynamicTags")
    log(question)
    let questionText = question.questionText
    let dynamicTags = []

    if (questionText != null && questionText.includes('[[')) {
      // dynamic tag detected - check number of dynamic tags

      let splitQuestionText = questionText.split('[[')

      // The first string will always be irrelevant, regardless of the count of the array which was made in the split
      // Every string after the first can be split again by ']]' - the closing tags
      // The first element in the array from the 2nd split will be the keyword
      // Loop through the existing tags and push if there's no mach
      for (let i = 1; i < splitQuestionText.length; i++) {
        let splitSubStr = splitQuestionText[i].split(']]')

        if (dynamicTags.length === 0) {
          dynamicTags.push(splitSubStr[0])
        } else {
          let match = false
          for (let j = 0; j < dynamicTags.length; j++) {
            if (dynamicTags[j] === splitSubStr[0]) {
              match = true
            }
          }
          if (match === false) {
            dynamicTags.push(splitSubStr[0])
          }
        }
      }

      log('Dynamic Tags:')
      log(dynamicTags)

      // loop through dynamicTags (if length > 0) and replace all text with matching result from question questionResponses
      for (let i = 0; i < dynamicTags.length; i++) {
        let previousResponse = getPreviousQuestionResponseWithQuestionSlug(dynamicTags[i])
        questionText = questionText.split('[[' + dynamicTags[i] + ']]').join(previousResponse)
        log('Previous Response:')
        log(previousResponse)
      }
    }

    question.questionText = questionText

    return question
  }

  function getPreviousQuestionResponseWithQuestionSlug(slug, deliverMeta = false) {
    let recordedAnswer = ''
    let recordedMeta = ''
 
      // TODO - check if this needs to handle child questions
    if (queryParams.get('userid') != null) {
      let profile = questionResponses.current.find(a => a.slug === "profile");
      let obj = JSON.parse(profile.answer);

      Object.keys(obj).forEach((key) => {
        if (key === slug) {
          recordedAnswer = obj[key]; // get previously recorded answer
          recordedMeta = ""; // get previously recorded answermeta
        }
      });
    }

    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug === slug) {
        recordedAnswer = questionResponses.current[i].answer // get previously recorded answer
         recordedMeta = questionResponses.current[i].answerMeta // get previously recorded answermeta
      }
    }

    if (deliverMeta) {
    	return [recordedAnswer, recordedMeta]
    } else {
    	return recordedAnswer
    }
  }

	function getConversationElements(question) {
    /** Fast XML Implementation */

    // Create FXP option to initilize new instance
    const fxp_options = {
      ignoreAttributes : false, 
      attributeNamePrefix: '',
      preserveOrder: true,
      allowBooleanAttributes: true,
      // attributesGroupName : "@_"
    };

    // Create a new instance of XMLParser from Fast XML Parser Library
    const fxpParser = new XMLParser(fxp_options);

    // Also create a new instance of XMLBuilder from Fast XML Parser Library
    // It is needed for content re-build of a tag 
    const fxpBuilder = new XMLBuilder(fxp_options);

    // Execute the XMLParser to get a JS object
    const resultParse = fxpParser.parse(question.questionText);
    
    // This Array will content the new items
    const newItems = [];

    for(let item in resultParse) {

      // Get TagElement Object and Atrributes array from entries
      // of resultParse usign Deconstructing assignment
      const [tagElement, attributes] = Object.entries(resultParse[item]) || {};

      // Usign the Deconstructing assignment
      // Get tagName (key) and tagContent (value)
      const [tagName, tagContent] = tagElement || [];

      // Get tagAttributes array from destructured attributes var
      // , (comma) is used cuz the first value
      // of attributes is ':@' (attributes identifier)
      const [, ...tagAttributes] = attributes || [];

      // Create newItem object to save the new structure required
      const newItem = {
        type: tagName.toLowerCase(),
        // Build content tag, included nested tags
        // Using fxpBuilder class
        content: fxpBuilder.build(tagContent),
        // Attach attributes found
        attributes: tagAttributes
      };

      // Insert the newItem to newItems Array
      newItems.push(newItem);
    }
    /** Fast XML Parser Ends */


    // Get array of images from question data
    let images = question.image ?? []
    if (question.image !== null)
    {
      if (question.image.includes('|')) {
        images = images.split('|');
      } else {
        images = [question.image];
      }
    }

    // Get array of animations from question data
    let animations = question.animation ?? []
    if (question.animation !== null && typeof question.animation != "undefined")
    {
      if (question.animation.includes('|')) {
        animations = animations.split('|');
      } else {
        animations = [question.animation];
      }
    }

    // Declare extra-loop variables
    let newElements = []
    let elementKey = 0
    let imageIndex = 0
    let animationIndex = 0
    let responseInSitu = false
    let newElementDetails = getInputDetailsForQuestion(question)

    // Loop through array of XML parser entites
    for (let i = 0; i < newItems.length; i++) {

      // Define new element fundamentals
      let element = {
        type: '',
        options: {
          key: question.questionId + '.' + (elementKey++) + '.' + i
        },
        content: newItems[i].content
      }

      // Determine the element type
      switch (newItems[i].type.toLowerCase()) {
        
        case 'heading': 
          element.type = Heading
          element.options.className = 'chat-heading'
          element.options.text = element.content
          break

        case 'text': 
          element.type = Text
          element.options.className = 'chat-heading'
          element.options.text = element.content
          break

        case 'info':
          element.type = Info
          element.options.className = 'information'
          element.options.text = element.content
          break

        case 'image': 
          element.type = Image
          element.options.src = images[imageIndex++] ?? (baseAPIURL + 'public/assets/img/mh4l/questions/image-needed.png')
          break

        case 'animation': 
          element.type = Animation
          element.options.bgClass = 'bg-primary-3'
          element.options.src = animations[animationIndex++] ?? (baseAPIURL + 'public/assets/img/mh4l/questions/image-needed.png')
          break

        case 'response': 
          responseInSitu = true
          newElementDetails = getInputDetailsForQuestion(question, i < (newItems.length -1)) // Get input element details
          element.type = newElementDetails.type
          element.options = {
            ...element.options,
            ...newElementDetails.options
          }
          break

        case 'conversation': 
          element.type = ChatMessage

          if (element.content.includes('<IMAGE></IMAGE>')) { // check content for <IMAGE></IMAGE> tags
            element.options.bgClass = 'bg-primary-3'
            element.options.imageUrl = images[imageIndex++] ?? (baseAPIURL + 'public/assets/img/mh4l/questions/image-needed.png')
          } else if (element.content.includes('<ANIMATION></ANIMATION>')) {
            element.options.bgClass = 'bg-primary-3'
            element.options.animationUrl = animations[animationIndex++] ?? (baseAPIURL + 'public/assets/img/mh4l/questions/image-needed.png')
          } else {
            element.options.bgClass = 'bg-primary'
            element.options.text = element.content
          }        
          break
        default:
      }

      // check for attribute and pass to isConversationSnippetValid()
      let conversationSnippetValid = true
      if (newItems[i].attributes.length > 0) {
        for (let j = 0; j < newItems[i].attributes.length; j++) {
          if (typeof newItems[i].attributes[j].condition != 'undefined') {
            conversationSnippetValid = isConversationSnippetValid(newItems[i].attributes[j].condition)
            log("conversationSnippetValid:")
            log(conversationSnippetValid)
          }
        }
      }

      if (conversationSnippetValid) {
        if (element.type === '') {
          log('element.type is "" at:')
          log(question.questionName)
        }

        if (responseInSitu && element.type === ChatMessage) {
          cachedElementsRef.current.push(React.createElement(
            element.type,
            element.options,
            element.content
          ))
        } else {
            // Push new element to array
          newElements.push(React.createElement(
            element.type,
            element.options,
            element.content
          ))
        }
      }
    }

    // Check if the response is in situ (already added)
    if (!responseInSitu) {
      // Response isn't in situ - add it
      let element = {
        type: '',
        options: {
          key: question.questionId + '.' + (elementKey++)
        },
      }

      newElementDetails = getInputDetailsForQuestion(question)

      element.type = newElementDetails.type
      element.options = {
        ...element.options,
        ...newElementDetails.options
      }

      // Push new element to array
      newElements.push(React.createElement(
        element.type,
        element.options,
      ))
    }

    // Make button
    let buttonText = 'Next'
    if (question.buttonText != null && question.buttonText !== '') {
      buttonText = question.buttonText
    }

    if (
      ( 
        question.options != null && 
        question.options.includes("Custom Next button") && 
        question.questionType !== "Short Answer" && 
        question.questionType !== "Single-Select-Dropdown" &&
        question.questionType !== "Blood Pressure" &&
        question.questionType !== "Multiple Questions" &&
        question.questionType !== "Single-Select" &&
        question.questionType !== "Multi-Select" &&
        question.questionType !== "CVD Score"
      ) || 
      question.questionType === "True/False" ||
      (
        question.questionType === "Short Answer" && question.questionText == null
      )) {

        newElements.push(React.createElement(
        Button,
        {
          key: question.questionId + '.' + (elementKey++),
          classList: 'primary regular large last-button ' + question.questionType.replace(' ', ''),
          innerText: buttonText,
          onclick: nextQuestion,
          style: {marginTop: '32px'},
          features: props.features
        },
        ''
      ))      
    } 

		// Add avatar
		newElements.push(React.createElement(
			ChatAvatar,
			{
				key: question.questionId + '.' + (elementKey++),
				className: isMobile ? 'small' : '',
				fillClass: 'fill-primary',
				autoSetPosition: true
			}
		));


		newElements.push(React.createElement(
			'div',
			{id: 'postRenderContainer'},
		))

    log("newElements:")
		log(newElements)

		return newElements
	}

  function getInputDetailsForQuestion(question, hasCachedComponentsAfterInput) {
    let element = {
      type: '',
      options: {
        slug: question.questionName,
        callback: handleQuestionResponse,
        stopCallback: handleQuestionStop,
        validation: question.validation,
        dataType: question.dataType,
        environment: environment,
        baseAPIURL: baseAPIURL,
        customisation: question.options,
      },
      features: props.features,
      token: props.token,
      tokenRefresh: props.tokenRefresh
    }

    // Declear outside the switch
    let options = []

    switch (question.questionType) {
      case 'Yes/No':
      case 'True/False':
        element.type = YesNo
        element.options = {
            ...element.options,
            ...{
              classList: 'include-m-b',
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        break

      case 'Yes/No/Skip':
        element.type = DynamicYesNoSkip
        element.options = {
            ...element.options,
            ...{
              innerText: '',
              options: [
                question.questionResponses.response1, 
                question.questionResponses.response2,
                question.questionResponses.response3,
                ],
              collapseButtons: true,
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        break;


      // Potential problem here for (question.options == "Responses as Buttons")
      case 'Single-Select':
          // uses Single-Select-Waist code ?

      // eslint-disable-next-line no-fallthrough
      case 'Multi-Select':
          // uses Single-Select-Waist code ?

      // eslint-disable-next-line no-fallthrough
      case 'Single-Select-Waist':
        if (
        		question.questionName !== 'workplace_selection' && 
            question.questionName !== 'contact_selection'
        	) {
          element.type = MultiChoice
          element.options = {
            ...element.options,
            ...{
              question: question,
              continueOnSelect: !(question.options != null && question.options.includes("Custom Next button")),
              sexAnswer: getAnswerForSlug('sex'),
              hasCachedElements: hasCachedComponentsAfterInput,
              hasCachedElementsCallback: displayChachedComponents,
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        } else {
          element.type = DynamicYesNo
          element.options = {
            ...element.options,
            ...{
              innerText: '',
              options: [question.questionResponses.response1, question.questionResponses.response2],
              collapseButtons: true,
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        }
        break;

      case "Short Answer":
        let defaultValue = ''
        if (question.defaultValue != null) {
          defaultValue = question.defaultValue
        }

        if (question.questionName === 'workplace') {
          let workplaceCodeGetKeys = ['tc','trackingcode','tracking_code','eventcode','event_code','workplace','work_place','workplacecode','workplace_code','work_place_code']

          // loop through queryparams and key params and find a matching query param and add to default value
          queryParams.forEach(function(value, key) {
            for (let i = 0; i < workplaceCodeGetKeys.length; i++) {
              if (key === workplaceCodeGetKeys[i]) {
                defaultValue = value
              }
            }
          })
        }

        if (question.questionName !== 'postcode') {
          element.type = DynamicInput
          element.options = {
            ...element.options,
            ...{
              defaultValue: defaultValue,
              inputType: 'text',
              apiBase: baseAPIURL,
              params: getParamsAsStr(false),
              buttonText: question.buttonText != null && question.buttonText !== "" ? question.buttonText : "Next",
              inputPlaceholder: question.explanationText != null && question.explanationText !== "" ? question.explanationText : "Enter " + tidyPlaceholderStr(question.questionName),
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        } 
        else if (question.questionName === 'postcode') {
          //let options = postCodeList.map(a => ({code: a.Pcode, suburb: a.Locality}))
          element.type = AutocompleteInput
          element.options = {
            ...element.options,
            ...{
              type: "text", 
              id: "colFormLabel",
              options: options,
              buttonText: question.buttonText != null && question.buttonText !== "" ? question.buttonText : "Next",
              placeholder: question.explanationText != null && question.explanationText !== "" ? question.explanationText : "Enter " + tidyPlaceholderStr(question.questionName),
              features: props.features,
              queryParams: queryParams,
              token: props.token,
              tokenRefresh: props.tokenRefresh
            }
          }
        }
        break


      case "Single-Select-Dropdown":
        // Get options for dropdown
        options = []

        Object.values(question.questionResponses).map((val, index) => {
          options.push(val)
          return null
        });

        if (question.questionName === "postcode") {
          //let simplePostCodes = postCodeList.map(a => ({code: a.Pcode, suburb: a.Locality}))
          //options = simplePostCodes
        }

        element.type = AutocompleteInput
        element.options = {
          ...element.options,
          ...{
            type: "text", 
            id: "colFormLabel",
            options: options,
            buttonText: question.buttonText != null && question.buttonText !== "" ? question.buttonText : "Next",
            placeholder: question.explanationText != null && question.explanationText !== "" ? question.explanationText : "Enter " + tidyPlaceholderStr(question.questionName),
            features: props.features,
            queryParams: queryParams,
            token: props.token,
            tokenRefresh: props.tokenRefresh
          }
        }
        break


      case "Multiple Questions":
        // If this has a multiselect, get the options
        options = []
        if (typeof question.childQuestions == "undefined") {
          log ("Multiple Question with no Children : " + question.questionName);
        }
        else {
          for (let k = 0; k < question.childQuestions.length; k++) {
            let childQuestionOptions = []
            //if (question.childQuestions[k].questionType == "Single-Select-Dropdown" || question.childQuestions[k].questionName === "postcode") {
            if (question.childQuestions[k].questionType === "Single-Select-Dropdown" 
                || question.childQuestions[k].questionType === "Single-Select" 
                || question.childQuestions[k].questionType === "Multi-Select" 
                || question.childQuestions[k].questionName === "postcode") {
                // should we use val, key instead of index ?? -Andrew
                Object.values(question.childQuestions[k].questionResponses).map((val, index) => {
                  childQuestionOptions.push(val);
                  return null
                });

                if (question.childQuestions[k].questionName === "postcode") {
                  //let simplePostCodes = postCodeList.map(a => ({code: a.Pcode, suburb: a.Locality}));
                  //childQuestionOptions.push(simplePostCodes)
                }
            }
            options.push(childQuestionOptions)
          }
       }
        element.type = MultiQuestion
        element.options = {
          ...element.options,
          ...{
            buttons: question.questionResponses,
            options: options,
            answers: questionResponses,
            sexAnswer: getAnswerForSlug('sex'),
            baseAPIURL: baseAPIURL,
            childQuestions: question.childQuestions,
            inputType: 'text',
            buttonText: question.buttonText != null && question.buttonText !== "" ? question.buttonText : "Next",
            features: props.features,
            queryParams: queryParams,
            token: props.token,
            tokenRefresh: props.tokenRefresh
            // setErrorMsgCallBack: setErrorMsg,
            // setErrorStateCallBack: setErrorState
          }
        }
        break


      case 'CVD Score':
      case 'Blood Pressure':
        if (question.questionType === 'CVD Score') {
          element.type = CVDScore
        } else if (question.questionType === 'Blood Pressure') {
          element.type = BloodPressure
        }
        
        element.options = {
          ...element.options,
          ...{
            buttons: question.questionResponses,
            inputType: 'text',
            inputPlaceholder: question.explanationText != null && question.explanationText !== "" ? 
            question.explanationText : "Enter " + tidyPlaceholderStr(question.questionName),
            features: props.features,
            queryParams: queryParams,
            token: props.token,
            tokenRefresh: props.tokenRefresh
          }
        }
        break
        default:
        
    }

    return element
  }

  // Gets the answer for a slug from stored responses
  // Returns string
  function getAnswerForSlug(slug) {
    let answer = ''
    //for (let i = 0; i < questionResponses.current.length; i++) {
      // if (questionResponses.current[i].slug == slug) {
      //   answer = questionResponses.current[i].answer // get previously recorded answer
      // }
      // we also need to check childQuestions for the slug/answer      
      for (const [key, value] of Object.entries(questionResponses.current)){
          if ( key === slug){
            answer = value
            break
          } else if (/^\s*(\{|\[)/.test(value['answer'])){
              let childAnswers=[]
              try{
                  childAnswers = JSON.parse(value['answer'])
                  log(childAnswers)
              }catch(e){
                  //  do nothing or return false
              }
              // we now have a json object of the child answers to search
              if (childAnswers.hasOwnProperty(slug)){
                  // get the answer for that tag from the childAnswers object
                  answer = childAnswers[slug];
                  return answer;
              }
          }
      }
    //}
    return answer
  }

  // Gets the answerMeta for a slug from stored responses
  // Returns string
  function getAnswerMetaForSlug(slug) {
    let answerMeta = ''
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug === slug) {
        answerMeta = questionResponses.current[i].answerMeta // get previously recorded answer
      }
      if (/^\s*(\{|\[)/.test(questionResponses.current[i].answer)){
        let childAnswers=[]
        try{
            childAnswers = JSON.parse(questionResponses.current[i].answer)
            log(childAnswers)
        }catch(e){
            //  do nothing or return false
        }
        if (childAnswers.hasOwnProperty(slug)){
          // get the answerMeta for that tag from the questionSet
          // find the slug in the child questions
          for (let x=0; x<questionSet.length; x++){
            // loop through each parent question checking for children
            if(questionSet[x].childQuestions.hasOwnProperty(slug)){
              console.dir(questionSet[x].childQuestions[slug])
              //TODO add meta lookup that matches child responseX
            }
          }
          break
        }      
      }
    }
    return answerMeta
  }

  // Gets the answer and answerMeta for a slug from stored responses
  // Returns objects {answer, answerMeta}
  function getAnswerAndMetaForSlug(slug) {
    let answer = ''
    let answerMeta = ''
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug === slug) {
        answer = questionResponses.current[i].answer
        answerMeta = questionResponses.current[i].answerMeta // get previously recorded answer
      }
    }
    return {answer: answer, answerMeta: answerMeta}
  }

  function getConditionStr(questionTextSubStr) {
    let conditionStr = ''
    let conditionAssignature = ''

    if (questionTextSubStr.includes('condition:"')) {
      conditionAssignature = ':'
    } else if (questionTextSubStr.includes('condition="')) {
      conditionAssignature = '='
    }

    let subStr = questionTextSubStr.split('condition' + conditionAssignature + '"')
    let splitIndex = subStr[1].indexOf('"')
    conditionStr = subStr[1].substring(0,splitIndex)

    return conditionStr
  }

  function isConversationSnippetValid(condition) {
  	// This function will leverage eval() to complete the conditional statements

  	let originalCondition = condition

  	if (condition === '{{EXTERNALONLY}}') {
  		if (queryParams.get('userid') == null) {
  			return true
  		} else {
  			return false
  		}
  	} else {

	  	// Encapsulate all response meta keys in quotations
	  	let numberCap = 9
	  	for (let i = 0; i <= numberCap; i++) {
	  		let searchStr = 'response' + i
	  		let replacementStr = '"response' + i + '"'
	  		condition = condition.split(searchStr).join(replacementStr)
	  	}

	  	// Replace all {{tags}} with response meta key if presence, else replace all {{tags}} with response value.
	    for (let i = 0; i < questionResponses.current.length; i++) {
	    	let searchStr = '{{' + questionResponses.current[i].slug + '}}' // compose the slug to search for
	    	let replacementStr = questionResponses.current[i].answer // set the answer as the replacment str
	    	if (questionResponses.current[i].answerMeta !== '') { // check if the meta isn't an empty string (indicates non-multiselect)
	    		replacementStr = questionResponses.current[i].answerMeta // update the replacement str
	    	}

				// If value is a poential int, print out as is, else encapsulate in quotations
	    	if (!isNaN(parseInt(replacementStr))) { // check if it's a number
	    		// is a number, leave replacement str as is
	    	} else {
	    		// not a number, encapsulate in quotations
	    		replacementStr = '"' + replacementStr + '"'
	    	}

	    	condition = condition.split(searchStr).join(replacementStr)
	    }

	    let result = false 
	    try {
	    	result = eval(condition)
	    } catch (err) {
	    	log('Conversation condition error: expression couldn\'t be evaluated. \nCondition:"'+originalCondition+'"')
	    }

	  	if (typeof result == 'boolean') {
	  		return result
	  	} else {
	  		log('Conversation condition error: expression didn\'t evaluate to a true/false. \nCondition:"'+originalCondition+'"')
	  		return false
	  	}
	  }
  }

  function evaluateConversationLogicalCondition(condition) {
  	let outcome = null

  	if (condition[1] === '&&') {
  		outcome = (condition[0] && condition[2])
  	} else if (condition[1] === '||') {
  		outcome = (condition[0] || condition[2])
  	}

  	return outcome
  }

  // function evaluateNestedConversationCondition(condition) {
  // 	condition[0] = evaluateConversationComparisonCondition(condition[0])
  // 	condition[2] = evaluateConversationComparisonCondition(condition[2])

  // 	return evaluateConversationLogicalCondition(condition)
  // }

  // function evaluateConversationComparisonCondition(partial) {
  // 	// Replace variables
  // 	let responseAnswerAndMeta = []
  // 	let tagIndex = 0

  // 	for (let i = 0; i < partial.length; i++) {
  // 		if (partial[i].split('{{').length === 2) {
  // 			tagIndex = i
  // 			partial[i] = partial[i].replace('{{','')
  // 			partial[i] = partial[i].replace('}}','')
  // 			partial[i] = partial[i].trim()
  // 			responseAnswerAndMeta = getPreviousQuestionResponseWithQuestionSlug(partial[i], true) 
  // 		}
  // 	}

  // 	// Use meta or value
  // 	if (partial[0].indexOf('response') > -1 || partial[2].indexOf('response') > -1) {
  // 		partial[tagIndex] = responseAnswerAndMeta[1]
  // 	} else {
  // 		if (typeof responseAnswerAndMeta[0] == 'string') {
  // 			partial[tagIndex] = responseAnswerAndMeta[0]
  // 		} else if (Array.isArray(responseAnswerAndMeta[0])) {
  // 			partial[tagIndex] = responseAnswerAndMeta[0][0]
  // 		}
  // 	}

  // 	// Replace ints with actual ints
  // 	for (let i = 0; i < partial.length; i++) {
  // 		if (!isNaN(parseInt(partial[i]))) {
  // 			partial[i] = parseInt(partial[i])
  // 		}
  // 	}

  // 	// Got through conditions
  // 	//'==','!==','!=','<=','>=','<','>'
  // 	let outcome = null
  // 	if (partial[1] == '==') { // equals

  // 		outcome = (partial[0] == partial[2])
  // 		// log('== comparison')

  // 	} else if (partial[1] == '!==') { // does not equal

  // 		outcome = (partial[0] !== partial[2])
  // 		// log('!== comparison')

  // 	} else if (partial[1] == '!=') { // does not equal

  // 		outcome = (partial[0] != partial[2])
  // 		// log('!= comparison')

  // 	} else if (partial[1] == '>=') { // greater than or equals to

  // 		outcome = (partial[0] >= partial[2])
  // 		// log('>= comparison')

  // 	} else if (partial[1] == '<=') { // less than or equals to

  // 		outcome = (partial[0] <= partial[2])
  // 		// log('<= comparison')

  // 	} else if (partial[1] == '<') { // less than

  // 		outcome = (partial[0] < partial[2])
  // 		// log('< comparison')

  // 	} else if (partial[1] == '>') { // greater than

  // 		outcome = (partial[0] > partial[2])
  // 		// log('> comparison')

  // 	}
  // 	return outcome
  // }


	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
		setIsMobile(width <= 1040);
	}

	function getProgressPercentage() {
		// let chatSectionIndex = 0
		// let chatQuestionIndex = 0
	}

	// function getDesktopSidecartContent() {
	// 	let desktopContent = '' // init desktop content var
	// 	const desktopContentElement = document.getElementById('healthcheckdesktopcontent') // get inner html from body element

	// 	// log(desktopContentElement.innerHTML)

	// 	// check if body element was set and content isn't empty
	// 	if (typeof desktopContentElement !== 'undefined' && desktopContentElement != null && typeof desktopContentElement.innerHTML !== 'undefined' && desktopContentElement.innerHTML !== '') {
	// 		desktopContent = desktopContentElement.innerHTML
	// 		desktopContent = desktopContent.replace(',function () { [native code] }','')
	// 	} else {
	// 		let welcomeContent = WelcomeContent()
	// 		desktopContent = welcomeContent
	// 	}

	// 	return desktopContent
	// }

	function addClassToClassList(stateVar, className) {
		let newClassListStr = stateVar

		if (!stateVar.includes(className)) {
			newClassListStr += (' ' + className)
		}

		return newClassListStr
	}

	function removeClassToClassList(stateVar, className) {
		let newClassListStr = stateVar

		if (stateVar.includes(className)) {
			newClassListStr = newClassListStr.replace(className, '')
		}

		return newClassListStr
	}

	return (
		<> <Helmet>
          <title>{environment!=="prod"?environment.toUpperCase():''} Health Check v3 {environment!=="production"?"("+props.version+")":''}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="healthcheck" version={props.version} />
        </Helmet>
      <noscript><img height="1" width="1" alt="fb" style={{display:'none'}}
      src="https://www.facebook.com/tr?id=662145955390083&ev=PageView&noscript=1"
      /></noscript>
      {apiLoading && <div className={`isLoading ${headerState}`} ref={isLoadingDiv}>
        <svg height="50%" width="100%" viewBox="0,0,360,120" >
          <g id="Layer 1">
            <path className="loaderpath" color={`${errorState ? 'red':'yellow'}`} stroke-width="4" d="M6.74321+74.288C6.74321+74.288+43.5579+74.3094+44.4661+73.4013C47.7739+70.0935+51.0877+64.3796+54.8661+60.6013C58.4151+57.0522+64.4661+77.2203+64.4661+72.2013C64.4661+60.9222+74.4661+13.2285+74.4661+14.6013C74.4661+34.8817+84.1824+114.085+84.8661+113.401C90.9815+107.286+92.4968+72.5398+97.6661+62.2013C97.8481+61.8372+98.8193+59.0013+98.8661+59.0013C102.328+59.0013+101.253+72.6141+104.066+69.8013C105.133+68.7347+111.761+45.4957+112.466+46.2013C116.118+49.8535+117.666+69.4899+117.666+74.6013C117.666+74.8415+118.541+77.726+119.666+76.6013C120.734+75.5337+123.846+65.8013+124.466+65.8013C127.54+65.8013+126.982+71.4013+128.866+71.4013C138.998+71.4013+188.211+71.2937+187.919+71.478C184.34+73.735+141.174+32.9312+159.997+14.9779C179.14-3.28066+195.419+26.8482+195.831+22.7184C195.951+21.5244+212.343-4.11359+230.231+14.6917C246.838+32.1497+204.566+73.4397+202.58+71.2715C201.869+70.4952+229.528+71.3654+233.509+71.3654C242.193+71.3654+241.29+70.953+244.466+64.6013C244.621+64.2906+245.25+62.5848+245.666+63.0013C247.914+65.2495+249.475+71.6276+250.466+74.6013C251.016+76.2505+253.266+80.7397+253.266+79.0013C253.266+71.8401+257.757+61.3454+259.266+53.8013C259.318+53.5414+260.438+50.2013+260.466+50.2013C261.879+50.2013+266.042+83.6588+266.231+83.4703C267.97+81.7312+272.089+58.1114+272.089+59.2583C272.089+62.5308+277.374+102.818+279.666+107.401C279.785+107.64+296.866+8.97147+296.866+9.00126C296.866+29.6941+299.666+50.7829+299.666+71.4013C299.666+72.4126+299.648+77.8013+300.466+77.8013C302.097+77.8013+310.72+61.2549+311.266+61.8013C313.499+64.0338+314.29+71.6893+316.369+73.7173C318.022+75.3291+349.496+73.5006+351.105+73.3555" fill="none" stroke-linecap="round" opacity="1" stroke-linejoin="round"/>
          </g>
        </svg>
      </div>
      }
      {errorState && <div className='errorMsg active' style={{color: 'red',fontSize: '1rem',}} ref={errorElement}>{errorMsg}</div>}

      {/* <div className={`partial-desktop-header ${displayLogoBannerAndFooter ? '' : ' hide'}`}  onClick={previousQuestion}>
    		<div className='mh4l-logo black'></div>
  		</div> */}

  		<div 
  			className="container-session-code"
  			id="containerSessionCode" 
  			data-session-code={sessionCode}
  			style={{display:'none'}}
  		></div>
      
			<div className="container-primary">
				<div className="partial-chat-view" ref={chatView}>
					<Header
						headerState={headerState}
						progress={progressPercentage}
						navigateBackCallback={previousQuestion}
						indexFlow={questionIndexFlow}
            features={props.features}
					/>
					<div className="container-chat-box">
          {!resultsVisibility && 
						<div className="chat-box" ref={chatBox} id="chat-box">
							<div className="partial-mobile-loading-screen">
							  <div className="copy">
							    <h3><em>My health for life</em> is a free, healthy lifestyle program helping Queenslanders to live well.</h3>
							  </div>

							<Button
							  classList={`start-button primary regular large mx-auto ${questionSetLoaded ? '' : 'disabled'}`} 
							  innerText={stateButtonText}
							  onclick={startHealthCheck}
                features={props.features}
							/> 
						  </div>
						</div>
            }
						{resultsVisibility && <div className="results-box" ref={resultsBox} id="results-box">
						  <ResultsScreen
								resultSet={overalResultSet}
								results={overalResult}
								key={'123'}
								classList='test'
								imgType='bottles.png'
								chartSubHeading='11+ standard drinks a week'
								chartDescription='Consuming 10 or less standard drinks per week is safest for your long term health. Drinking less than 4 standard drinks on any one occasion is best. The less you choose to drink, the better your sleep, energy and body will feel.'
								question= 'Want to learn more about eating well or explore some new food ideas? '
								links={[
									{
										link: 'https://www.google.com',
										text: 'Living well library'
									},
									{
										link: 'https://www.google.com',
										text: 'Jaimie\'s Ministry of Food'
									}
								]}
								formSubmitCallback={pushUserDetailsToAPI}
								userFirstName={userFirstName}
                userEmail={userEmail}
                userPhone={userPhone}
                loggedInUserData={loggedInUserData}
                resultsVisibility={resultsVisibility}
                //displayLogoBannerAndFooter={displayLogoBannerAndFooter}
                environment={environment}
                joinFormSuccess={joinFormSuccess}
                features={props.features}
                moodle2Link={moodle2Link.current}
							/>
						</div>}
					</div>
				</div>
			</div>
			{/* {resultsVisibility && displayLogoBannerAndFooter && <Footer className='desktop-footer' environment={environment}></Footer>} */}
		</>
	);
}

// const WelcomeContent = () => (
// 	<div className="">
// 		<h2 className='text-white'>Welcome to <em>My health for life</em></h2>
// 		<h3 className='text-white'>A free, healthy lifestyle program.</h3>
// 		<p className='text large text-white'><em>My health for life</em> is a free, healthy lifestyle program helping Queenslanders to live well.</p>
// 		<p className='text large text-white'>You can take the first steps to a healthier you with our online health check. It only takes 5-10 minutes to complete. You’ll find out how your health is tracking and can decide if the program is right for you.</p>
// 	</div>
// );

export default HealthCheck;
