import Button from './Button.js';



function SocialShare(props) {

  function suggestedProgramClick(){
    let offsetTop = props.reference.current.offsetTop;
    log(offsetTop)
  }
    return (
      <div className={`component-social-share ${props.classList}`}>
        <div className='suggested-link'>
          <a 
            onClick={suggestedProgramClick.bind()} 
            // href={props.suggestedLink}
            >
              {props.suggestedLinkText}
          </a>
        </div>
      </div>
    );
  }
  
  export default SocialShare;