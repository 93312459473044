import TagManager from "react-gtm-module";

/**
 * Class to handle all Google Tag Manager & Data Layer pushes with methods.
 */
import { log } from "../helpers"

class GTMHandler {
  // constructor() {}

  activeEventKeys = Object.freeze({
    formSubmission: "formSubmission",
    buttonClick: "buttonClick",
    riskLevel: "riskLevel",
  });

  // Triggers GTM event
  bindSubmit(event) {
    TagManager.dataLayer({
      dataLayer: {
        event: this.activeEventKeys.formSubmission,
      },
    });
  }

  // Triggers GTM event
  bindClick(event) {
    TagManager.dataLayer({
      dataLayer: {
        event: this.activeEventKeys.buttonClick,
      },
    });
  }

  /**
   * @param {*} eventName The event name as a `string`.
   * @param {*} dataObject A object of the data to push to the data layer.
   */
  pushDataLayer(eventName, dataObject) {
    log(`Pushing to ${eventName} data layer:`+ dataObject);
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...dataObject,
      },
    });
  }
}

export const gtmHandler = new GTMHandler();
